import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import { useTranslation } from 'react-i18next';
const resolveLinkPath = (childTo, parentTo) => `${parentTo}/${childTo}`;

const NavItem = ({ headerToPath, label, icon, collapsed }) =>
//  ( <li className="nav__item mb-2">
//     <NavLink
//       to={headerToPath}
//       className={({ isActive }) =>
//         isActive
//           ? `nav__link ${!collapsed ? "nav__active" : "nav__active--collapsed"}`
//           : "nav__link "
//       }
//     >
//       {label === "LMS" && icon} {collapsed ? null : label}
//     </NavLink>
//   </li>
// );
{
  const getNavStyles = (isActive) => {
    return isActive
      ? `nav__link ${!collapsed ? "nav__active" : "nav__active--collapsed"}`
      : "nav__link ";
  };
  return (
    <li className="nav__item">
      <NavLink
        to={headerToPath}
        className={({ isActive }) => getNavStyles(isActive)}
      >
        {icon} {collapsed ? null : label}
      </NavLink>
    </li>
  );
};

const getUserRoles = () => {
  let userobjdata = JSON.parse(localStorage.getItem("user_obj_data"));
  let userRealmRoles = [];
  try {
    userRealmRoles = userobjdata.tokenParsed.resource_access["realm-management"].roles;
  }
  catch (e) {
    console.log(e)
  }
  return userRealmRoles;

}

const NavItemHeader = (props) => {
  const { t } = useTranslation();
  const { item } = props;
  const { label, icon, to: headerToPath, children } = item;
  const location = useLocation();
  const { collapsed } = LayoutState();

  const [expanded, setExpand] = useState(
    location.pathname.includes(headerToPath)
  );

  const onExpandChange = (e) => {
    e.preventDefault();
    setExpand((expanded) => !expanded);
  };

  return (
    <>
      <span onClick={onExpandChange}>
        <NavItem
          headerToPath={headerToPath}
          label={t(label)}
          icon={icon}
          collapsed={collapsed}
        />
      </span>
      {expanded && !collapsed && (
        <div style={{ marginLeft: "2em" }}>
          {children.map((item, index) => {
            if (item.allowedRoles) {
              let actualRolesFrmURM = getUserRoles();
              let allowedRoles = item.allowedRoles;
              if (allowedRoles.some(ai => actualRolesFrmURM.includes(ai))) {
                const key = `${item.label}-${index}`;
                const { label, Icon, children } = item;
                if (children) {
                  return (
                    <div key={key}>
                      <NavItemHeader
                        item={{
                          ...item,
                          to: resolveLinkPath(item.to, props.item.to),
                        }}
                      />
                    </div>
                  );
                }
                return (
                  <NavItem
                    headerToPath={resolveLinkPath(item.to, props.item.to)}
                    label={t(label)}
                    //icon={icon}
                    collapsed={collapsed}
                  />
                );
              }
            }
            else {
              const key = `${item.label}-${index}`;
              const { label, Icon, children } = item;
              if (children) {
                return (
                  <div key={key}>
                    <NavItemHeader
                      item={{
                        ...item,
                        to: resolveLinkPath(item.to, props.item.to),
                      }}
                    />
                  </div>
                );
              }
              return (
                <NavItem
                  headerToPath={resolveLinkPath(item.to, props.item.to)}
                  label={t(label)}
                  //icon={icon}
                  collapsed={collapsed}
                />
              );
            }

          })}
        </div>
      )}
    </>
  );
};

export default NavItemHeader;
