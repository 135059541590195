import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useState, useEffect, forwardRef } from "react";
import { Editicon, Deleteicon } from "../../../../Components/IconComponents";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../../Components/UiComponents/Table/Table";
import { deleteData, deleteDataById, getData } from "../../../../Services/Services";
import {
  getMasterResourceListURL,
  serviceBaseURL,
  appserveraddr,
  dmscoreservicecontextname,
  deleteMasterResourceById,
  deleteMasterResourceImageByName,
} from "../../../../Services/EndPoints";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Moment from "react-moment";
import {
  masterResourceTableData,
  masterResourcecolumnsForExport,
} from "../../../../JsonData/MasterResourceConfig";
import { Link } from "react-router-dom";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";

const MasterResourceTable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const qs = require("qs");
  const [masterResourceData, setMasterResourceData] = useState(
    masterResourceTableData
  );
  const [searchData, setSearchData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    getMasterResourceList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  async function getMasterResourceList() {
    setLoader(true);

    let masterResourceList = await getData(getMasterResourceListURL);
    let sortedList = masterResourceList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setLoader(false);

    setRowCount(sortedList.length);
    setData(sortedList);
  }
  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = masterResourcecolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };
  const addNewHandler = () => {
    navigate("/master-resource/create-masterresource");
  };

  const getImageWithAuth = async (imageName) => {
    const imageUrl = `${appserveraddr}/${dmscoreservicecontextname}/master_resource/get_resource_image/${imageName}`;
  
    try {
      const response = await fetch(imageUrl, {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem("auth_token"),
        },
      });
  
      if (response.ok) {
        const blob = await response.blob(); // Convert the response to a Blob
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            // Return the Base64-encoded string (exclude the data URL prefix part)
            resolve(reader.result.split(',')[1]);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob); // Read the Blob as a Data URL (Base64)
        });
      } else {
        console.error("Failed to fetch image:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };
  
  

  const columns = React.useMemo(
    () => [
      {
        Header: t("masterresourcetableheaders.activity"),
        accessor: "activity",
        Cell: (props) => {
          if (props.value) {
            let activityText = props.value;
            if (props.value.length > 15) {
              activityText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{activityText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("masterresourcetableheaders.category"),
        accessor: "category",
        Cell: (props) => {
          if (props.value) {
            let categoryText = props.value;
            if (props.value.length > 15) {
              categoryText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{categoryText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("masterresourcetableheaders.name"),
        accessor: "name",
        Cell: (props) => {
          if (props.value) {
            let name = props.value;
            if (props.value.length > 15) {
              name = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{name}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },

      {
        Header: t("masterresourcetableheaders.createdon"),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
        leftAlign: true,
      },
      {
        Header: t("masterresourcetableheaders.updatedon"),
        accessor: "updated_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
        leftAlign: true,
      },
      {
        Header: t("masterresourcetableheaders.unit"),
        accessor: "unit",
        disableSortBy: true,
      },
      {
        Header: t("masterresourcetableheaders.image"),
        accessor: "resource_image_name",
        Cell: (props) => {
          const [image, setImage] = useState(null);
          const [loading, setLoading] = useState(true); // Track loading state
          const [hasImage, setHasImage] = useState(false); // Track if image exists
      
          useEffect(() => {
            if (props.value) {
              setImage(null); // Reset image on new value
              setLoading(true); // Set loading to true when a new image is being fetched
              setHasImage(false); // Reset the state indicating image existence
      
              // Fetch image and convert to Base64
              getImageWithAuth(props.value)
                .then((base64) => {
                  if (base64) {
                    setImage(base64); // Set the Base64 image data
                    setHasImage(true); // Mark that the image exists
                  } else {
                    setImage(null); // If fetch fails, clear image
                  }
                  setLoading(false); // Stop loading
                })
                .catch(() => {
                  setImage(null); // Clear image in case of error
                  setHasImage(false); // No image found
                  setLoading(false); // Stop loading in case of error
                });
            } else {
              setImage(null); // If no value for image, reset image
              setHasImage(false); // No image available
              setLoading(false); // Stop loading
            }
          }, [props.value]); // Re-run when image name changes
      
          // If there's no image and it's not loading, show "NA"
          if (!loading && !hasImage) {
            return <span>NA</span>;
          }
      
          // If loading, show "Loading..."
          if (loading) {
            return <span>Loading...</span>;
          }
      
          // Display the Base64 image
          if (image) {
            return <img className="rsourceImage" src={`data:image/jpeg;base64,${image}`}/>;
          } else {
            return <span>NA</span>; // Fallback message if image is not available
          }
        },
      },      
      
      
      {
        Header: t("masterresourcetableheaders.action"),
        disableSortBy: true,

        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
          const [selectedName, setSelectedName] = useState(null);
          const deletePopUp = (id,resource_image_name) => {
            setSelectedId(id);
            setSelectedName(resource_image_name);
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async () => {
            try {
              const response = await deleteDataById(deleteMasterResourceById, selectedId);
              if (response?.status === "success") {
                await getMasterResourceList();;
                toast.success(
                  response.msg || t("delete_success_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              } 
            } catch (error) {
              toast.error(t("delete_error_message"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              console.error("Delete error:", error);
            } finally {
              setIsdelete(false);
              setSelectedId(null);
            }
          };
          const deleteTableDataImageByName = async () => {
            try {
              const response = await deleteDataById(deleteMasterResourceImageByName, selectedName);
              if (response?.status === "success") {
                await getMasterResourceList();;
                toast.success(
                  response.msg || t("delete_success_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              }
            } catch (error) {
              toast.error(t("delete_error_message"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              console.error("Delete error:", error);
            } finally {
              setIsdelete(false);
              setSelectedName(null);
            }
          };

          const handleDelete = () => {
            deleteTableDataById();
            deleteTableDataImageByName();
          };
          // const deleteTableDataById = (id) => {
          //   deleteData(deleteMasterResourceById, id)
          //     .then((response) => {
          //       deletePopUp();
          //       getMasterResourceList();
          //     })
          //     .catch((error) => {
          //       console.log(error);
          //     });
          // };

          return (
            <div className="usertable__actionsicon">
              <span
  onClick={() => {
    console.log("imgName:", props.row.original.resource_image_name); // Log the image name
    navigate("/master-resource/create-masterresource", {
      state: {
        id: props.row.original.id,
        imgName: props.row.original.resource_image_name, // Pass the image name in the state
      },
    });
  }}
>
  <Editicon value={props.value} className="cursor-pointer me-2" />
</span>


              <span onClick={() => deletePopUp(props.row.original.id,props.row.original.resource_image_name)}>
                <Deleteicon
                  value={props.value}
                  className="cursor-pointer mr-1"
                />
              </span>

              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.activity}
                  deleteRow={handleDelete}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t]
  );
  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {data && (
          <Table
            data={data}
            addButtonLabel={t("createmasteraddbuttonlabel")}
            addNewHandler={addNewHandler}
            setSearchData={setSearchData}
            columns={columns}
            numberOfRows={10}
            rowCount={rowCount}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MasterResourceTable;
