import React, { useEffect, useRef,useState } from "react";
import './RegionTree.css';
import Modal from "react-bootstrap/Modal";
const RegionNode = (prop) => {
  const [dispNone,setDispNone] = useState("d-none")
  const showActionBtns = (elem,id) => {
    let elems = document.getElementsByClassName("rgn_edit_icon"); 
    let elemsCrt = document.getElementsByClassName("rgn_crt_btn"); 
    for(let i=0;i<elems.length;i++){
      elems[i].classList.add("d-none");
      elemsCrt[i].classList.add("d-none");
    }
    document.getElementById("edit_btn"+id).classList.remove("d-none");
    document.getElementById("plus_btn"+id).classList.remove("d-none");

  }
   return (
     <div className="" onClick={() => showActionBtns(this,prop.id)}>
       {prop.text} 
       <i onClick={(e) => prop.selectedId(prop.id,'EDIT',prop.keyVal,prop.regionTypeId)} title="Edit Region" id={"edit_btn"+prop.id} class={"fa d-none fa-pen ms-2 rgn_edit_icon"} role="button" aria-hidden="true"></i>
       <i onClick={(e) => prop.selectedId(prop.id,'CRT',prop.keyVal,prop.regionTypeId)}  title="Create Region" id={"plus_btn"+prop.id} class={"fa d-none fa-plus ms-2 rgn_crt_btn"} role="button" aria-hidden="true"></i>   
     </div>
   );
 };

export default RegionNode;
