import React, { useEffect } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import CreateUserTaskForm from "./CreateUserTaskForm";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from 'react-i18next';

const UserTaskForm = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {

    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 0,
      component: <DMSHeader title={t('user_task_formheader')} showIconsForback={true} />,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 14,
      id: 1,
      component: <CreateUserTaskForm />,
    },
  ];
  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default UserTaskForm;
