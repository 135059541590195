import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import Table from "../../../../Components/UiComponents/Table/Table";
import { getData, postDataWithBody } from "../../../../Services/Services";
import {
    getFacilityReportAttrByReportIdAndCat
} from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";
const FLCKTable = (props) => {
  const [reportAttributeData, setReportAttributeData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [tableData,setTableData] = useState([]);
  const { t } = useTranslation();
  const [isShowModal, setIsShowModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getReportAttributeData();
  }, []);

  const getReportAttributeData = async () => {
    let input = {"category_cd":props.categoryCd,"facility_report_id":props.facilityReportId};
    var raDataList = await postDataWithBody(getFacilityReportAttrByReportIdAndCat, input, {});
    setRowCount(raDataList.length);
    setReportAttributeData(raDataList);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t('mealpopup.slno'),
        accessor: "sno",
        disableSortBy: true,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t('mealpopup.mealtype'),
        accessor: "meal_type"
      },
      {
        Header: t('mealpopup.type'),
        accessor: "attribute"
      },
      {
        Header: t('mealpopup.quantity'),
        accessor: "facility_report_attribute_value"
      }
    ],
    []
  );
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {reportAttributeData && (
            <Table
              data={reportAttributeData}
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
     
    </div>
  );
};

export default FLCKTable;
