import axios from "axios";
export default {
  getData: (url, params, headers) =>
    axios({
      method: "GET",
      url: url,
      headers: headers,
      params: params,
    }),
  postData: (url, headers, body) =>
    axios({
      method: "POST",
      data: body,
      url: url,
      headers: headers,
    }),
  putData: (url, headers, body) =>
    axios({
      method: "PUT",
      data: body,
      url: url,
      headers: headers,
    }),

  deleteData: (url, headers) =>
    axios({
      method: "DELETE",
      url: url,
      headers: headers,
    })
};
