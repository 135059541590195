import React, { useEffect } from "react";
import { useParams } from "react-router";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../DMSHeader/DMSHeader";
import { toast, ToastContainer } from "react-toastify";
import FormViewerForm from "./FormViewerForm";
import FormViewerTable from "./FormViewerTable";
import FormBuilderView from "./FormBuilderView";
import { useTranslation } from "react-i18next";
// import "./CreateAgency/Agency.css";
const ReportManagement = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: <DMSHeader title={t("adhoc_management_header")} />,
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 10,
      id: 2,
      component: <FormBuilderView />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default ReportManagement;
