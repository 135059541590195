import React, { useState, useEffect } from "react";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../DMSHeader/DMSHeader";
import MyTaskList from "./MyTaskList/MyTaskList";
import MyIndividualTaskList from "../MyIndividualTaskListManagement/MyIndividualTaskList/MyIndividualTaskList";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import "./MyTaskListManagement.css";
import { useTranslation } from 'react-i18next';

const MyTaskListManagement = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    // {
    //   x: 0,
    //   y: 0,
    //   width: 12,
    //   height: 1,
    //   id: 1,
    //   component: (
    //     <DMSHeader
    //       title="My Task List"
    //       showmenu={true}
    //       showImportDropDownLabel={false}
    //     />
    //   ),
    //   haveOverflowVisible: true,
    // },
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('daily_operational_task_header')}
          showmenu={true}
          showImportDropDownLabel={false}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 6,
      id: 2,
      component: <MyIndividualTaskList />,
    },
  ];
  const MyIndividualTaskListlayoutData = [
    {
      x: 0,
      y: 2,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('sop_related_task_header')}
          showmenu={true}
          showImportDropDownLabel={false}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 3,
      width: 12,
      height: 6,
      id: 2,

      component: <MyTaskList />,
    },
  ];
  // return (
  //   <>
  //     <div>
  //       <Accordion defaultActiveKey="0" flush>
  //         <Accordion.Item eventKey="0">
  //           <Accordion.Header className="headerstyle">
  //             SOP Related Tasks
  //           </Accordion.Header>
  //           <Accordion.Body className="bodystyle">
  //             <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
  //               <MyTaskList />
  //               {/* <GridItem layoutData={layoutData} /> */}
  //               {/* <GridItem layoutData={MyIndividualTaskListlayoutData} /> */}
  //             </div>
  //           </Accordion.Body>
  //         </Accordion.Item>
  //       </Accordion>
  //     </div>
  //     <div style={{ marginTop: "8rem" }}>
  //       <Accordion defaultActiveKey="0" flush>
  //         <Accordion.Item eventKey="0">
  //           <Accordion.Header className="headerstyle">
  //             Daily Operational Tasks
  //           </Accordion.Header>
  //           <Accordion.Body className="bodystyle">
  //             <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
  //               <MyIndividualTaskList />
  //               {/* <GridItem layoutData={layoutData} /> */}
  //               {/* <GridItem layoutData={MyIndividualTaskListlayoutData} /> */}
  //             </div>
  //           </Accordion.Body>
  //         </Accordion.Item>
  //       </Accordion>
  //     </div>
  //   </>
  // );
  return (
    <div>
      <Accordion defaultActiveKey="0" flush>
 
        <Accordion.Item eventKey="0">
          <Accordion.Header>{t('daily_operational_task_header')}</Accordion.Header>
          <Accordion.Body className="accordion-body-style">
            {/* <div className="grid-stack grid-stack-instance-1 smartpole__header--transition"> */}
            <div>
              <MyIndividualTaskList />
            </div>
            {/* </div> */}
          </Accordion.Body>
        </Accordion.Item>


        <Accordion.Item eventKey="1">
          <Accordion.Header>{t('sop_related_task_header')}</Accordion.Header>
          <Accordion.Body className="accordion-body-style">
            {/* <div className="grid-stack grid-stack-instance-1 smartpole__header--transition"> */}
            <MyTaskList />
            {/* </div> */}
          </Accordion.Body>
        </Accordion.Item>

      </Accordion>
    </div>
  );
};

export default MyTaskListManagement;
