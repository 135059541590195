import React, { useEffect } from "react";
import GridItem from "../../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import Form9Table from "../Form9Table/Form9Table";


const StateFloodReport = () => {
  const { t } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="Form 9"
          

        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height:12,
      id: 2,
      component: <Form9Table/>,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};


export default StateFloodReport
