import { createContext, useContext, useState } from "react";

const Layout = createContext();

const LayoutContext = ({ children }) => {
  const [collapsed, toggle] = useState(true);

  return (
    <Layout.Provider value={{ collapsed, toggle }}>{children}</Layout.Provider>
  );
};

export const LayoutState = () => {
  return useContext(Layout);
};

export default LayoutContext;
