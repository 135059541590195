import React, { useEffect, useRef, useState } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import AgencyTable from "./AgencyTable/AgencyTable";
import DMSHeader from "../DMSHeader/DMSHeader";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
const AgencyManagement = (props) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('agency_management_header')}
          fileName="AgencyManagement"
          showdownload={true}
          showImportDropDownLabel={false}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 12,
      id: 2,
      component: <AgencyTable />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
    //hhhhh
  );
};

export default AgencyManagement;
