import * as yup from "yup";

export const schema = [
  yup.object({
    name: yup
      .string()
      .required("Please provide the name")
      .max(30, "name cannot exceed 30 characters")
      .matches(/^[A-Za-z\s]+$/, "Please provide a valid name"),
    description: yup
      .string()
      .nullable() // Allow null values (empty string)
      .max(200, "Description must be at most 200 characters"),
  }),
];

export const createRegionFormConfig = [
  {
    id: 0,
    name: "Create Region",
    fields: [
      {
        name: "name",
        label: "Name",
        type: "input",
        mandatory: "true",
      },
      {
        name: "zipcode",
        label: "ZIP Code",
        type: "input",
      },
      {
        name: "description",
        label: "Region Description",
        type: "text_area",
      },
    ],
  },
];
