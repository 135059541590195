import React, { useEffect} from "react";
import { useParams } from "react-router";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../DMSHeader/DMSHeader";
import { toast, ToastContainer } from "react-toastify";
import FormViewerForm from "./FormViewerForm";
import FormViewerTable from "./FormViewerTable";
// import "./CreateAgency/Agency.css";
const FormViewer = (props) => {
  let { formName } = useParams();
  useEffect(() => {
    GridStack.init();
  }, [formName]);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={formName}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 10,
      id: 2,
      component: <FormViewerTable formName={formName}/>,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
    //hhhhh
  );
};

export default FormViewer;
