import React, { useState, useEffect } from "react";
import "./DMSHeader.css";
import ImportPopup from "../Common/ImportPopup/ImportPopup";
import {
  Dataview,
  Goback,
  Reload,
  Menu,
} from "../../../Components/IconComponents";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch } from "react-redux";
import { setTableData } from "../../../CentralStore/ExportLMSTable/dataSlice";
import ExportTableData from "../../../Components/UiComponents/Table/TableComponents/ExportTableData/ExportTableData";

const height = "1.2rem";
const width = "1.2rem";

const DMSHeader = ({
  title,
  vehicleNumber,
  subName,
  status,
  showIconsForback,
  showicons,
  data,
  showdownload,
  importEndpoint = "",
  downloadTemplate,
  fileName,
  showImportDropDownLabel = true,
}) => {
  const navigate = useNavigate();
  const [showImport, setShowImport] = useState(false);
  const dispatch = useDispatch();

  const dataFromStore = useSelector((state) => state.data);

  useEffect(() => {
    return () => {
      dispatch(setTableData({ columns: [], tableData: [] }));
    };
  }, []);

  const importpop = () => {
    setShowImport(!showImport);
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <Menu />
    </a>
  ));

  return (
    <div className="lms__header">
      <div className="lms__title">
        {showIconsForback && (
          <span style={{ marginRight: "10px" }}>
            <Goback
              className="cursor-pointer"
              height={height}
              width={width}
              onClick={() => navigate(-1)}
            />
          </span>
        )}
        {title} {vehicleNumber}
      </div>

      <div className="lms__title">
        {subName}
        {status === "In Transit" ? (
          <span className="lms__status--transit">{status}</span>
        ) : (
          <span className="lms__status--delivery">{status}</span>
        )}
      </div>

      <span className="lms__title_icon">
        {showicons && (
          <span className="lms__icons">
            <Reload />
          </span>
        )}
        {showicons && (
          <span className="lms__icons">
            <Dataview />
          </span>
        )}
        {showdownload && (
          <ExportTableData
            data={dataFromStore.tableData}
            columns={dataFromStore.columns}
            filename={fileName}
            id="ExportTableData"
            style={{ color: "#212529" }}
          >
          </ExportTableData>
        )}
        {showImport && (
          <ImportPopup
            className="import__popup"
            show={showImport}
            data={data}
            title="Import File"
            handleToggle={importpop}
            importEndpoint={importEndpoint}
            downloadTemplate={downloadTemplate}
          />
        )}
      </span>
    </div>
  );
};
export default DMSHeader;

