import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../../Common/Form/FormHandler/FormHandler";
import "./HumanLossForm.css";
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  HumanLossPrimaryConfig,
  PrimaryConfigSchema,
  HumanLossUserConfig,
} from "../../../../../JsonData/HumanLossUserConfig";

import {
  postDataWithBody,
  getData,
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../../Services/Services";

import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  getPoliceStationDetails,
  updateHumanLossPrimaryDetails,
} from "../../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";

const HumanLossPrimaryRegistrationForm = () => {
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [humanLossUserConfig, setHumanLossUserConfig] = useState(
    HumanLossUserConfig
  );
  const [policeStationOptions, setPoliceStationOptions] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  let dataFromTable = state?.data;

  const [lgShow, setLgShow] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(PrimaryConfigSchema[activeTab]),
    mode: "all",
  });

  const assign_type = useWatch({
    name: "assign_type",
    control,
  });

  // Fetch police stations once on component mount
  useEffect(() => {
    const getPoliceStationList = async () => {
      try {
        const policeStationDetails = await getData(getPoliceStationDetails);

        // Map the response to the format expected by the dropdown
        const policeStationOptions = policeStationDetails.map((station) => ({
          value: station.id.toString(),
          label: station.police_station,
        }));

        // Set the options in state
        setPoliceStationOptions(policeStationOptions);

        // Update the HumanLossUserConfig dynamically
        const updatedConfig = { ...HumanLossPrimaryConfig };
        updatedConfig[0].fields.forEach((field) => {
          if (field.name === "policeStationOfIncident") {
            field.options = policeStationOptions;
          }
        });

        // Update state to trigger re-render
        setHumanLossUserConfig(updatedConfig);
      } catch (error) {
        console.error("Error fetching police station details:", error);
      }
    };

    getPoliceStationList();
  }, []); // Empty dependency array to fetch data only once

  // Set default value for police station after fetching data
  useEffect(() => {
    if (dataFromTable && policeStationOptions.length > 0) {
      const selectedPoliceStation = policeStationOptions.find(
        (option) => option.value === dataFromTable.policeStationId.toString()
      );
      if (selectedPoliceStation) {
        setValue("policeStationOfIncident", selectedPoliceStation.value);
      }
    }
  }, [dataFromTable, policeStationOptions, setValue]);

  // Function to get the label from options based on the value
  const getSelectedLabel = (fieldName, value) => {
    const field = HumanLossPrimaryConfig[0].fields.find((f) => f.name === fieldName);
    const selectedOption = field?.options.find((option) => option.value === value);
    return selectedOption ? selectedOption.label : value; // If no label found, return the value itself
  };

  useEffect(() => {
    if (dataFromTable) {
      // Reset the form with the data received
      reset({
        date: dataFromTable.reported_on,
        nameOfDistrict: dataFromTable.distName,
        block: dataFromTable.blockName,
        nameOfDisaster: dataFromTable.disaster_name,
        incidentId: dataFromTable.incidentId,
        incidentDate: dataFromTable.incidentDate,
        incidentTime: dataFromTable.incidentTime,
        Latitude: dataFromTable.latitude,
        longitude: dataFromTable.longitude,
        numberOfDeaths: dataFromTable.noOfCasualtyDeaths,
        numberOfInjured: dataFromTable.noOfCasualtyInjured,
        policeStationOfIncident: getSelectedLabel("policeStationOfIncident", dataFromTable.policeStationId),
        addressOfIncident: dataFromTable.addressOfIncident,
      });
    }
  }, [dataFromTable, reset]);

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          control={control}
          columns={3}
          getValues={getValues}
        />
      </div>
    );
  };

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    console.log("DATAFORPRIMARYUPDATE", data);
    setLoader(true);

    const input = {
      id: dataFromTable.id,
      noOfCasualtyDeaths: Number(data.numberOfDeaths),
      noOfCasualtyInjured: Number(data.numberOfInjured),
      policeStationId: Number(data.policeStationOfIncident),
      addressOfIncident: data.addressOfIncident,
      latitude: data.Latitude,
      longitude: data.longitude,
    };

    const res = await postDataWithBody(
      updateHumanLossPrimaryDetails.replace("DATE", dataFromTable.reported_on),
      input,
      {}
    );
    if (res.status == "success") {
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
      navigate("/dist-dashboard/human-loss-report", {
        state: {
          updated: true,
          date: dataFromTable.reported_on,
          distName: dataFromTable.distName,
        },
      });
    } else {
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
      setLoader(false);
    }
    e.preventDefault();
  };

  const tabsData = generateTabsData(HumanLossPrimaryConfig);
  return (
    <div className="mt-4 facility-container">
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="EditHumanLossPrimaryRegForm">
            <div>
              <h4 style={{ marginLeft: "15px" }}>Primary</h4>
            </div>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && <div>{tab.component}</div>}
                </>
              ))}
            </div>

            <div className="d-flex justify-content-center">
              <Button
                type="submit"
                id="submitBtn"
                className="mx-2"
                variant="primary"
                style={{ marginTop: "-2px" }}
              >
                Update
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default HumanLossPrimaryRegistrationForm;
