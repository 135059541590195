import React from "react";
import { downloadFile,getDRAttributeValue } from "../../../../../../Components/UiComponents/utils";
import { useTranslation } from "react-i18next";
let downloadAttachmentURL = process.env.REACT_APP_APP_SERVER_ADDRESS +"/"+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+"/download/download_attachments/?directoryPath=[DIRECTORY_PATH]&fileName=Property_Damage_Report.zip";
  
const PropertyDamage = (props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="affectedtable-container">
      <div className="affectedtable-header-style">{t('propertydamage')}</div>
      <div className="m-2">
        {/* <div className="row mainsection"> */}
        <div className="row section1 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('kutchahouse')}</div>
            <div>
              Completely : {getDRAttributeValue(props.data,'PDR_KUTCHA_HOUSES_DAMAGED_COMPLETELY')} <span>, Partially : {getDRAttributeValue(props.data,'PDR_KUTCHA_HOUSES_DAMAGED_PARTIALLY')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('puccahouse')}</div>
            <div>
              Completely : {getDRAttributeValue(props.data,'PDR_PUCCA_HOUSES_DAMAGED_COMPLETELY')} <span>, Partially : {getDRAttributeValue(props.data,'PDR_PUCCA_HOUSES_DAMAGED_PARTIALLY')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('hutsdamages')}</div>
            <div> {getDRAttributeValue(props.data,'PDR_TOTAL_HOUSES_DAMAGED')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('totalhousedamged')}
            </div>
            <div> {getDRAttributeValue(props.data,'PDR_TOTAL_HOUSES_DAMAGED')}</div>
          </div>
        </div>

        <div className="row section2 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('estimatedcostofdamagedhouse')}
            </div>
            <div>{getDRAttributeValue(props.data,'PDR_ESTIMATED_COST_OF_DAMAGED_HOUSES')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('estimatevalueofpublicpropty')}
            </div>
            <div>{getDRAttributeValue(props.data,'PDR_ESTMTD_VL_OF_DMGD_PBLC_PRPRTY')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">{t('attachments')}</div>
            <div>
              {
              getDRAttributeValue(props.data,'attachment_path') != null ? (
              <div className="affected-population-labelstyle" role="button"
              onClick={() => downloadFile(downloadAttachmentURL.replace("[DIRECTORY_PATH]",getDRAttributeValue(props.data,'attachment_path')),'Property_Damage_Report_'+new Date().getTime()+'.zip')} 
              >Download</div>
              ) : (
                "Not Available"
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDamage;
