import React, { useState, useRef } from "react";
import {
  MapContainer,
  TileLayer,
  LayerGroup,
  Marker,
  Popup,
  useMapEvents,
  Tooltip,
  useMapEvent,
  Polyline,
} from "react-leaflet";
import MapPopUp from "../MapPopUp/MapPopUp";
// import MarkerActive from "../../../../Assets/Icons/custom-marker.svg";
// import MarkerInactive from "../../../../Assets/Icons/marker-inactive.svg";
// import GoodCircle from "../../../../Assets/Icons/goodcircle.svg";
// import ModerateCircle from "../../../../Assets/Icons/moderatecircle.svg";
// import DangerousCircle from "../../../../Assets/Icons/dangerouscircle.svg";
// import RFIDInactive from "../../../../Assets/Icons/RFIDINactive.svg";
// import RFIDActive from "../../../../Assets/Icons/RFIDACTIVE.svg";
// import CameraACTIVE from "../../../../Assets/Icons/CameraACTIVE.svg";
// import PasAct from "../../../../Assets/Icons/PasAct.svg";
// import PasInact from "../../../../Assets/Icons/PasInact.svg";
import "./CustomMap.css";
import L from "leaflet";
import { MarkerState } from "../../../Context/MapMarkerContext/MapMarkerContext";
// import QRCODEAct from "../../../../Assets/Icons/QRCODEAct.svg";
// import QRCODEInact from "../../../../Assets/Icons/QRCODEInact.svg";
// import BoombarrierInact from "../../../../Assets/Icons/BoombarrierInact.svg";
// import BoombarrierActi from "../../../../Assets/Icons/BoombarrierActi.svg";
// import CameraInact from "../../../../Assets/Icons/CameraInact.svg";
// import WeighBridgeActive from "../../../../Assets/Icons/WeighBridgeActive.svg";
// import WeighBridgeInactive from "../../../../Assets/Icons/WeighBridgeInactive.svg";
// import EntryGate from "../../../../Assets/Icons/EntryGate.svg";

const CustomMap = ({
  coordinates,
  showActionBox = false,
  showStatus = false,
  chooseCoordinates,
  zoom,
  popUpShow,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [showToolBar, setShowToolBar] = useState(false);
  const { setMarkerId } = MarkerState();
  const mapRef = useRef();
  const handleClick = (e) => {
    const { lat, lng } = e.latlng;
    console.log("Latitude:", e.latlng.lat);
    console.log("Longitude:", e.latlng.lng);
    chooseCoordinates(lng, lat);
  };
  // Custom hook to handle map events
  const MapClickHandler = () => {
    useMapEvents({
      click: handleClick,
    });
    return null;
  };

  const markerClick = (event, markerId) => {
    const id = event.target.options.index;
    setShowToolBar(true);
    setSelectedIndex(id); //get index via custom marker property
    setMarkerId(markerId); //to set the marker in context
  };

  const SetViewOnClick = ({ animateRef }) => {
    const map = useMapEvent("click", (e) => {
      map.setView(e.latlng, map.getZoom(), {
        animate: animateRef.current || false,
      });
    });

    return null;
  };

  // const getMarkerIcon = (index) => {
  //   if (index === selectedIndex) {
  //     return activeIcon;
  //   //  } else {
  //   //   return defaultIcon;
  //   }
  // };

  const PopUoCloser = () => {
    const map = useMapEvents({
      click(e) {
        setShowToolBar(false);
        setSelectedIndex(-1);
        setMarkerId(0); //to set the marker in context
      },
    });
    return null;
  };

  const center = coordinates?.reduce(
    (acc, loc) => [
      acc[0] + loc.x / coordinates.length,
      acc[1] + loc.y / coordinates.length,
    ],
    [0, 0]
  );
  const animateRef = useRef(false);

  return (
    <>
      {showToolBar && showActionBox}
      <MapContainer
        zoom={zoom}
        scrollWheelZoom={true}
        center={center}
        style={{ height: "100%" }}
      >
        {/* <Polyline positions={polyLine} pathOptions={polyLineOptions} /> */}
        <PopUoCloser />
        <TileLayer
          url="http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}"
        />

        {coordinates.map((marker, index) => (
          <Marker
            //icon={iconType(marker?.popupdata)}
            key={index}
            index={index}
            center={[marker.x, marker.y]}
            position={[marker.x, marker.y]}
            // eventHandlers={{
            //   click: (event) => {
            //     markerClick(event, marker?.locationId);
            //   },
            // }}
          >
            {/* <Popup className="react-leaflet-popup" closeButton={true}>
              { popUpShow  ? <MapPopUp popupvalue={marker.popupdata} /> :""}
            </Popup> */}
          </Marker>
        ))}
        <MapClickHandler />
        <SetViewOnClick animateRef={animateRef} />
      </MapContainer>
    </>
  );
};
export default CustomMap;
