import React, { useEffect } from 'react'


const SOPExecutionManagement = () => {
    useEffect(()=>{
        window.location.href = process.env.REACT_APP_GIS_SERVICE+"/sopinstancelist"
    })
  return (
    <div>Loading...</div>
  )
}

export default SOPExecutionManagement