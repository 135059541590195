import React, { useState,useEffect }  from 'react';
import { useNavigate } from "react-router-dom";
import Table from "../../../Components/UiComponents/Table/Table";
import {
  getData
} from "../../../Services/Services";
import {
  gtFormDataByFormNameURL
} from "../../../Services/EndPoints";
const FormViewerTable= (props) => { 

  const [dataLoaded, isDataLoaded] = useState(false);
  const [headerArr, setHeaderArr] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();
  const addNewHandler = () => {
    navigate("/create-form-view/"+props.formName);
  };

  useEffect(() => {
    gtFormDataByFormName(props.formName);
  },[props.formName]);


  async function gtFormDataByFormName(formName){

    if(formName){
      var data = await getData(gtFormDataByFormNameURL+formName);
      setRowCount(data.length);
      setTableData(data);
      var headerInfo = [];
      for(let key in data[0]){
        headerInfo.push({
          Header: key,
          accessor: key,
          disableSortBy: true,
        })
      }
      setHeaderArr(headerInfo);
      isDataLoaded(true);
    }
    
  }
  return (
    <div className="container">
      {dataLoaded === true && (<Table
            data={tableData}
            columns={headerArr}
            numberOfRows={5}
            addNewHandler={addNewHandler}
            showExport={false}
            rowCount = { rowCount }
            showFilter={false}
            addButtonLabel="Create"
            showReset={false}
            onExpand={false}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{ border: "1px solid  #f0f0f0", padding: "2px", borderRadius: "12px"}}
          />)}
    </div>
  )
}

export default FormViewerTable;