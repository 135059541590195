import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";
import Loader from "react-spinner-loader";
import "react-datepicker/dist/react-datepicker.css";
import "./DMSDashboard.css";
import { GridStack } from "gridstack";
import { useTranslation } from "react-i18next";
import {
  getChildRegionsById,
  getAllincidents,
  getTaskConfigsURL,
  getFloodEntityStats,
  getFacilityReport,
  getASStats,
  getFacilityOpenReport,
  facilityDashboardData
} from "../../../../Services/EndPoints";
import LMSButton from "../../Common/LMSButton/LMSButton";
import moment from "moment";
import FacilityTypeView from "./FacilityTypeView/FacilityTypeView";
import CommunityKitchenTrendView from "../CommunityKitchenTrendView";
import FloodReliefCampTrendView from "../FloodReliefCampTrendView";
import AnimalCenterTrendView from "../AnimalCenterTrendView";
import {
  postDataWithBodyFrGIS,
  postDataWithBody,
} from "../../../../Services/Services";
import FacilityRegistered from "./FacilityRegistered/FacilityRegistered";
import OpenCloseChart from "./OpenCloseChart/OpenCloseChart";
import HealthCampChart from "./HealthCamp/HealthCampChart";
import {
  commmunityKitchen,
  floodRelifCamp,
  healthCamp,
} from "./DMSDashboardConfig";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Popover } from "react-bootstrap";

const externalStyle1 = {
  multiselectContainer: {
    minWidth: "7rem",
  },

  optionContainer: {
    border: "20px",
    color: "#000",
  },
};
const externalStyle2 = {
  multiselectContainer: {
    width: "100%",
  },
  searchBox: {
    height: "45px",
    width: "20rem",
    color: "#fff",
    overflow: "auto",
  },
  optionContainer: {
    border: "20px",
    color: "#000",
  },
};

const DMSDashboard = () => {
  const blockref = useRef();
  let distPopulated = null,
    facilityCodeIdMap = {};
  const [daterange, setDaterange] = useState("MONTH");
  const { t } = useTranslation();
  const [block, setBlock] = useState();
  const [incident, setIncident] = useState({ name: "Incident 1", id: 1 });
  const [options, setOptions] = useState([
    { name: "Incident 1", id: 1 },
    { name: "Incident 2", id: 2 },
  ]);
  const [fromDate, setFromDate] = useState(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1); // Subtract one day
    return yesterday;
  });
  
  const [toDate, setToDate] = useState(new Date()); // Today's date

  const [selectedDistrcit, setSelectedDistrict] = useState(null);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [facilityRegisteredInput, setFacilityRegisteredInput] = useState({});
  const [ckData, setCkData] = useState({});
  const [facilityOpenReportData, setFacilityOpenReportData] = useState([]);

  const [fRCampData, setFRCampData] = useState({});
  const [fRCenter, setFRCenterData] = useState({});
  const [aSData, setASData] = useState([]);

  const [incidents, setIncidents] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [blockoptions, setBlockoptions] = useState([]);
  const [frCampEntityData, setFrCampEntityData] = useState([]);
  const [frCenterEntityData, setFrCenterEntityData] = useState([]);
  const [inidentname, setInidentname] = useState("Flood at Darbhanga");
  const [incidenttime, setIncidenttime] = useState("12-MAR-2024 03:03:00 PM");
  const [regionId, setRegionId] = useState([]);
  const [healthCampData, setHealthCampData] = useState({});
  const [communityKitchenData, setCommunityKitchenData] = useState({});
  const [floodReliefCampData, setFloodReliefCampData] = useState({});
  const [animalShelterData, setAnimalShelterData] = useState({});
  const [facilityRegisteredData, setFacilityRegisteredData] = useState({});
  const [communityKitchenMembersData, setCommunityKitchenMembersData] = useState({});
  const [regionIdList, setRegionIdList] = useState([]);
  const [districtId, setDistrictId] = useState(null);
  const [allBlockIds, setAllBlockIds] = useState([]);
  const [distInput, setDistInput] = useState("ALL");
  const [loader, setLoader] = useState(false);
  const [openVsClosedData, setOpenVsClosedData] = useState({});
  const [error, setError] = useState(false);


  // const [facilityDashboardData, setFacilityDashboardData] = useState({
  //   community_kitchen_members: [],
  //   flood_relief: [],
  //   community_kitchen_open_close: [],
  //   health_camp: [],
  //   facility_registered: [],
  //   animal_shelter_open_close: [],
  //   community_kitchen: [],
  //   animal_shelter: [],
  //   flood_relief_open_close: [],
  // });
  
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 4,
      height: 5,
      id: 0,
      component: (
        <FacilityTypeView
          dataval={communityKitchenData}
          range={daterange}
          title="Community Kitchen"
          config={commmunityKitchen}
        />
      ),
    },
    {
      x: 4,
      y: 0,
      width: 4,
      height: 5,
      id: 1,
      component: (
        <FacilityTypeView
          dataval={floodReliefCampData}
          range={daterange}
          type="floodrelifcamp"
          selincident={incident}
          selblock={block}
          title="Flood Relief Camp"
          config={commmunityKitchen}
        />
      ),
    },
    {
      x: 8,
      y: 0,
      width: 4,
      height: 5,
      id: 6,
      component: (
        <FacilityTypeView
          dataval={healthCampData}
          range={daterange}
          type="floodrelifcamp"
          selincident={incident}
          selblock={block}
          title="Health Camp"
          config={healthCamp}
        />
      ),
    },
    {
      x: 0,
      y: 11,
      width: 4,
      height: 5,
      id: 7,
      component: <CommunityKitchenTrendView dataval={communityKitchenMembersData} />,
    },
    {
      x: 4,
      y: 11,
      width: 4,
      height: 5,
      id: 8,
      component: <AnimalCenterTrendView dataval={animalShelterData} />,
    },
    {
      x: 8,
      y: 11,
      width: 2,
      height: 5,
      id: 10,
      component: <FacilityRegistered dataval={facilityRegisteredData} />,
    },
    {
      x: 10,
      y: 11,
      width: 2,
      height: 5,
      id: 10,
      component: <OpenCloseChart dataval={openVsClosedData}/>,
    },
  ];
  const girdoptions = {
    staticGrid: true,
    column: 24,
    margin: 5,
  };
  const onSelectBlock = function (selectedList, selectedItem) {
    setSelectedBlocks(selectedList);
    setBlock(selectedItem);
  
    // Update `region_id_list` with selected block IDs or empty array if no blocks selected
    const regionIds = selectedList.length > 0
      ? selectedList.map((block) => block.id)
      : [districtId];
    setAllBlockIds(regionIds);
  };
  
  const onSelectDistrict = function (selectedList, selectedItem) {
    setSelectedDistrict([selectedItem]); // Update the selected district
    // setDistrictId(selectedItem.id); // Update the district ID
 
    if (selectedItem.id === "all") {
        // setSelectedBlocks([]); // Clear selected blocks
         blockref.current.resetSelectedValues(); // Clear selected blocks in the UI
        setDistInput("ALL"); // Set dist_input to "ALL"
    } else {
        blockref.current.resetSelectedValues(); // Clear previously selected blocks
        getBlockListByDistId(selectedItem.id); // Fetch blocks for the selected district
        setDistInput(selectedItem.name);
        setRegionIdList(allBlockIds); // Set to all block IDs
         // Set dist_input to the selected district name
    }
};

  const onSelectIncident = function (selectedList, selectedItem) {
    setIncident(selectedItem);
  };
  useLayoutEffect(() => {
    setLoader(true);
    getFacilityType();
    getIncidentList();
    getDistrictList();
    getFacilityDashboardData();
    setLoader(false);

    var grid = GridStack.init(girdoptions);
  }, []);

  const getDistrictList = async () => {
    try {
      const distList = await postDataWithBody(getChildRegionsById, ["52"], {});
      const allOption = { id: "all", name: "All" };
      const updatedDistList = [allOption, ...distList];
      setDistrictList(updatedDistList);
      setSelectedDistrict([updatedDistList[0]]);
      distPopulated = updatedDistList[0].id;
      await getBlockListByDistId(updatedDistList[0].id)
      refreshDashBoards();
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  const getRegionIdList = async () => {
    let regionIds = [];
    distPopulated =
      distPopulated == null ? selectedDistrcit[0].id : distPopulated;
    if (selectedBlocks.length == 0) {
      if (distPopulated !== "all") {
        var rgnList = await postDataWithBody(
          getChildRegionsById,
          [distPopulated],
          {}
        );
        rgnList?.forEach(function (region) {
          regionIds.push(region.id);
        });
      }
    } else {
      selectedBlocks.forEach(function (node, index) {
        regionIds.push(node.id);
      });
    }
    setRegionId(regionIds);
    return regionIds;
  };
  const refreshDashBoards = async () => {
    setLoader(true);
    await getFacilityType();
    let intervalObj = { TODAY: 0, WEEK: 1, MONTH: 2 };
    let inputObj = {};
    inputObj["interval_type"] = intervalObj[daterange];
    inputObj["end_date"] = new Date().toISOString().slice(0, 10);
    if (fromDate && toDate) {
      inputObj["start_date"] = moment(fromDate).format("YYYY-MM-DD");
      inputObj["end_date"] = moment(toDate).format("YYYY-MM-DD");
    }
    inputObj["region_id"] = await getRegionIdList();
    Promise.all([
      getFacilityType(),
      populateCommunityKitchenInfo(inputObj),
      populateFRCampInfo(inputObj),
      populateFRCenterInfo(inputObj),
      populateFacilitiesRegistered(inputObj),
      populateFacilitiesopenReport(inputObj),
      populateASReport(inputObj),
      populateFlCenterEntityReport(inputObj),
      getFacilityDashboardData(),
  ])
      .catch((error) => {
          console.error("Error refreshing dashboards:", error);
      })
      .finally(() => {
          setLoader(false); // Stop the loader
      });
  };
  const populateFacilitiesRegistered = (inputObj) => {
    setFacilityRegisteredInput(inputObj);
    //let data ={"facility_type_id":502,"region_id":[4691],"start_date":"2024-01-01","end_date":"2024-12-01","interval_type":0};
  };
  const populateASReport = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["ANML_SHLTR"];
    const asData = await postDataWithBody(getASStats, inputObj, {});
    setASData(asData);
  };
  const populateFlCampEntityReport = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["FLD_RLF_CMP"];
    const frcmpEntity = await postDataWithBody(
      getFloodEntityStats,
      inputObj,
      {}
    );
    setFrCampEntityData(frcmpEntity[0]);
  };
  const populateFlCenterEntityReport = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["FLD_RLF_CNTR"];
    const frcenterEntity = await postDataWithBody(
      getFloodEntityStats,
      inputObj,
      {}
    );
    setFrCenterEntityData(frcenterEntity[0]);
  };
  const populateCommunityKitchenInfo = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["COMM_KTCHN"];
    const ckFacilityData = await postDataWithBody(
      getFacilityReport,
      inputObj,
      {}
    );

    setCkData(ckFacilityData[0]);
  };
  const populateFacilitiesopenReport = async (inputObj) => {
    const facilityOpenReport = await postDataWithBody(
      getFacilityOpenReport,
      inputObj,
      {}
    );
    setFacilityOpenReportData(facilityOpenReport);
  };
  const populateFRCampInfo = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["FLD_RLF_CMP"];
    const ckFacilityData = await postDataWithBody(
      getFacilityReport,
      inputObj,
      {}
    );

    setFRCampData(ckFacilityData[0]);
  };

  const populateFRCenterInfo = async (inputObj) => {
    inputObj["facility_type_id"] = facilityCodeIdMap["FLD_RLF_CNTR"];
    const ckFacilityData = await postDataWithBody(
      getFacilityReport,
      inputObj,
      {}
    );
    setFRCenterData(ckFacilityData[0]);
  };
  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      setBlockoptions(blockList);
      const allIds = blockList.map(block => block.id);
      setAllBlockIds(allIds);
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };

  
  const transformArrayToObject = (dataArray) => {
    // console.log(dataArray,"daataaarr");
    return dataArray.reduce((acc, item) => {
      acc[item.attribute] = item.total_meals;
      return acc;
    }, {});
  };

  const transformArrayToObjectForMealType = (dataArray) => {
    // console.log(dataArray,"daataaarr");
    return dataArray.reduce((acc, item) => {
      acc[item.meal_type] = item.total_meals;
      return acc;
    }, {});
  };

  const transformArrayToObjectForName = (dataArray) => {
    // console.log(dataArray,"daataaarr");
    return dataArray.reduce((acc, item) => {
      acc[item.name] = item.total_meals;
      return acc;
    }, {});
  };

  const transformArrayToObjectForFR = (dataArray) => {
    return dataArray.reduce((acc, item) => {
      // Ensure facility_type is not null or undefined
      if (item.facility_type) {
        // Split facility_type into individual types and trim whitespace
        const types = item.facility_type.split(',').map((type) => type.trim());
   
        // Increment count for each facility type
        types.forEach((type) => {
          acc[type] = (acc[type] || 0) + 1;
        });
      }
      return acc;
    }, {});
  };
  // const transformArrayToObjectForFR = (data) => {
  //   // Iterate through the keys in the data object
  //   return Object.keys(data).reduce((acc, key) => {
  //     // Extract the total value from the first element of the array
  //     acc[key] = data[key][0]?.total || 0; // Default to 0 if the total is not available
  //     return acc;
  //   }, {});
  // };

  const transformOpenCloseData = (data) => {
    return {
      community_kitchen: {
        open: data.community_kitchen_open_close[0].open,
        closed: data.community_kitchen_open_close[0].closed,
      },
      flood_relief: {
        open: data.flood_relief_open_close[0].open,
        closed: data.flood_relief_open_close[0].closed,
      },
      animal_shelter: {
        open: data.animal_shelter_open_close[0].open,
        closed: data.animal_shelter_open_close[0].closed,
      },
    };
  };
  
  


  // {"region_id_list":[4169,4691,4687,4689,4693,4690,4692,4694,4688,4695,4177],"from_date":"2024-01-01","to_date":"2024-10-23","dist_input":""}
  const getFacilityDashboardData = async () => {
    try {
      const facilityDashboardDataReq = await postDataWithBody(
        facilityDashboardData,
        {
          region_id_list: distInput === "ALL" ? [] : allBlockIds,
          from_date: moment(fromDate).format("YYYY-MM-DD"),
          to_date: moment(toDate).format("YYYY-MM-DD"),
          dist_input: distInput === "ALL"  ? distInput : "",
        },
        // {
        //   region_id_list: [4169,4691,4687,4689,4693,4690,4692,4694,4688,4695,4177],
        //   from_date: "2024-01-01",
        //   to_date: "2024-10-23",
        //   dist_input: "",
        // },
        {}
      );
      const inputData = {
        community_kitchen_open_close: facilityDashboardDataReq.community_kitchen_open_close,
        flood_relief_open_close: facilityDashboardDataReq.flood_relief_open_close,
        animal_shelter_open_close: facilityDashboardDataReq.animal_shelter_open_close,
      };

      // const inputDataForFacilityRegistered = {
      //   facility_registered_community_kitchen: facilityDashboardDataReq.facility_registered_community_kitchen,
      //   facility_registered_flood_relief: facilityDashboardDataReq.facility_registered_flood_relief,
      //   facility_registered_animal_shelter: facilityDashboardDataReq.facility_registered_animal_shelter
      // }

      const healthCampDataObj = transformArrayToObject(facilityDashboardDataReq.health_camp);
      const communityKitchenDataObj = transformArrayToObjectForMealType(facilityDashboardDataReq.community_kitchen);
      const floodReliefcampDataObj = transformArrayToObjectForMealType(facilityDashboardDataReq.flood_relief);
      const animalShelterDataObj = transformArrayToObjectForMealType(facilityDashboardDataReq.animal_shelter);
      const facilityRegisteredDataObj = transformArrayToObjectForFR(facilityDashboardDataReq.facility_registered);
      const communityKitchenMembersDataObj = transformArrayToObjectForMealType(facilityDashboardDataReq.community_kitchen_members);
      const openVsClosedDataObj = transformOpenCloseData(inputData);
      setHealthCampData(healthCampDataObj);
      setCommunityKitchenData(communityKitchenDataObj);
      setFloodReliefCampData(floodReliefcampDataObj);
      setAnimalShelterData(animalShelterDataObj);
      setFacilityRegisteredData(facilityRegisteredDataObj);
      setCommunityKitchenMembersData(communityKitchenMembersDataObj);
      setOpenVsClosedData(openVsClosedDataObj);
      // console.log(communityKitchenDataObj,"communittyyyykitchen");
      // console.log(floodReliefcampDataObj,"floodreliefdata");
      // console.log(healthCampDataObj,"healthhcamp");
      // console.log(animalShelterDataObj,"animalShelterr");
      // console.log(facilityRegisteredDataObj,"facilityregistereddata");
      // console.log(communityKitchenMembersDataObj,"communitymembers");
      // setFacilityDashboardData(facilityDashboardDataReq);
      // console.log(facilityDashboardDataReq, "checking data");
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  const getIncidentList = async () => {
    var incidentList = await postDataWithBodyFrGIS(getAllincidents, "", {});
    let inputincs = [];
    let name = "";
    if (incidentList && incidentList.data && incidentList.data[0]) {
      let incidentdata = incidentList.data[0];
      setInidentname(incidentdata["name"]);
      setIncidenttime(incidentdata["created_on"]);
    } else {
    }
  };
  const getFacilityType = async () => {
    let res = await postDataWithBody(getTaskConfigsURL, ["FCLTY_TYP"], {});
    let fm = {};
    res.forEach(function (node, elem) {
      fm[node.code] = node.id;
    });

    facilityCodeIdMap = fm;
  };

  
  const handleFromDateChange = (date) => {
    if (toDate && date > toDate) {
      setError(true);
      setFromDate(date);
    } else {
      setError(false); // Clear error if the dates are valid
      setFromDate(date);
    }
  };

  const handleToDateChange = (date) => {
    if (fromDate && date < fromDate) {
      setError(true);
      setToDate(date);
    } else {
      setError(false); // Clear error if the dates are valid
      setToDate(date);
    }
  };
  // const exportTooltip = (
  //   <Tooltip
  //     id="export-tooltip"
  //     style={{
  //       zIndex: 1050,
  //       transform: "translateX(-100px) translateY(10px)", // Moves the tooltip 10px to the left and 10px down
  //       overflow: "visible",
  //     }}
  //   > 
  //     <span> Select proper 'From Date' and 'To Date' <br /></span>
  //   </Tooltip>
  // );

  // const exportTooltip = (
  //   <Popover
  //     id="export-popover"
  //     style={{
  //       maxWidth: "200px",  // Increase max width to 350px or any desired value
  //       width: "100%",  // Optional: Ensure it spans the available width
  //       maxHeight:"60px",
  //       textAlign: "center", 
  //       display: "flex",  // Use flexbox to center the content
  //     justifyContent: "center",  // Horizontally center
  //       // backgroundColor: "yellow",
  //     }}
  //   >
  //     <Popover.Body>
  //       Select proper 'From Date' and 'To Date' <br />
  //       (<span>From Date should be less than To Date</span>)
  //     </Popover.Body>
  //   </Popover>
  // );
  
  
  useEffect(() => {
    if (fromDate && toDate && fromDate > toDate) {
      setError( "Select proper From Date and To Date");
    } else {
      setError(null);
    }
  }, [fromDate, toDate]);
  console.log(selectedDistrcit,"1")
  console.log(blockoptions,"2")
  console.log(allBlockIds,"3")
  console.log(facilityRegisteredData,"facilityRegisteredData");

  return (
    <div className="container-fluid p-0">
      <Loader
          show={loader}
          type="body"
          stack="vertical"
          message="Loading Data"
        />
      <div className="toprow--container">
        <div className="dash-title">Facility Dashboard</div>
        <div className="d-flex gap-3 align-items-center">
          <div className="date-picker-container">
            <span className="ms-1">{t("flooddisaster.datetxtfrom")}</span>
            <ReactDatePicker
            selected={fromDate}
            onChange={handleFromDateChange}
            dateFormat="dd/MM/yyyy"
            isClearable
            minDate={new Date(2024, 5, 1)} // June 1, 2024
            // maxDate={new Date()} // Today's date
            placeholderText={t("flooddisaster.dateplaceholder")}
            className="custom-datepicker ms-2"
          />
          </div>
          <div className="date-picker-container">
            <span>{t("flooddisaster.datetxtto")}</span>
            <ReactDatePicker
            selected={toDate}
            onChange={handleToDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={new Date(2024, 5, 1)} // June 1, 2024
            // maxDate={new Date()} // Today's date
            isClearable
            placeholderText={t("flooddisaster.dateplaceholder")}
            className="custom-datepicker ms-2"
          />
          </div>
          <div className="single-multiselect">
            <Multiselect
              style={externalStyle1}
              options={districtList}
              displayValue="name"
              customArrow={true}
              placeholder="Select District"
              singleSelect={true}
              closeOnSelect={true}
              onSelect={onSelectDistrict}
              selectedValues={selectedDistrcit}
              customCloseIcon={true}
            />
          </div>
          <div>
            <Multiselect
              style={externalStyle2}
              options={blockoptions}
              displayValue="name"
              isSelectAll={true}
              caseSensitiveSearch={false}
              placeholder={t("allblocks")}
              hidePlaceholder={true}
              singleSelect={false}
              ref={blockref}
              onSelect={onSelectBlock}
              showCheckbox={true}
            />
          </div>
          <div>
         {/* { error ? (
       <OverlayTrigger placement="top" overlay={exportTooltip}>
            <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={refreshDashBoards}
              disabled={!!error}
            />
            </OverlayTrigger>
             ):( */}
              <LMSButton
              size="btn_sm"
              label={t("flooddisaster.apply")}
              onClick={refreshDashBoards}
              disabled={!!error}
            />
             {/* )} */}
          </div>
        </div>
      </div>
      {error && <div className="error-message1">{error}</div>}
      <div className="grid-stack grid-stack-instance-1 mt-1">
        {layoutData.map((gridItem) => (
          <div
            className="grid-stack-item"
            gs-x={gridItem.x}
            gs-y={gridItem.y}
            gs-w={gridItem.width}
            gs-h={gridItem.height}
            key={gridItem.id}>
            <div
              className={`grid-stack-item-content ${
                gridItem?.haveNoBg ? " " : " grid-item"
              } ${
                gridItem?.haveOverflowVisible ? " apply__overflow--visible" : ""
              }`}>
              {gridItem.component}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DMSDashboard;
