import React, { useEffect } from "react";
import Select from "../Select/Select";
import TextInput from "../TextInput/TextInput";
import RadioButtonGroup from "../RadioButtonGroup/RadioButtonGroup";
import FileUpload from "../FileUpload/FileUpload";
import MultiFileUpload from "../MultiFileUpload/MultiFileUpload";
import MultiSelectWrapper from "../MultiSelect/MultiSelect";
import DatePicker from "../DatePicker/DatePicker";
import TextareaInput from "../TextareaInput/TextareaInput";
import HierarchySelect from "../HierarchySelect/HierarchySelect";
import Checkbox from "../Checkbox/Checkbox";
import FileUploadForMR from "../FileUploadForMasterResource/FileUploadForMR";

// import MapWrapper from "../../../../../Components/UiComponents/MapWrapper/MapWrapper";

const FormHandler = ({
  register,
  errors,
  setValue,
  getValues,
  fields,
  columns,
  control = {},
  setvendorName,
  onChange,
  checkboxOnChange,
  onChangeForRegion,
  openMapHandler,
  is_multi,
  isMultiSelectDisabled,
  imgName,
  isTextFieldDisable
}) => {
  // Function to check if "Quantity" should be disabled
  //const isQuantityDisabled = contactType === "Individual";
  //console.log("isQuantityDisabled=", isQuantityDisabled);

  // Helper function to determine if a field is mandatory
  const isFieldMandatory = (field) => {
    if (typeof field.mandatory === 'function') {
      const formValues = getValues ? getValues() : {};
      return field.mandatory(formValues);
    }
    return field.mandatory === "true";
  };

  const generateField = (field) => {
    let component;
    const {
      name,
      label,
      options,
      type,
      mandatory,
      id,
      heading,
      label1,
      label2,
      maxLength,
      disabled,
      is_multi,
      editId,
      defaultselect,
      disableField,
      style
    } = field;

     // Determine if the field is mandatory based on the dynamic logic
     const isMandatory = isFieldMandatory(field);

    switch (type) {
      case "input":
        component = (
          <TextInput
            name={name}
            label={label}
            maxLength={maxLength}
            register={register}
            errors={errors}
            mandatory={field.name === "Latitude" || field.name === "longitude" ? isMandatory : mandatory}
            disabled={disabled || isTextFieldDisable || disableField}
            openMapHandler={openMapHandler}
            isTextFieldDisable={isTextFieldDisable}
            style={style}
          />
        );
        break;
      case "select":
        component = (
          <Select
            name={name}
            label={label}
            register={register}
            errors={errors}
            options={options}
            mandatory={mandatory}
            defaultselect={defaultselect}
            disabled={isTextFieldDisable || disableField}
            isTextFieldDisable={isTextFieldDisable}
            style={style}
          />
        );
        break;
      case "hier_select":
        component = (
          <HierarchySelect
            name={name}
            label={label}
            register={register}
            errors={errors}
            options={options}
            onChange={onChange}
            control={control}
            mandatory={mandatory}
          />
        );
        break;
      case "multi_select":
        component = (
          <MultiSelectWrapper
            name={name}
            label={label}
            register={register}
            errors={errors}
            is_multi={is_multi}
            options={options}
            mandatory={mandatory}
            control={control}
            // disabled={disabled}
            disabled={isMultiSelectDisabled && (name != "contact_list" && name != "region_id") || isTextFieldDisable}
            isMultiSelectDisabled={isMultiSelectDisabled}
            onChangeForRegion={onChangeForRegion}
            isTextFieldDisable={isTextFieldDisable}
          />
        );
        break;

      case "file_upload":
        component = (
          <FileUpload
            label={label}
            register={register}
            name={name}
            errors={errors}
            mandatory={mandatory}
            control={control}
            id={id}
            setValue={setValue}
            getValues={getValues}
            editId={editId}
          />
        );
        break;
        case "file_upload_MR":
        component = (
          <FileUploadForMR
            label={label}
            register={register}
            name={name}
            errors={errors}
            mandatory={mandatory}
            control={control}
            id={id}
            setValue={setValue}
            getValues={getValues}
            editId={editId}
            imgName={imgName}
          />
        );
        break;
      case "multi_file_upload":
        component = (
          <MultiFileUpload
            label={label}
            register={register}
            name={name}
            errors={errors}
            mandatory={mandatory}
            control={control}
            id={id}
            setValue={setValue}
            getValues={getValues}
            editId={editId}
          />
        );
        break;
      case "text_area":
        component = (
          <TextareaInput
            label={label}
            register={register}
            name={name}
            errors={errors}
            mandatory={mandatory}
            control={control}
            id={id}
            disabled={isTextFieldDisable}
            isTextFieldDisable={isTextFieldDisable}
          />
        );
        break;
      case "checkbox":
        component = (
          <Checkbox
            label={label}
            onChange={checkboxOnChange}
            name={name}
            mandatory={mandatory}
            control={control}
            id={id}
            heading={heading}
          />
        );
        break;
      // case "map":
      //   component = (
      //     <MapWrapper
      //       label2={label2}
      //       // register={register}
      //       name={name}
      //       errors={errors}
      //       mandatory={mandatory}
      //       control={control}
      //       id={id}
      //       heading={heading}
      //     />
      //   );
      //   break;

      case "date":
        component = (
          <DatePicker
            label={label}
            register={register}
            name={name}
            errors={errors}
            mandatory={field.name === "paymentDate" ? isMandatory : mandatory}
            disabled={disabled || isTextFieldDisable || disableField} 
          />
        );
        break;
      default:
        break;
    }
    return component;
  };

  // Effect to handle dynamic field updates
  useEffect(() => {
    if (getValues && setValue) {
      const handleFieldUpdates = () => {
        const values = getValues();
        fields.forEach(field => {
          if (typeof field.mandatory === 'function') {
            // Trigger form validation if needed
            if (field.name === 'paymentDate' && values.paymentStatus !== '0') {
              setValue('paymentDate', '');
            }
          }
        });
      };

      handleFieldUpdates();
    }
  }, [fields, getValues, setValue]);

  const styles = {
    columnCount: columns,
    columnGap: "30%", // sets the size of the gap between columns
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
    gap: "0.9rem",
  };

  return (
    <div style={styles}>
      {fields?.map((field) => (
        <div style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}>
          {generateField(field)}
        </div>
      ))}
    </div>
  );
};
export default FormHandler;
