import React, { useEffect,useState,useRef,ReactElement,useLayoutEffect } from 'react'
import OpenLayerSource from './OpenLayerSource';
import { GridStack } from "gridstack";
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Draw from 'ol/interaction/Draw.js';
import 'ol/ol.css';
import './RTIncidentDashboard.css';
const RTIncidentDashboard = () => {
  
  const center = { lat: 25.74755419155397, lng:85.56225740541943  };
  const zoom = 8;
  const [map,setMap] = useState();
  const [source,setSource] = useState();
  const [draw,setDraw]=useState();
  const options = {
    staticGrid: true,
    column: 24,
  };
  const setMapInstance=(mapinst,sourceinst)=>{
    setMap(mapinst);
    setSource(sourceinst);
  };
  const drawInteraction=(option)=>{
    console.log(option);
    if (option !== 'None') {
      let drawinst = new Draw({
        source: source,
        type: option,
      });
      map.addInteraction(drawinst);
      setDraw(drawinst);
      drawinst.on("drawend", function (event) {
        event.stopPropagation();
        event.preventDefault();
        map.removeInteraction(drawinst);
      })
    }
  }
  useLayoutEffect(() => {
    var grid = GridStack.init(options);
  }, []);
  return (
    
    <>
   {/* <Accordion defaultActiveKey="-1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>GIS Filter</Accordion.Header>
        <Accordion.Body>
        <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="home" title="Region Filter">
      <div className="row">
      <div className="col-auto">
        <span className="input-group">
          <label className="input-group-text" for="type">Geometry type:</label>
          <select className="form-select" id="type" onChange={(event)=>{drawInteraction(event.target.value)}}>
            <option value="Point">Point</option>
            <option value="LineString">LineString</option>
            <option value="Polygon">Polygon</option>
            <option value="Circle">Circle</option>
            <option value="None">None</option>
          </select>
          <input className="form-control" type="button" value="Undo" id="undo"></input>
        </span>
      </div>
    </div>
      </Tab>
      <Tab eventKey="profile" title="Profile">
        Tab content for Profile
      </Tab>
      <Tab eventKey="longer-tab" title="Loooonger Tab">
        Tab content for Loooonger Tab
      </Tab>
      <Tab eventKey="contact" title="Contact" disabled>
        Tab content for Contact
      </Tab>
    </Tabs>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion> */}
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      {/* <GridItem layoutData={layoutData} /> */}
    
    <div
      className="grid-stack-item"
      gs-x="0"
      gs-y="0"
      gs-w="12"
      gs-h="13"
      key="0"
    >
      <div
        className='grid-stack-item-content  grid-item'
      >
        <OpenLayerSource zoom={zoom} center={center} onmapload={setMapInstance}/>
      </div>
    </div>
    {/* <div
      className="grid-stack-item"
      gs-x="8"
      gs-y="0"
      gs-w="4"
      gs-h="13"
      key="1"
    >
      <div
        className='grid-stack-item-content  grid-item'
      >
        <div>
        <form>
        <label style={{minWidth:"97%"}}>
           Select Incident
        <Select
        styles={customStyles}
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={events}
        isSearchable
      />
      </label>
      </form>
        </div>
      </div>
    </div> */}

    </div>
    </>
  )
}

export default RTIncidentDashboard;