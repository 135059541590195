import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Loader from "react-spinner-loader";
import { Deleteicon, Editicon } from "../../../../Components/IconComponents";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import Table from "../../../../Components/UiComponents/Table/Table";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { getData, putDataWithBody } from "../../../../Services/Services";
import { deleteUserById, getUserLog, getUsersFromApp } from "../../../../Services/EndPoints";
import { contactTableData, userActivityTableDataForExport, userTableDatacolumnsForExport } from "../../../../JsonData/ContactConfig";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";
// import {
//   Editicon,
//   Deleteicon,
//   Viewicon,
// } from "../../../../Components/IconComponents";
// import Table from "../../../../Components/UiComponents/Table/Table";
// //import { Modal, Button } from "react-bootstrap";
// import {
//   contactTableData,
//   userTableDatacolumnsForExport,
// } from "../../../../JsonData/ContactConfig";
// import {
//   getUsersFromApp,
//   deleteContactURL,
//   deleteUserById,
// } from "../../../../Services/EndPoints";
// import { toast, ToastContainer } from "react-toastify";
// import { useDispatch } from "react-redux";
// import { getData, putDataWithBody } from "../../../../Services/Services";
// import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
// import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
// import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
// import StatusHandler from "../../Common/StatusHandler/StatusHandler";
// import { useTranslation } from "react-i18next";
// import { RoleContext } from "../../../..";

const UserActivityTable = forwardRef((props, ref) => {
  const { roleName } = useContext(RoleContext);
  const dispatch = useDispatch();
  const qs = require("qs");
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const { t, i18n } = useTranslation();
  const [searchData, setSearchData] = useState([]);


//   const formatSearchData = (data) => {
//     return data.map(role => {
//         // Create a string of comma-separated permission names
//         const formattedUserRole = role.user_role_map.map(obj => {
//             return obj.roleEntity.name;
//         }).join(", "); // Join the permission names with a comma and space

//         // Return the role with formatted permissions as a string
//         return {
//             ...role,
//             user_role_map: formattedUserRole, // Replace array with the formatted string
//         };
//     });
// };

//   console.log(searchData,"searchuserdata");
//   useEffect(() => {
//     const formattedData = formatSearchData(searchData);
//     console.log(formattedData,"formatedUserroles");
//     sendDataToCentralStore(formattedData);
//   }, [searchData]);

  useEffect(() => {
    getUserList();
  }, []);
  
//   const addNewHandler = () => {
//     navigate("/users/create-user");
//   };

  const getShortenedText = (strLength, fulltext) => {
    let abbreviatedText = "";
    if (fulltext.length > strLength) {
      abbreviatedText = fulltext.substring(0, 15) + "...";
    } else {
      abbreviatedText = fulltext;
    }
    return <span title={fulltext}>{abbreviatedText}</span>;
  };
  const getUserList = async () => {
    setLoader(true);
    var userList = await getData(getUserLog);
    sendDataToCentralStore(userList);
    setLoader(false);
    console.log('ssss',userList);
    setUserData(userList);
    setRowCount(userList.length);
  };
  //   useEffect(() => {
  //     if (props.locationInstance && props.locationInstance.msg != "") {
  //       toast.success("Agency has been created successfully.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     }
  //     getAgecnyList();
  //   }, []);

//   const sendDataToCentralStore = (data) => {
//     if (data.length > 0) {
//       // Step 1: Sort data by date and time in descending order
//       const sortedData = [...data].sort((a, b) => {
//         // Combine date and time for accurate sorting
//         const datetimeA = new Date(`${a.date}T${a.time}`);
//         const datetimeB = new Date(`${b.date}T${b.time}`);
        
//         return datetimeB - datetimeA; // Sort descending
//       });
  
//       // Step 2: Export the sorted data
//       const columns = userActivityTableDataForExport; // Columns config
//       dispatch(
//         setTableData({
//           columns,
//           tableData: getExportedColumnsData(columns, sortedData),
//         })
//       );
//     }
//   };

const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      // Step 1: Format the date for each record
      const formattedData = data.map((item) => {
        // Format the date as dd-mm-yyyy
        const date = new Date(item.date);
        const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
        return { ...item, date: formattedDate }; // Update the date field
      });
  
      // Step 2: Export the formatted data
      const columns = userActivityTableDataForExport; // Columns config
  
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, formattedData), // Send the formatted data
        })
      );
    }
  };
  
  
  //   const navToCreatAgency = (parentAgencyId) => {
  //     navigate("/agencylist/createagency", {
  //       state: { parentAgencyId: parentAgencyId },
  //     });
  //   };
  //   const getAgecnyList = async () => {
  //     var agencyList = await getData(agencyListURL);
  //     setAgencyData(agencyList);
  //     sendDataToCentralStore(agencyList);
  //   };

  //   Name:"",
  //   Phone1:"",
  //   Phone2:"",
  //   Email:"",
  //   Address:"",
  //   ContactType:"",
  //   Agency:"",

 // Define sorting functions outside the component
// const dateTimeSort = (rowA, rowB) => {
//     const datetimeA = new Date(`${rowA.values.date}T${rowA.values.time}`);
//     const datetimeB = new Date(`${rowB.values.date}T${rowB.values.time}`);
//     return datetimeB - datetimeA; // descending order
//   };
  
  const columns = React.useMemo(
    () => [
        {
            Header: t("useractivitytableheaders.name"),
            accessor: "name",
            disableSortBy: true,
          },
          {
            Header: t("useractivitytableheaders.username"),
            accessor: "user_name",
            disableSortBy: true,
          },
          {
            Header: t("useractivitytableheaders.agency"),
            accessor: "agency",
            disableSortBy: true,
          },
        //   {
        //     Header: t("useractivitytableheaders.user_region"),
        //     accessor: "user_region",
        //     disableSortBy: true,
        //   },
          {
            Header: t("useractivitytableheaders.detail"),
            accessor: "detail",
            disableSortBy: true,
          },
          {
            Header: t("useractivitytableheaders.activity"),
            accessor: "activity",
            disableSortBy: true,
          },
         
          {
            Header: t("useractivitytableheaders.date"),
            accessor: "date",
            disableSortBy: true, // Disable sorting on the date column
            Cell: (props) => {
              // Format the date as dd-mm-yyyy
              const date = new Date(props.value);
              const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
              
              return <span>{formattedDate}</span>;
            },
          },
          {
            Header: t("useractivitytableheaders.time"),
            accessor: "time",
            disableSortBy: true, // Disable sorting on the time column
            Cell: (props) => {
              return <span>{props.value}</span>; // Custom Cell rendering for time
            },
          },
    //   {
    //     Header: t("useractivitytableheaders.phone"),
    //     accessor: "phone_number",
    //     disableSortBy: true,
    //   },
    //   {
    //     Header: t("useractivitytableheaders.role"),
    //     accessor: "role",
    //     disableSortBy: true,
    //   },
      
      
      
    //   {
    //     Header: t("useractivitytableheaders.job_title"),
    //     accessor: "job_title",
    //     disableSortBy: true,
    //   },
    //   {
    //     Header: t("useractivitytableheaders.time"),
    //     accessor: "time",
    //     disableSortBy: true,
    //     sortType: dateTimeSort, // Same here for time column
    //     initialSortDesc: true,
    //     Cell: (props) => {
    //       return <span>{props.value}</span>; // Custom Cell rendering for time
    //     },
    //   },
    //   {
    //     Header: t("useractivitytableheaders.ipaddress"),
    //     accessor: "ip_address",
    //     disableSortBy: true,
    //   },
    ],
    [t]
  );
  

  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {contactTableData && (
          <Table
            data={userData}
            columns={columns}
            numberOfRows={10}
            setSearchData={setSearchData}
            // addButtonLabel={t("createuseraddbuttonlabel")}
            // addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid  #f0f0f0",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default UserActivityTable;
