import { React } from "react";
import "./SearchBox.css";
import { SearchIconLeft } from "../../../../IconComponents/index.js";
import { useTranslation } from "react-i18next";
const SearchBox = ({ searchValue, onSearchHandler }) => {
  const { t } = useTranslation();
  return (
    <form className="search-form">
      <input
        type="text"
        placeholder={t("searchPlaceholder")}
        value={searchValue}
        onChange={(e) => onSearchHandler(e.target.value)}
      />
      <button disabled={true}>
        <span className="search-icon">
          <SearchIconLeft width="1.3em" height="1.3em" />
        </span>
      </button>
    </form>
  );
};
export default SearchBox;
