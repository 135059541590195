import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
//import DailyFloodReportCompTable from "./DailyFloodReportCompTable";
import { useTranslation } from "react-i18next";
import HumanLossReportForm from "./HumanLossReportForm";

const HumanLossReport = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Human Loss Report" />
      </div>
      <div className="widget-container p-1">
        <HumanLossReportForm />
      </div>
    </div>
  );
};

export default HumanLossReport;
