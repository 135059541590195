import React, { Fragment, useEffect, useState } from 'react';
import './MajorRivers.css';
import { getData, postDataWithBody } from '../../../../../Services/Services';
import { getRiverListURL, updateFloodReport } from '../../../../../Services/EndPoints';
import { Button } from 'react-bootstrap';
 
const MajorRivers = ({ list,submitHandler, dailyFloodReportData }) => {
  const [riverCheckboxConfig, setRiverCheckboxConfig] = useState([]);
 
  console.log("Received prop", list);
 
  useEffect(() => {
    const fetchRiverData = async () => {
      try {
        const data = await getData(getRiverListURL);
        console.log("DATAriver:",data);
        const updatedConfig = data.map(item => ({
          id: item.id,
          name: item.value,
          checked: list.includes(item.id),
        }));
        setRiverCheckboxConfig(updatedConfig);
        console.log("Rivers data fetched and config updated:", updatedConfig);
      } catch (error) {
        console.error("Error fetching rivers data:", error);
      }
    };
 
    fetchRiverData();
  }, [list]);
  console.log("River Checkbox Config:", riverCheckboxConfig);
 
  const handleCheckboxChange = (id) => {
    setRiverCheckboxConfig(prevState =>
      prevState.map(river =>
        river.id === id ? { ...river, checked: !river.checked } : river
      )
    );
  };
 
 
  // Save and submit data to API
  const handleSaveAndSubmit = async () => {
    const affected_river_list = riverCheckboxConfig
      .filter((river) => river.checked)
      .map((river) => ({river_id: river.id, river_name: river.name}));
      
 
    console.log("Selected River IDs:", affected_river_list);
 
    // Create the data object
    const data = {
      id: dailyFloodReportData[0].id, // Replace "reportId" with a valid ID or fetch it dynamically
      affected_river_list
    };
 
    // console.log(idValue,"idValue",dailyFloodReportData[0].id,"dailyFloodReportData")
 
    try {
      // Send data to the backend
      // const response = await postDataWithBody(updateFloodReport, data, {});
      // console.log("Data successfully updated:", response);
 
      // Call submitHandler with the constructed data
      if (submitHandler) {
        submitHandler(data); // Ensure a valid `data` object is passed
      }
      console.log("Data successfully updated2:", data);
 
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };
 
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };
 
  const riverChunks = chunkArray(riverCheckboxConfig, 5);
 
  return (
    <Fragment>
    <div className="rivers-container">
      {riverChunks.map((chunk, index) => (
        <div key={index} className="river-row">
          {chunk.map(river => (
            <div key={river.id} className="river-item">
              <input
                type="checkbox"
                id={`river-${river.id}`}
                checked={river.checked}
                onChange={() => handleCheckboxChange(river.id)}
              />
              <label htmlFor={`river-${river.id}`}>{river.name}</label>
            </div>
          ))}
         
        </div>
      ))}
    </div>
    <div className="button-container">
    <Button
          type="button"
          className="mx-2"
          variant="primary"
          onClick={handleSaveAndSubmit}
        >
          Save and Submit
        </Button>
        </div>
  </Fragment>
  );
};
 
export default MajorRivers;