import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../../../../src/Components/IconComponents/XCircleblack";
import {
    Editicon,
    Deleteicon,
    Viewicon
} from "../../../../Components/IconComponents";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import {
    SOPTableData,
    columnsForExport,
} from "../../../../JsonData/SOPConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {getMasterSOPListURL,getSOPRsrcDtlBySOPId } from "../../../../Services/EndPoints";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";


const SOPResourceTable = (props) => {
    const dispatch = useDispatch();
    const qs = require("qs");
    const [rowCount, setRowCount] = useState(0);
    const [SOPData, setSOPData] = useState([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const navigate = useNavigate();
     useEffect(() => {
         getSOPResourceList();
     }, []);

 
    const addNewHandler = () => {
        navigate("/planning/create-sop");
    };
    const openSOPDataTable = async () => {
       setShow(true);
    };
    const getShortenedText=  (strLength,fulltext) => {
        let abbreviatedText = "";
        if(fulltext.length>strLength){
            abbreviatedText =  fulltext.substring(0,15)+"..."
        }
        else{
            abbreviatedText = fulltext;
        }
        return <span title={fulltext}>{abbreviatedText}</span>
    }
    const hideModal = () => {
        setShow(false);
    };
    const getSOPResourceList = async () => {
        var sopRscrList = await getData(getSOPRsrcDtlBySOPId+props.sopInstanceData.id);
        setRowCount(sopRscrList.length);
        setSOPData(sopRscrList);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: "Name",
                disableSortBy: true,
                accessor: "name"
            },
            {
                Header: "Activity",
                disableSortBy: true,
                accessor: "rsrc_activity_id"
            },
            {
                Header: "Category",
                disableSortBy: true,
                accessor: "rsrc_cat_id"
            },

            {
                Header: "Created On",
                disableSortBy: true,
                accessor: "created_on",
                Cell: (props) => {
                   
                    return (
                        <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>
                    )
                },
              },
              {
                Header: "Updated On",
                disableSortBy: true,
                accessor: "updated_on",
                Cell: (props) => {
                   
                    return (
                        <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>
                    )
                },
              }
        ],
        []
    );
    return (
        <div className="container  create-user">
            SOP Resource Map
            <div className="datatable__container">
                {SOPTableData && (
                    <Table
                        data={SOPData}
                        rowCount={rowCount}
                        columns={columns}
                        numberOfRows={7}
                        showExport={false}
                        showFilter={false}
                        showReset={false}
                        onExpand={false}
                        headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
                        tableStyle={{ border: "none", padding: "0px" }}
                    />
                )}
            </div>
        </div>
    );
};

export default SOPResourceTable;






// /planning/create - sop