import * as yup from "yup";
export const schema = [
  yup.object({
    first_name: yup
      .string()
      .required("Please provide the first name")
      .max(30, "Name cannot exceed 30 characters")
      .matches(/^[A-Za-z]+(?:\s[A-Za-z]+)*$/, "Please enter valid name"),
    phone_number: yup
      .string()
      .required("Please provide the phone number")
      .matches(
        /^(?:\+91|0)?[6789]\d{9}$/,
        "Please provide a valid phone number"
      )
      .max(15, "Phone number must be at most 15 digits"),

    secondary_phone_number: yup
      .string()
      .nullable() // Allow null values (empty string)
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      ) // Transform an empty string to null
      .test(
        "is-valid-phone",
        "Please provide a valid phone number",
        (value) => value === null || /^(?:\+91|0)?[6789]\d{9}$/.test(value)
      )
      .max(15, "Phone number must be at most 15 digits"),
    role_list: yup
      .array()
      .required("Please select the role")
      .min(1, "Pick atleast 1")
      .of(
        yup.object().shape({
          value: yup.string().required(),
        })
      ),
    user_name: yup
      .string()
      .required("User name is required")
      .email("Please enter a valid email"),
    address: yup
    .string()
    .nullable()
    .max(200, "Description must be at most 200 characters"),
    region: yup
      .mixed()
      .test("valid-region", "Region is required", (value) => {
        // Adjust based on whether it's an object or string
        if (typeof value === "string") {
          return value && value !== "";
        } else if (typeof value === "object" && value !== null && value.length) {
          return value[0].region_id && value[0].region_id !== ""; // Example if region is an object
        }
        return false;
      })
      .nullable()
      .required("Please select a region"),
  }),
];

export const CreateUserConfig = [
  {
    id: 0,
    name: "",
    heading: "",
    fields: [
      {
        name: "first_name",
        label: "userformlabels.firstname",
        type: "input",
        mandatory: "true",
      },
      {
        name: "last_name",
        label: "userformlabels.lastname",
        maxLength: "15",
        type: "input",
      },
      {
        name: "role_list",
        label: "userformlabels.roles",
        type: "multi_select",
        mandatory: "true",
      },
      {
        name: "phone_number",
        label: "userformlabels.phone1",
        type: "input",
        maxLength: "15",
        mandatory: "true",
      },
      {
        name: "secondary_phone_number",
        label: "userformlabels.phone2",
        // maxLength: "15",
        type: "input",
      },
      {
        name: "user_name",
        label: "userformlabels.username", //(For ex: someone@domain.com)
        maxLength: "60",
        type: "input",
        mandatory: "true",
      },
      {
        name: "agency_id",
        label: "userformlabels.agency",
        type: "select",
        mandatory: "true",
      },
      {
        name: "region",
        label: "userformlabels.region",
        type: "hier_select",
        mandatory: "true",
      },
      {
        name: "job_title_id",
        label: "userformlabels.jobtitle",
        type: "select",
        mandatory: "true",
      },
      {
        name: "address",
        label: "userformlabels.address",
        type: "text_area",
      },
      {
        name: "notification_on",
        label: "Send Mobile Notification",
        type: "checkbox",
        defaultValue: "true",
      },
    ],
  },
];
