import React from "react";

import { useState, useEffect } from "react";
import "./BlockDetails.css";
import { useTranslation } from "react-i18next";
import Table from "../../../Components/UiComponents/Table/Table";

const DistrictBlockDetails = (props) => {
  console.log("blockdata from Blockdetails component=", props.data);
  const { t } = useTranslation();
  console.log(props.column);
  const headerTableHeadStyle = {
    background: "#18a3a0",
    color: "#FFFFFF",
    textAlign: props.column[0].centerAlign ? "center" : "left",
  };
  return (
    <div className="blockdetails">
      <div className="container  create-user">
        <div className="datatable__container">
          {props?.data && (
            <Table
              data={props?.data}
              columns={props.column}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              showRowCount={false}
              tabChanger={true}
              activeTab={props.activeTab}
              setActiveTab={props.setActiveTab}
              headerTableHeadStyle={headerTableHeadStyle}
              tableStyle={{
                padding: "2px",
              }}
              applyTableHeaderCustomClass={true}
              disbalePaginationFilter={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DistrictBlockDetails;
