import React, { useState } from "react";
import Sidebar from "../../Layout/Sidebar/Sidebar";
import Header from "../../Layout/Header/Header";
import { Outlet } from "react-router-dom";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import Signout from "../../Layout/Signout/Signout";

const DMS = () => {
  const { collapsed } = LayoutState();

  return (
    <div className={`${collapsed ? "main__layout-collapsed" : "main__layout"}`}>
      <Sidebar />
      <div>
        <Signout />
      </div>
      <Header />
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default DMS;
