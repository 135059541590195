import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../IconComponents/XCircleblack";
import {
  postDataWithBody,
  getData,
  putDataWithBody,
} from "../../../Services/Services";
import { createConfig, updateConfig } from "../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation} from "react-i18next";
import { ThemeContext } from "../../..";
const ModalResourceType = (props) => {
  const {
    title,
    titleValue,
    show,
    handleToggle,
    size,
    popupLabel,
    buttonLabel,
    data,
  } = props;
  console.log("update value=", data);
const { theme } = useContext(ThemeContext);
  // Create a state variable to hold the input field value
  const [inputValue, setInputValue] = useState(data || ""); // Initialize with the provided data value
  const { t } = useTranslation();
  async function saveConfigData() {
    var res = null;
    const configVal = inputValue; // Get the value from the state
    if (data) {
      data.value = configVal;
      res = await putDataWithBody(updateConfig, data, {});
      if (res.status == "success") {
        props.showSuccessMsg(props.selectedConfigId);
        if (props.onAddJobTitle) props.onAddJobTitle(configVal); // Call the callback here with the entered job title
        handleToggle();
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
      }
    } else {
      console.log("configVal", configVal);
      let dataElem = {
        categoryName: props.selectedConfigId,
        code: props.selectedConfigId,
        value: configVal,
        status: true,
      };

      if (props.onAddJobTitle){
        props.onAddJobTitle(configVal);
      } else {
        res = await postDataWithBody(createConfig, dataElem, {});
        if(res.status == "success"){
          props.showSuccessMsg(props.selectedConfigId);
          handleToggle();
        }
        else{
          toast.error(res.msg, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000, // Close after 2 seconds
          });
        }
      }
    }
   
  }
  // Update the inputValue state when the input field changes
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleToggle}
        id={theme}
        size={size}
        className="table__modal__popup"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {t(title)}
            {t(titleValue)}
          </Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={handleToggle}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div class="form-group">
              <label
                className="create__resource--label"
                for="tfld_config_value"
              >
                {popupLabel}
              </label>
              <input
                type="text"
                maxLength={40}
                class="form-control"
                id="tfld_config_value"
                //value={data}
                value={inputValue.value}
                onChange={handleInputChange} // Add onChange event handler
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={saveConfigData}
            type="button"
            className="btn btn-success"
            style={{ backgroundColor: "#40B2B7" }}
          >
            {t(buttonLabel)}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalResourceType;
