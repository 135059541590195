import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ImportPopup.css";
import { Redclosebutton } from "../../../../Components/IconComponents";
import ImportFileForm from "./ImportfileForm/ImportFileForm";

const ImportPopup = (props) => {
  const { title, show, handleToggle, size, importEndpoint,downloadTemplate } = props;
  return (
    <Modal
      show={show}
      onHide={handleToggle}
      size={size}
      className="import__popup"
      centered
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <Redclosebutton onClick={handleToggle} width="1.5em" height="1.5em" />
      </Modal.Header>
      <Modal.Body>
        <ImportFileForm
          downloadTemplate={downloadTemplate}
          importEndpoint={importEndpoint}
          handleToggle={handleToggle}
         
        />
      </Modal.Body>
    </Modal>
  );
};

export default ImportPopup;
