import axios from "axios";
import { baseUrl } from "../../../Services/EndPoints";


export async function getData(endPoint) {
  try {
    const response = await axios.get(baseUrl + endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function deleteData(endPoint, id) {
  try {
    const url = `${baseUrl}${endPoint}/${id}`;
    const response = await axios.delete(url);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function postDataWithBody(endPoint, body) {
  try {
    
    const response = await axios.post(baseUrl + endPoint, body);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function editData(endPoint, data, id) {
  try {
    const url = `${baseUrl}${endPoint}/${id}`;
    const response = await axios.put(url, data);

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error);

    return [];
  }
}

//     const response = await axios.put(url, data);

//     if (response.status === 200) {
//       return response.data;
//     } else {
//       console.log(response);
//     }
//   }. catch (error) {
//     console.log(error);

//     return [];
//   }
// }
export async function postDatawithFormData(endPoint, formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const response = await axios.post(baseUrl + endPoint, formData, config);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

export async function putDatawithFormData(endPoint, formData, id) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const url = `${baseUrl}${endPoint}/${id}`;
    const response = await axios.put(url, formData, config);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}

// postDatawithPayload
