import React from "react";
import Select from "react-select";
import "./MultiSelect.css";
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';

const MultiSelectWrapper = ({
  options,
  onChangeForRegion,
  errors,
  name,
  label,
  is_multi,
  mandatory,
  control,
  disabled = false, // Add disabled prop
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#fff" : "#000",
    }),
    control: (base, state) => ({
      ...base,
      background: "#252b48",
      fontSize: "12px",
      borderColor: state.isFocused
        ? "#ffa100"
        : errors && errors[name]
        ? "#f4694c"
        : "#fff",
      boxShadow: state.isFocused ? null : null,
      "&:hover": {
        borderColor: state.isFocused ? "#ffa100" : "#fff",
      },
      // Apply custom styles if the select is disabled
      ...(disabled && {
        backgroundColor: "#7c8091", // Disabled background color
        color: "#7c8091", // Disabled text color
        cursor: "not-allowed", // Change cursor to indicate it's disabled
        borderColor: "#7c8091", // Light border color when disabled
      }),
    }),

    dropdownIndicator: (base) => ({
      ...base,
      color: "#17A3A0", // Custom colour
    }),
  };

  const { t } = useTranslation();

  return (
    <div id={"container_" + name}>
      <label className="form__label">
        {t(label)} {mandatory && <span className="asterisk">*</span>}
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Select
          styles={customStyles}
          placeholder={t("multiselectplaceholder")}
          classNamePrefix="react-select"
          theme={(theme) => ({
            ...theme,
            borderRadius: 0,
            colors: disabled
              ? {}  // If disabled, set the colors to an empty object
              : {
                  ...theme.colors,
                  primary25: "#17A3A0",
                  neutral50: "#fff",
                  neutral80: "#fff",
                  primary: "black",
                },
          })}
          value={value}
          onChange={(selected) => {
            onChange(selected);
            onChangeForRegion();
          }}
          options={options}
          isMulti={is_multi === false ? false : true}
          isDisabled={disabled} // Set the disabled property
        />
        
        )}
      />
      <p className="error__message">{errors[name]?.message}</p>
    </div>
  );
};

export default MultiSelectWrapper;
