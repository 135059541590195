import React from "react";

import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Table from "../../../../Components/UiComponents/Table/Table";
import { getMealsDetailsURL } from "../../../../Services/EndPoints";
import { postDataWithBody } from "../../../../Services/Services";

const CommunityKitchenBreakFastDetails = (props) => {
  const { t } = useTranslation();
  const [breakfastData, setBreakFastData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    getMealsDetailsData(props.mealTypeCd, props.facilityreportId);
  }, []);

  const getMealsDetailsData = async (mealTypeCd, facilityreportId) => {
    let inputobj = {
      facilityReportIds: [parseInt(facilityreportId)],
      facilityOfferingTypeCd: mealTypeCd,
    };
    var mealdataarr = await postDataWithBody(getMealsDetailsURL, inputobj, {});

    let totalAmount = 0;
    mealdataarr.forEach(function (data, node) {
      if (
        data.facility_report_attribute_value &&
        !isNaN(data.facility_report_attribute_value)
      ) {
        totalAmount += parseInt(data.facility_report_attribute_value);
      }
    });
    mealdataarr.push({
      attribute: "Total",
      facility_report_attribute_value: totalAmount,
    });

    mealdataarr.sort((a, b) => {
      // Keep "Total" at the end
      if (a.attribute === "Total") return 1;
      if (b.attribute === "Total") return -1;
    
      // Define group labels for male, female, and transgender
      const maleGroup = "Males";
      const femaleGroup = "Females";
      const transgenderGroup = "Transgender";
    
      // Check if the attribute belongs to the group labels
      const isMaleGroupA = a.attribute === maleGroup;
      const isMaleGroupB = b.attribute === maleGroup;
      const isFemaleGroupA = a.attribute === femaleGroup;
      const isFemaleGroupB = b.attribute === femaleGroup;
      const isTransgenderGroupA = a.attribute === transgenderGroup;
      const isTransgenderGroupB = b.attribute === transgenderGroup;
    
      // Ensure "Males", "Females", "Transgender" come before their sub-categories
      if (isMaleGroupA && !isMaleGroupB) return -1;
      if (!isMaleGroupA && isMaleGroupB) return 1;
    
      // Now we are in the sub-category comparison: Male (0-1), Female (0-1), etc.
    
      // For "Male" sub-categories
      const isMaleSubCategoryA = a.attribute.includes("Male");
      const isMaleSubCategoryB = b.attribute.includes("Male");
    
      if (isMaleSubCategoryA && !isMaleSubCategoryB) return -1;
      if (!isMaleSubCategoryA && isMaleSubCategoryB) return 1;

      if (isFemaleGroupA && !isFemaleGroupB) return -1;
      if (!isFemaleGroupA && isFemaleGroupB) return 1;
    
      // For "Female" sub-categories
      const isFemaleSubCategoryA = a.attribute.includes("Female");
      const isFemaleSubCategoryB = b.attribute.includes("Female");
    
      if (isFemaleSubCategoryA && !isFemaleSubCategoryB) return -1;
      if (!isFemaleSubCategoryA && isFemaleSubCategoryB) return 1;


      if (isTransgenderGroupA && !isTransgenderGroupB) return -1;
      if (!isTransgenderGroupA && isTransgenderGroupB) return 1;
    
      // For "Transgender" sub-categories
      const isTransgenderSubCategoryA = a.attribute === "Transgender";
      const isTransgenderSubCategoryB = b.attribute === "Transgender";
    
      if (isTransgenderSubCategoryA && !isTransgenderSubCategoryB) return -1;
      if (!isTransgenderSubCategoryA && isTransgenderSubCategoryB) return 1;
    
      // Fallback to alphabetical order if they're from the same group
      return a.attribute.localeCompare(b.attribute);
    });
    
    setRowCount(mealdataarr.length);
    setBreakFastData(mealdataarr);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("communitykitchenpopuptable.name"),
        accessor: "attribute",
        disableSortBy: true,
      },
      {
        Header: t("communitykitchenpopuptable.value"),
        accessor: "facility_report_attribute_value",
        disableSortBy: true,
      },
    ],
    []
  );
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {breakfastData && (
            <Table
              data={breakfastData}
              addButtonLabel="Create UserTask"
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount} // Pass rowCount to the Table component
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
              applyTableHeaderCustomClass={true}
              disbalePaginationFilter={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommunityKitchenBreakFastDetails;
