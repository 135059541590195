import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import DynamicTabs from "../../Common/Tabs/Tabs";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import useFormPersist from "react-hook-form-persist";
import { toast, ToastContainer } from "react-toastify";
import { schema,AgencyFormConfig } from "../../../../JsonData/AgencyConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  postDataWithBody,
  putDataWithBody,
  getData,
} from "../../../../Services/Services";
import {
  createAgencyURL,
  agencyTypeURL,
  keycloakbaseurl,
  updateAgencyURL,
  getallRolesByRealm,
} from "../../../../Services/EndPoints";
import api from "../../../../Services/api";
import Loader from "react-spinner-loader";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
const AgencyRegistrationForm = (props) => {
  const [loader, setLoader] = useState(false);
  const [agencyConfig, setAgencyConfig] = useState(AgencyFormConfig);
  const navigate = useNavigate();
  const [agencyListArr, setAgencyListArr] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useLocation();
  const [roleIdMap, setRoleIdMap] = useState({});
  const [roleData, setRoleData] = useState([]);
  const { t } = useTranslation();
  let dataFrEdit = state?.data;
  let systemDefinedRoles = [
    "default-roles-" + process.env.REACT_APP_URM_REALM,
    "offline_access",
    "uma_authorization",
  ];
  

  const { keycloak } = useKeycloak();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

 
  useEffect(() => {
    setLoader(true);
    if (dataFrEdit) {
      populateRegionFormFrEdit(dataFrEdit);
      setLoader(false);
    } else {
      reset();
       setLoader(false);
    }
  }, [dataFrEdit]);

  useEffect(() => {
    getAgencyTypeList();
    //fetchAllRoles();
  }, []);

  function populateRegionFormFrEdit(formdata) {
    reset(formdata);
    setValue("agency_type_id", formdata.agency_type_id);
    //setValue("name", formdata.name);
    //setValue("description", formdata.description);
    //setValue("agency_type_id", formdata.agency_type_id);
  }
  async function getAgencyTypeList() {
     setLoader(true);
    var agencyList = await getData(agencyTypeURL);
     setLoader(false);
    for (let agency in agencyList) {
      agencyList[agency]["label"] = agencyList[agency]["value"];
      agencyList[agency]["value"] = agencyList[agency]["id"];
    }
    
    agencyConfig[0].fields[1]["options"] = agencyList;
    setAgencyConfig(agencyConfig);
    setAgencyListArr(agencyList);
  }
  const getLoggedInUserAuthToken = async () => {
    return keycloak.token;
  };
  const fetchAllRoles = async () => {
    let url =
      keycloakbaseurl +
      getallRolesByRealm.replace("{REALM}", process.env.REACT_APP_URM_REALM);
    const authToknObj = await getLoggedInUserAuthToken();
    let headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + authToknObj,
    };
    const response = await api.getData(url, {}, headers);
    let rolearr = [];
    for (let i = 0; i < response.data.length; i++) {
      var dataobj = response.data[i];
      roleIdMap[dataobj.id] = dataobj;
      if (!(systemDefinedRoles.indexOf(dataobj.name) > -1)) {
        rolearr.push({
          label: dataobj.name,
          value: dataobj.id,
        });
      }
    }
    agencyConfig[0].fields[2]["options"] = rolearr;
    setAgencyConfig(agencyConfig);
  };

  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
      {
        (agencyListArr.length>0) && (<FormHandler
        register={register}
        errors={errors}
        fields={fields}
        control={control}
        columns={2}
        getValues={getValues}
      />)}
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    e.preventDefault();
    data.parent_agency_id = props.parentAgencyId;
    data.status = true;
    let res = null;
     setLoader(true);
    if (dataFrEdit) {
      data["id"] = dataFrEdit.id;
      delete data["created_on"];
      delete data["updated_on"];
      res = await putDataWithBody(updateAgencyURL, data, {});
       setLoader(false);
    } else {
      res = await postDataWithBody(createAgencyURL, data, {});
       setLoader(false);
    }

    /*let agency_role_map = [];
    for (let role in data.role_list) {
      agency_role_map.push({ role_id: data.role_list[role].value });
    }
    data["agency_role_map"] = agency_role_map;*/

    toast.success(res.msg, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
    navigate("../../agency-list");
  };

  const navToAgencyList = () => {
    navigate("../agency-list/");
  };

  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };

  const tabsData = generateTabsData(agencyConfig);

  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="form-body">
        <div className="registration__form--body">
          <form onSubmit={handleSubmit(submitHandler)} name="AgancyRegForm">
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tab.heading}
                      </div>
                      <div>{tab.component}</div>
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary">
                 {t('saveAndSubmit')}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AgencyRegistrationForm;
