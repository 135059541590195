import React, { useEffect } from "react";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../DMSHeader/DMSHeader";
import FacilityList from "./FacilityList/FacilityList";
import { DownlaodNew } from "../../../Components/IconComponents";
import { useTranslation } from 'react-i18next';
const FacilityManagement = () => {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('facility_management_header')}
          showdownload={true}
          showImportDropDownLabel={false}
          fileName="FacilityManagement"
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 12,
      id: 2,
      component: <FacilityList />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default FacilityManagement;