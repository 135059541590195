import React, { useState, useEffect, createContext, useContext } from "react";
import "./Header.css";
import api from "../../Services/api";
import { Link, useNavigate } from "react-router-dom";
import avatar from "../../Assets/Images/avatar.png";
import { useKeycloak } from "@react-keycloak/web";
import { BurgerIcon, BurgerIconClosed } from "../../Components/IconComponents";
import { LayoutState } from "../../Context/LayoutContext/LayoutContext";
import {
  urmservice,
  userImageUpload,
  mediaFileLink,
} from "../../Services/EndPoints";
import { useTranslation } from "react-i18next";
import ThemesPopUp from "../../Components/UiComponents/ThemesPopUp/ThemesPopUp";
import { useSelector } from "react-redux";
import { RoleContext } from "../..";

const Header = ({ children }) => {
  const { t, i18n, ready } = useTranslation();
  const { collapsed, toggle } = LayoutState();
  const [avtarImg, setAvtarImg] = useState(null);
  const navigate = useNavigate();
  const [roleTitle, setRoleTitle] = useState("");
  const { keycloak } = useKeycloak();
  const { profileData } = useSelector((store) => store.data);
  const { roleName } = useContext(RoleContext);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  useEffect(() => {
    if (keycloak && keycloak.tokenParsed) {
      getUserProfileImage(keycloak.tokenParsed.email);
    }
  }, [keycloak]);

  const getUserProfileImage = async (email) => {
    let headers = {
      "content-type": "application/json",
    };
    try {
      const response = await api.getData(
        urmservice + userImageUpload + "/" + email,
        {},
        headers
      );
      setAvtarImg(
        urmservice + mediaFileLink + "/" + response.data.user_image.id
      );
    } catch (e) {
      setAvtarImg(avatar);
    }
  };

  return (
    <div className="header_container">
    <div className={`top__nav ${collapsed ? " collapsed" : null}`}>
      <div className="top__nav-wrapper">
        <div className="top-menu cursor-pointer" onClick={() => toggle(!collapsed)}>
          <span>
            {!collapsed ? (
              <BurgerIcon width="1.6em" height="1.6em" />
            ) : (
              <BurgerIconClosed width="1.6em" height="1.6em" />
            )}
            <span className="ml-2">{t("menu")}</span>
          </span>
        </div>
        <div className="center-box">{children}</div>
      
        <div className="top__nav-right">
        {/* <select
            className="lngselect"
            onChange={(e) => changeLanguage(e.target.value)}
            value={i18n.language}
          >
            <option value="en">English</option>
           
          </select> */}
           <ThemesPopUp />
          <div className="top__nav-right">
            {profileData &&
              <div className="user-details">
                <span className="user-name">{(keycloak.idTokenParsed && keycloak.idTokenParsed.name) ? keycloak.idTokenParsed.name : "NA"}</span>
                <span className="user-role">{roleName}</span>
              </div>
            }
            <div className="profile cursor-pointer" onClick={() => {
              navigate("/userProfile");
            }}>
              <img src={avtarImg} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Header;
