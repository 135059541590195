import React, { useEffect } from "react";
import DMSHeader from "../../DMSHeader/DMSHeader";
import { useTranslation } from "react-i18next";
import PartiallyAffectedReportTable from "./PartiallyAffectedReportTable/PartiallyAffectedReportTable";

const PartiallyAffectedReport = () => {
  return (
    <div>
      <div className="widget-container">
        <DMSHeader title="Partially Affected Report - Bihar State" />
      </div>
      <div className="widget-container p-1">
        <PartiallyAffectedReportTable />
      </div>
    </div>
  );
};

export default PartiallyAffectedReport;
