import React from 'react'
import { downloadFile, getDRAttributeValue } from "../../../../../../Components/UiComponents/utils";
import { useTranslation } from 'react-i18next';

const AffectedPopulation = (props) => {
  let downloadAttachmentURL = process.env.REACT_APP_APP_SERVER_ADDRESS + "/" + process.env.REACT_APP_CORE_CONTEXT_PATH_NAME + "/download/download_attachments/?directoryPath=[DIRECTORY_PATH]&fileName=Fishing_Boats.zip";
  const { t, i18n } = useTranslation();
  return (
    <div className="affectedtable-container">
      <div className="affectedtable-header-style">{t('affectedpopulation')}</div>
      <div className="m-2">
        {/* <div className="row mainsection"> */}
        <div className="row section1 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('totalpopulation')}
            </div>
            <div>{getDRAttributeValue(props.data, 'PAR_TOTAL_POPULATION')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('populationaffectedystrdy')}
            </div>
            <div>
              Humans : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_TILL_YSTRDAY_HUMANS')} , <span>Animals : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_TILL_YSTRDAY_ANIMALS')}</span>
            </div>
          </div>

          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('populationaffectedtody')}
            </div>
            <div>
              Humans : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_NWLY_TODAY_HUMANS')} , <span>Animals : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_NWLY_TODAY_ANIMALS')}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('totalpopulationaffected')}
            </div>
            <div>
              Humans : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_SO_FAR_HUMANS')} , <span>Animals : {getDRAttributeValue(props.data, 'PAR_POPULATION_AFFECTED_SO_FAR_ANIMALS')}</span>
            </div>
          </div>

          <div className="col-md-3 mt-3">
            <div className="affected-population-labelstyle">
              {t('peopleevacuated')}
            </div>
            <div>{getDRAttributeValue(props.data, 'PAR_TOTAL_PEOPLE_EVACUTED')}</div>
          </div>
          <div className="col-md-3 mt-3">
            <div className="affected-population-labelstyle">{t('noofdeath')}</div>
            <div>
              Humans : {getDRAttributeValue(props.data, 'PAR_TOTAL_HUMANS_DEATH')} , <span>Animals : {getDRAttributeValue(props.data, 'PAR_TOTAL_ANIMAL_DEATH')}</span>
            </div>
          </div>
        </div>

        <div className="row section2 px-3 mt-3 mb-4">
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('noofvillagesubmerged')}
            </div>
            <div>{getDRAttributeValue(props.data, 'PAR_VILLAGES_SUBMERGED')}</div>
          </div>
          <div className="col-md-3">
            <div className="affected-population-labelstyle">
              {t('attachments')}
            </div>
            <div>
              {
                getDRAttributeValue(props.data, 'attachment_path') != null ? (
                  <div className="affected-population-labelstyle" role="button"
                    onClick={() => downloadFile(downloadAttachmentURL.replace("[DIRECTORY_PATH]", getDRAttributeValue(props.data, 'attachment_path')), 'Affected_Population_Report_' + new Date().getTime() + '.zip')}
                  >Download</div>
                ) : (
                  "Not Available"
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default AffectedPopulation