import React, { useEffect } from 'react'


const GisManagement = () => {
    useEffect(()=>{
        window.location.href = process.env.REACT_APP_GIS_SERVICE+"/gismanagement"
    })
  return (
    <div>Loading...</div>
  )
}

export default GisManagement