import { createContext, useContext, useState } from "react";

const Context = createContext();

const AppContext = ({ children }) => {
  const [playBackLocation, setPlayBackLocation] = useState([]);
  

  return (
    <Context.Provider value={{ playBackLocation, setPlayBackLocation }}>{children}</Context.Provider>
  );
};

export const PlayBackLocationState = () => {
  return useContext(Context);
};

export default AppContext;
