import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../Components/UiComponents/Table/Table";
import {
  Editicon,
  Deleteicon,
  Claim,
  Complete,
} from "../../../../Components/IconComponents";
import { facilityTableData } from "../../../../JsonData/CreateFacilityConfig";
import ModalWithComment from "../../../../Components/UiComponents/ModalWithComment/ModalWithComment";
import {
  getData,
  postDataWithBody,
  putDataWithBody,
} from "../../../../Services/Services";
import {
  getMyTaskListURL,
  deleteFacilityURL,
  claimMytask,
} from "../../../../Services/EndPoints";
import Moment from "react-moment";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { useKeycloak } from "@react-keycloak/web";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from 'react-i18next';

const MyTaskList = (props) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [selectedSopInstanceId, setSelectedSopInstanceId] = useState(null);
  const [taskData, setTaskData] = useState(null);
  const [showPop, setShowPop] = React.useState(false);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();
  const { keycloak } = useKeycloak();
  useEffect(() => {
    setTimeout(function () {
      getMyTaskList();
    }, 500);
  }, []);
  let taskServiceData = [];
  const getMyTaskList = async () => {
    var myTaskList = await postDataWithBody(
      getMyTaskListURL,
      { user_name: getLoggedInuserName() },
      {}
    );
    taskServiceData = myTaskList;
    setRowCount(myTaskList.length);
    setData(myTaskList);
  };

  const getLoggedInuserName = () => {
    return keycloak.idTokenParsed.email;
  };
  const addNewHandler = () => {
    navigate("/facility-mgmt/create-facility");
    // localStorage.removeItem("DriverRegForm");
  };
  const showCompleteTaskPopup = (elem) => {
    setTaskData(elem);
    setShowPop(!showPop);
  };

  function refreshTaskTable() {
    getMyTaskList();
    setShowPop(false);
  }
  const columns = React.useMemo(
    () => [
      {
        Header: t('soprelatedtasktableheaders.taskname'),
        accessor: "task_name",
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.description'),
        accessor: "description",
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.actionmessage'),
        accessor: "action_message",
        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            // if (props.value.length > 15) {
            //   descriptionText = props.value.substring(0, 15) + "...";
            // }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.tasktype'),
        accessor: "task_type",
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.createdby'),
        accessor: "created_by",
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.createdon'),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            const gmtOffsetValue = new Date(props.value);
            // gmtOffsetValue.setMinutes(gmtOffsetValue.getMinutes() + 330); // Add 5:30 offset (330 minutes)
            return <Moment format="DD/MM/YYYY, hh:mm a">{gmtOffsetValue}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t('soprelatedtasktableheaders.updatedon'),
        accessor: "updated_on",
        Cell: (props) => {
          if (props.value) {
            const gmtOffsetValue = new Date(props.value);
            // gmtOffsetValue.setMinutes(gmtOffsetValue.getMinutes() + 330); // Add 5:30 offset (330 minutes)
            return <Moment format="DD/MM/YYYY, hh:mm a">{gmtOffsetValue}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },      
      {
        Header: t('soprelatedtasktableheaders.status'),
        accessor: "status",
        disableSortBy: true,
        Cell: (props) => {
          if (props.row.original.task_status_code == "TASK_EXE_STATUS_STARTED") {
            return (
              <div className="inactivestatus">
                <div id='inactive_dot'></div>
                <div>{t("strated")}</div>
              </div>
            )
          }
          else if (props.row.original.task_status_code == "TASK_EXE_STATUS_CLAIMED") {
            return (
              <div className="inprogressstatus">
                <div id='progress_dot'></div>
                <div>{t("claimed")}</div>
              </div>
            )
          }
          else if (props.row.original.task_status_code == "TASK_EXE_STATUS_COMPLETED") {
            return (
              <div className="activestatus">
                <div id='active_dot'></div>
                <div>{t("completed")}</div>
              </div>
            )
          }


        },
      },
      {
        Header: t('soprelatedtasktableheaders.action'),
        accessor: "actions",
        disableSortBy: true,
        Cell: (props) => {
          const [isClaimConfirmed, setIsClaimConfirmed] = useState(false);
          const confirmClaim = () => {
            setIsClaimConfirmed(!isClaimConfirmed);
          };
          const confirmTaskById = async (id) => {
            let task_id = null;
            taskServiceData.forEach(function (dataElem) {
              if (dataElem.id == id) {
                task_id = dataElem.task_id;
              }
            });
            debugger;
            let input = {
              task_id: task_id,
              sop_instance_id: id,
              user_name: getLoggedInuserName(),
            };
            var res = await postDataWithBody(claimMytask, input, {});
            if (res.status == "success") {
              getMyTaskList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsClaimConfirmed(false);
          };

          if (
            props.row.original.task_status_code == "TASK_EXE_STATUS_STARTED"
          ) {
            return (
              <span role="button" title={t('claimtask')} onClick={confirmClaim}>
                <Claim />

                {isClaimConfirmed && (
                  <Confirmpopup
                    className="DeltePopup"
                    show={isClaimConfirmed}
                    title={t('claimtask')}
                    message={t('taskpopupmsg')}
                    handleToggle={confirmClaim}
                    row={props.row}
                    name={props.row.original.task_name}
                    deleteRow={confirmTaskById}
                    cancel={t('cancelbutton')}
                    action={t('confirm')}
                  />
                )}
              </span>
            );
          } else if (
            props.row.original.task_status_code == "TASK_EXE_STATUS_CLAIMED"
          ) {
            return (
              <span
                role="button"
                title="Complete Task"
                onClick={() => showCompleteTaskPopup(props.row.original)}
              >
                <Complete />
              </span>
            );
          } else {
            return "";
          }
        },
      },
    ],
    [t]
  );

  return (
    <div>
      {showPop && (
        <ModalWithComment
          titleValue={t('addcomments')}
          showSuccessMsg={refreshTaskTable}
          taskData={taskData}
          show={showPop}
          buttonLabel={t('popup.save')}
          handleToggle={showCompleteTaskPopup}
          {...props}
        />
      )}

      {facilityTableData && (
        <Table
          data={data}
          title=""
          columns={columns}
          numberOfRows={7}
          showExport={false}
          showReset={false}
          onExpand={false}
          showSearch={true}
          isHeaderFixed={true}
          rowCount={rowCount}
          headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
          tableStyle={{
            border: "1px solid  #f0f0f0",
            padding: "2px",
            borderRadius: "12px",
          }}
        />
      )}
    </div>
  );
};

export default MyTaskList;
