import React, { useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { GridStack } from "gridstack";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../../DMSHeader/DMSHeader";
import SOPTaskTable from "./SOPTaskTable";
import SOPResourceTable from "./SOPResourceTable";
import SOPInfo from "./SOPInfo";
import { useTranslation } from 'react-i18next';

const ViewSOP = (props) => {
  const { t } = useTranslation();

    useEffect(() => {
        GridStack.init();
    }, []);

    const { state } = useLocation();
    let sop_instance_data = state?.data;
    const layoutData = [
      {
        x: 0,
        y: 0,
        width: 12,
        height: 1,
        id: 1,
        component: (
          <DMSHeader
            title={t('viewsopinfo')}
            showmenu={true}
            showIconsForback={true}
            showImportDropDownLabel={false}
          />
        ),
        haveOverflowVisible: true,
      },
      {
        x: 0,
        y: 2,
        width: 12,
        height: 5,
        id: 1,
        component: <SOPInfo sopInstanceData={sop_instance_data} />,
        haveOverflowVisible: true,
      },
      {
        x: 0,
        y: 5,
        width: 12,
        height: 12,
        id: 2,
        component: <SOPTaskTable sopInstanceData={sop_instance_data} />,
      },
      {
        x: 0,
        y: 17,
        width: 12,
        height: 10,
        id: 2,
        component: <SOPResourceTable sopInstanceData={sop_instance_data} />,
      },
    ];

    return (
        <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
            <GridItem layoutData={layoutData} />
        </div>
    );
};

export default ViewSOP;
