import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../DMSHeader/DMSHeader";
import UserTaskTable from "./UserTaskTable/UserTaskTable";
import { useTranslation } from 'react-i18next';

const UserTaskManagement = (props) => {
  const { t } = useTranslation();

    useEffect(() => {
    GridStack.init();
  }, []);
  const location = useLocation();
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('task_management_header')}
          showdownload={true}
          fileName="TaskManagement.csv"
          showmenu={true}
          showImportDropDownLabel={false}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 11,
      id: 2,
      component: <UserTaskTable />,
      // component: <CreateUserTaskForm />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default UserTaskManagement;
