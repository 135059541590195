import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import DynamicTabs from "../../Common/Tabs/Tabs";
import { yupResolver } from "@hookform/resolvers/yup";
import "./CreateContact.css";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import { toast, ToastContainer } from "react-toastify";
import useFormPersist from "react-hook-form-persist";
import Loader from "react-spinner-loader";
import {
  agencyListURL,
  findContactsById,
  createJobTitle,
  regionListURL,
  skillListURL,
  getJobTitle,
  agencyTypeListURL,
  hierarchylevelListURL,
  getContacts,
} from "../../../../Services/EndPoints";
import {
  contactformschema,
  ContactFormConfig,
} from "../../../../JsonData/ContactConfig";
import {
  postDataWithBody,
  getData,
  putDataWithBody,
} from "../../../../Services/Services";
import ModalResourceType from "../../../../Components/UiComponents/ModalResourceType/ModalResourceType";
import { createContact, updateContact } from "../../../../Services/EndPoints";
import { useTranslation } from "react-i18next";
const ContactRegistrationForm = (props) => {
  const { t } = useTranslation();
  const [disableinput, setDisableinput] = useState(false);
  let contactAgencyMapList = [];
  const [contactConfig, setContactConfig] = useState(ContactFormConfig);
  const [agencyData, setAgencyData] = useState([]);
  const [agencyConfig, setAgencyConfig] = useState([]);
  const [contactDataById, setContactDataById] = useState({});
  const [regionDataFrUpsert, setRegionDataFrUpsert] = useState([]);
  const [regionData, setRegionData] = useState([]);
  const [regionChanged, isRegionChanged] = useState(false);
  const [jobTitleList, setJobTitleList] = useState({});
  const [jobPopUp, setJobPopUp] = useState(false);
  const [selectedConfigId, setSelectedConfigId] = useState(null);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const { state } = useLocation();
  let dataFrEdit = state?.data;
  const [contactExists, setContactExists] = useState(false);
  // let contactExists;
  useEffect(() => {
    getRegionList();
    //getAgencyList();
    //getRegionList();
    //getAllJobTitleList();
  }, []);

  const getSkillData = async () => {
    var skillListArr = await getData(skillListURL);
    skillListArr.forEach(function (skill) {
      skill.label = skill.value;
      skill.value = skill.id;
    });
    ContactFormConfig[0].fields[9]["options"] = skillListArr;
    setContactConfig(ContactFormConfig);
    setLoader(false);
  };

  
  const getRegionList = async () => {
    setLoader(true);
    var regionList = await getData(regionListURL);
    setRegionData(regionList);
    let hierregionData = flatToHierarchy(regionList, "parent_region_id");
    ContactFormConfig[0].fields[5]["options"] = hierregionData[0];
    setContactConfig(ContactFormConfig);
    getAllJobTitleList();
  };
  const getAllJobTitleList = async () => {
    var jbTitlList = await getData(getJobTitle);
    let jbTitlTemp = [];
    jbTitlList.forEach((obj, index) => {
      jbTitlTemp.push({ label: obj.value, value: obj.id });
    });
    ContactFormConfig[0].fields[6]["options"] = jbTitlTemp;
    setJobTitleList(jbTitlTemp);
    getAgencyList();
  };
  const flatToHierarchy = (flat, parent_key_name) => {
    var roots = []; // things without parent
    var all = {};
    flat.forEach(function (item) {
      all[item.id] = item;
    });
    Object.keys(all).forEach(function (id) {
      var item = all[id];
      item["id"] = item.id + "";
      item["label"] = item.name;
      item["value"] = item.id + "";
      if (item[parent_key_name] == 0) {
        roots.push(item);
      } else if (item[parent_key_name] in all) {
        var p = all[item[parent_key_name]];
        if (!("children" in p)) {
          p.children = [];
        }
        p.children.push(item);
      }
    });
    return roots;
  };

  const createJobTiltle = async (jobTitle) => {
    let input = { job_title: jobTitle, status: true };
    var resourceData = await postDataWithBody(createJobTitle, input, {});
    let jbTitlTemp = [
      ...jobTitleList,
      { label: jobTitle, value: resourceData.id },
    ];
    ContactFormConfig[0].fields[6]["options"] = jbTitlTemp;
    setContactConfig(ContactFormConfig);
    setJobTitleList(jbTitlTemp);
  };

  const populateContactFormFrEdit = async (formdata, agencyList) => {
    setLoader(true);
    let contactdata = await postDataWithBody(
      findContactsById,
      [formdata?.id],
      {}
    );
   
    contactdata = contactdata[0];
    formdata["contact_type"] = contactdata["contact_type"];
    formdata["quantity"] = contactdata["quantity"];
    reset(formdata);
    setValue("contact_type", contactdata.contact_type);
    setContactDataById(contactdata);
    let agencyName = null,
      jobTitle = null;
    agencyList.forEach((agency, index) => {
      if (agency.value == contactdata.agency_id) {
        agencyName = agency.label;
      }
      setLoader(false);
    });
    let jobList = ContactFormConfig[0].fields[6]["options"];
    jobList.forEach((job, index) => {
      if (job.value == contactdata.job_title_id) {
        jobTitle = job.label;
      }
    });
    setValue("agency", [{ label: agencyName, value: contactdata.agency_id }]);
    let skillArr = [];
    let skillIdNameMap = {};
    let skillListDropArr = ContactFormConfig[0].fields[9]["options"];
    skillListDropArr.forEach((skill, index) => {
      skillIdNameMap[skill.value] = skill.label;
    });
    contactdata.contact_skill_map.forEach((skill, index) => {
      skillArr.push({
        label: skillIdNameMap[skill.skill_id],
        value: skill.skill_id,
      });
    });
    setValue("skills", skillArr);

    let slectedRegions = [];
    contactdata.contact_region_map.forEach((contact, index) => {
      slectedRegions.push(contact.region_id + "");
    });
    let regionDataTemp = ContactFormConfig[0].fields[5].options;
    let regionDataWthSlctdValus = addSelectedFlag(
      [regionDataTemp],
      slectedRegions
    );
    setRegionData(regionDataWthSlctdValus);
    ContactFormConfig[0].fields[5]["options"] = regionDataWthSlctdValus[0];
    setContactConfig(ContactFormConfig);
    if (formdata.quantity === "Individual") {
      document.getElementsByName("quantity")[0].disabled = true;
      document.getElementsByName("quantity")[0].value = "";
    } else if (formdata.quantity === "Team") {
      document.getElementsByName("quantity")[0].disabled = false;
    } else {
      document.getElementsByName("quantity")[0].disabled = true;
      document.getElementsByName("quantity")[0].value = "";
    }
  };

  function addSelectedFlag(data, selectedValues) {
    return data.map(({ type, children = [], ...rest }) => {
      const o = { ...rest, type };
      if (selectedValues.indexOf(o.id) > -1) {
        o.checked = true;
      }
      if (children.length)
        o.children = addSelectedFlag(children, selectedValues);
      return o;
    });
  }
  const onHierDropdownChange = (currentNode, selectedNodes) => {
    isRegionChanged(true);
    let regionDataSlected = [];
    selectedNodes.forEach((region) => {
      regionDataSlected.push({ region_id: region.id });
    });
    setRegionDataFrUpsert(regionDataSlected);
  };

  const getAgencyList = async () => {
    var agencyList = await getData(agencyListURL);
    for (let agency in agencyList) {
      agencyList[agency]["label"] = agencyList[agency]["name"];
      agencyList[agency]["value"] = agencyList[agency]["id"];
    }
    //let hierData = flatToHierarchy(agencyList,"parent_agency_id");
    ContactFormConfig[0].fields[4]["options"] = agencyList;
    setContactConfig(ContactFormConfig);
    setAgencyData(agencyList);
    await getSkillData();
    document.getElementsByName("quantity")[0].disabled = true;
    if (dataFrEdit) {
      populateContactFormFrEdit(dataFrEdit, agencyList);
    }
  };

  const jobHandler = (props) => {
    setJobPopUp(!jobPopUp);
  };

  const handleAddJobTitle = async (jobTitle) => {
    // Call createJobTiltle with the entered job title
    await createJobTiltle(jobTitle);
    // Close the modal
    setJobPopUp(false);
  };

  function showSuccessMsg(configId) {
    //refreshConfigMasterTable(configId);
    toast.success("Job title added successfully.", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 2000, // Close after 2 seconds
    });
  }
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    trigger,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(contactformschema[activeTab]),
    mode: "all",
  });
  const contact_type = useWatch({
    name: "contact_type",
    control,
  });

  useEffect(() => {
    let contactType = getValues("contact_type");
    if (contactType) {
      if (contactType === "Individual") {
        document.getElementsByName("quantity")[0].disabled = true;
        document.getElementsByName("quantity")[0].value = "";
      } else {
        document.getElementsByName("quantity")[0].disabled = false;
      }
    }
  }, [contact_type]);
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        {agencyData.length > 0 &&
          regionData.length > 0 &&
          jobTitleList.length > 0 && (
            <FormHandler
              register={register}
              errors={errors}
              fields={fields}
              onChange={onHierDropdownChange}
              control={control}
              columns={2}
              getValues={getValues}
            />
          )}
      </div>
    );
  };
  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };

  const submitHandler = async (data, e) => {
    const phone1 = data.phone1;
    setLoader(true)

    try {
      // Fetch contact list to check if phone1 exists
      const response = await getData(getContacts);

      const contactExists = response.some(
        (contact) =>
          contact.phone1 === phone1 &&
          (!dataFrEdit || contact.id !== dataFrEdit.id)
      );
      if (contactExists) {
        toast.error("Contact number already exists", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });

        return;
      }

      e.preventDefault();
      data["status"] = true;
      data["type"] = 1;
      setLoader(true);
      data["contact_region_map"] = regionDataFrUpsert;

      // Prepare contact skills map
      if (data?.skills) {
        const contact_skill_map = data.skills.map((skill) => ({
          skill_id: skill.id,
        }));
        data["contact_skill_map"] = contact_skill_map;
      }

      let res = null;
      if (dataFrEdit) {
        // Edit existing contact logic
        delete data["created_on"];
        delete data["updated_on"];
        data["id"] = dataFrEdit.id;

        // Update region data if it has changed
        if (!regionChanged) {
          data["contact_region_map"] = contactDataById.contact_region_map;
        } else {
          const contactRegionidMap = {};
          contactDataById?.contact_region_map?.forEach((contact) => {
            contactRegionidMap[contact.region_id] = contact.id;
          });

          const regionDataFrUpdate = regionDataFrUpsert.map((region) => ({
            region_id: region.region_id,
            id: contactRegionidMap[region.region_id] || null,
            contact_id: dataFrEdit.id,
          }));
          data["contact_region_map"] = regionDataFrUpdate;
        }

        // Update contact
        res = await putDataWithBody(updateContact, data, {});
      } else {
        // Create new contact
        res = await postDataWithBody(createContact, data, {});
      }

      // Handle response
      if (res.status === "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setLoader(false);
        navigate("../view-contact");
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });
        setLoader(false);
      }
    } catch (error) {
      // Error handling for contact fetching or submission
      console.error("Error fetching contact list or submitting:", error);
    }
  };

  useEffect(() => {
    getAgencyTypeList();
    getHierarchyLevelList();
  }, []);

  async function getAgencyTypeList() {
    var agencyList = await getData(agencyTypeListURL);

    for (let agency in agencyList) {
      agencyList[agency]["label"] = agencyList[agency]["value"];
      agencyList[agency]["value"] = agencyList[agency]["id"];
    }
    ContactFormConfig[0].fields[1]["options"] = agencyList;
    setAgencyConfig(ContactFormConfig);
  }

  async function getHierarchyLevelList() {
    var hierarchyList = await getData(hierarchylevelListURL);

    for (let hierarchy in hierarchyList) {
      hierarchyList[hierarchy]["label"] = hierarchyList[hierarchy]["name"];
      hierarchyList[hierarchy]["value"] = hierarchyList[hierarchy]["id"];
    }
    ContactFormConfig[0].fields[2]["options"] = hierarchyList;
    setAgencyConfig(ContactFormConfig);
  }

  const saveTabDataHandler = async (e) => {
    const isStepValid = await trigger();
    if (isStepValid) setActiveTab((prevId) => prevId + 1);
  };

  const previousHandler = () => {
    if (activeTab > 0) {
      setActiveTab((prevId) => prevId - 1);
    }
  };
  const tabsData = generateTabsData(ContactFormConfig);
  return (
    <div className="mt-4">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateContactRegForm">
            <div>
              {tabsData?.map((tabs) => (
                <>
                  {tabs.id === activeTab && (
                    <>
                      <div className="registration__form--heading">
                        {tabs.heading}
                      </div>
                      <div>{tabs.component}</div>
                      {/* <div>
                        <span
                          // onClick={createJobTiltle}
                          id="add_new_titlecontact"
                          onClick={jobHandler}
                        >
                          {t("addnew")}
                        </span>
                      </div> */}
                    </>
                  )}
                </>
              ))}
            </div>
            <div className="d-flex justify-content-center">
              {activeTab > 0 && (
                <Button
                  variant="secondary"
                  className="previousbutton-style mx-2"
                  onClick={previousHandler}>
                  {t("previous")}
                </Button>
              )}
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" className="mx-2" variant="primary" disabled={loader}>
                  {/* Save & Submit */}
                  {t("saveAndSubmit")}
                </Button>
              )}
              {tabsData.length - 1 !== activeTab && (
                <Button
                  variant="primary"
                  className="mx-2"
                  onClick={saveTabDataHandler}>
                  {t("save&next")}
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>

      {jobPopUp && (
        <ModalResourceType
          titleValue={t("popup.tittle")}
          showSuccessMsg={showSuccessMsg}
          selectedConfigId={selectedConfigId}
          onAddJobTitle={handleAddJobTitle}
          show={jobPopUp}
          buttonLabel={t("popup.button")}
          handleToggle={jobHandler}
          popupLabel={t("popup.label")}
          {...props}
        />
      )}
    </div>
  );
};
export default ContactRegistrationForm;
