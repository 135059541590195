import React, { useEffect, useState } from "react";
import "./PersonalDetails.css";
import { FormConfig, schema } from "./PersonalDetailsConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Delayed from "../../../Components/UiComponents/Delayed/Delayed";
import { useDispatch } from "react-redux";
import FormHandler from "../../DMS/Common/Form/FormHandler/FormHandler";
import Confirmpopup from "../../DMS/Common/ConfirmPopup/Confirmpopup";
import lmsToast from "../../DMS/Common/CustomToastifier/CustomToastifier";
import DetailsHandler from "../../DMS/Common/DetailsHandler/DetailsHandler";
import { updateUserData } from "../../../Services/EndPoints";
import {
  fetchProfileData
} from "../../../CentralStore/ExportLMSTable/dataSlice";
import { useTranslation } from 'react-i18next';
import ChangePasswordComp from "./ChangePasswordComp";
import { putDataFormDataWithNoidPf } from "../../../Services/ProfileServices";
import DmsButton from "../../DMS/Common/DmsButton/DmsButton";


export default function PersonalDetails({ userData = [] }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema), mode: "all" });
  const [isEdit, setIsEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    console.log(userData, "userData");
    setValue("first_name", userData?.first_name);
    setValue("middle_name", userData?.middle_name);
    setValue("last_name", userData?.last_name);
    setValue("email", userData?.email);
    setValue("phone_number", userData?.phone_number);
    setValue("user_name", userData?.user_name);
    setValue("theme", userData?.theme);
    setValue("locale", userData?.locale);

  }, [userData]);
  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const togglePopup = () => {
    setOpenPopup(!openPopup);
  };

  const updateFormWithData = (res) => {
    if (res && res.data) {
      const updatedUserData = res.data; // Adjust according to your API response structure
      // Now update the form fields with the updated user data
      // For example, if you have input fields with IDs or names corresponding to user data fields:
      document.getElementById("first_name").value = updatedUserData.first_name;
      document.getElementById("email").value = updatedUserData.email;
    }
  };
  const appendFormData = (data, formData) => {
    // Iterate over the data object and append each field to the formData object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
  };

  const GenerateForm = (fields, register, errors) => {
    return (
      <Delayed>
        <FormHandler
          register={register}
          errors={errors}
          fields={fields}
          columns={2}
        />
      </Delayed>
    );
  };

  const submitHandler = (data) => {
    const formData = new FormData();
    const user_profile = {
      id: userData.id,
      email_id: data.email,
      user_name: data.user_name,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
      theme: data.theme,
      locale: data.locale
    };
    console.log("data", data);
    formData.append("user_profile", JSON.stringify(user_profile));
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    // if (userData?.id) {
    // appendFormData(data, formData);
    // const sample = {
    //   user_profile: formData,
    // };
    putDataFormDataWithNoidPf(updateUserData, formData)
      .then((response) => {
        setTimeout(() => {
          lmsToast({ toasterType: "success", operationType: "edit" });
        }, 300);
        dispatch(fetchProfileData());
        updateFormWithData(response);
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  };
  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        {isEdit ? (
          <div className="mt-3 profile_form">
            <div className="profile-form_label active__title">
              {t("personal_detail")}
            </div>
            <React.Fragment>
              <div>{GenerateForm(FormConfig(), register, errors)}</div>
            </React.Fragment>
          </div>
        ) : (
          <DetailsHandler
            title={t('personal_detail')}
            columns={2}
            config={FormConfig()}
            details={userData}
          />
        )}
        <div className="profile_button">
          <DmsButton
            label={isEdit ? t('save_profile') : t('edit_profile')}
            type={isEdit ? "button" : "submit"}
            onClick={() => {
              toggleEdit();
            }}
          />
          {/* <DmsButton
            label={t('change_password')}
            variant="warning"
            onClick={togglePopup}
          /> */}
        </div>

        {openPopup && (
          <Confirmpopup
            title={t('change_password')}
            show={openPopup}
            handleToggle={togglePopup}
            messageComp={<ChangePasswordComp togglePopup={togglePopup} />}
          />
        )}
      </form>
    </>
  );
}