export const misStateReportConfig = {
  colKey1: "name",
  colKey2: "",
  colKey3: "",
  colKey4: "gp_affected",
  colKey5: "ra_vil_affected",
  colKey6: "ua_np_affected",
  colKey7: "ua_mc_affected",
  colKey8: "ua_tnp_affected",
  colKey9: "ua_nw_affected",
  colKey10: "ua_total",
  colKey11: "",
  colKey12: "",
  colKey13: "",
  colKey14: "pa_ry",
  colKey15: "pa_uy",
  colKey16: "pa_ty",
  colKey17: "",
  colKey18: "",
  colKey19: "",
  colKey20: "fa_rt",
  colKey21: "fa_ut",
  colKey22: "fa_tt",
  colKey23: "",  
  colKey24: "",
  colKey25: "",
  colKey26: "",
  colKey27: "",
  colKey28: "",
  colKey29: "",
  colKey30: "",
  colKey31: "",
  colKey32: "",
  colKey33: "",
  colKey34: "",
  colKey35: "aa_r",  
  colKey36: "aa_u",
  colKey37: "aa_tot",
  colKey38: "river_list",
  colKey39: "",
  colKey40: "totl_gp_aftd",
  colKey41: "mb_ca",
  colKey42: "totl_vil_aftd",
  colKey43: "totl_wards_aftd",
  colKey44: "",
  colKey45: "agr_area_aff",
  colKey46: "totl_agr_ar_aftd",
  colKey47: "non_agr_area_aff",
  colKey48: "totl_non_agr_ar_aftd",
  colKey49: "dc_area_aff",
  colKey50: "totl_dmg_crp_ar_aftd",
  colKey51: "est_crops_aff",
  colKey52: "totl_est_crops_aff",
  colKey53: "ktc_cmp",
  colKey54: "totl_ktc_hou",
  colKey55: "ktc_par",
  colKey56: "totl_ktc_par",
  colKey57: "ccr_cmp",
  colKey58: "totl_cct_cmp",
  colKey59: "ccr_par",
  colKey60: "totl_cct_par",
  colKey61: "cta_hut",
  colKey62: "totl_ctg_ht",
  colKey63: "anml_shed",
  colKey64: "totl_anml_shd",
  colKey65: "",
  colKey66: "",
  colKey67: "",
  colKey68: "",
  colKey69: "dmg_bt_cmp",
  colKey70: "totl_dmg_bt_cmp",
  colKey71: "dmg_bt_par",
  colKey72: "totl_dmg_bt_par",
  colKey73: "dmg_nt_cmp",
  colKey74: "totl_dmg_nt_cmp",
  colKey75: "dmg_nt_par",
  colKey76: "totl_dmg_nt_par",
  colKey77: "",
  colKey78: "",
  colKey79: "hu_dt",
  colKey80: "totl_hum_dt",
  colKey81: "anml_dt",
  colKey82: "totl_anml_dt",
  colKey83: "mt_bt",
  colKey84: "gt_bt",
  colKey85: "pt_bt",
  colKey86: "ttl_eva_ystd",
  colKey87: "ttl_eva_td",
  colKey88: "ttl_eva_nw",
  colKey89: "ttl_cmp_op",
  colKey90: "ttl_cmp_cl",
  colKey91: "ttl_cmp_op_td",
  colKey92: "ttl_rgt_tl_ystd",
  colKey93: "nw_ppl_rgt_td",
  colKey94: "ttl_ppl_rgt_so_fr",
  colKey95: "ttl_ppl_bck_fr_cmp",
  colKey96: "ppl_rmng_cmp",
  colKey97: "ttl_ck_op",
  colKey98: "ttl_ck_cl",
  colKey99: "ttl_ck_op_td",
  colKey100: "num_ppl_etng_lun_td",
  colKey101: "num_ppl_etng_dnr_tn",
  colKey102: "",
  colKey103: "ttl_num_ml_prvd_tld",
  colKey104: "num_hlt_cen_run_dly",
  colKey105: "num_hlt_cen_run_tl_nw",
  colKey106: "num_ppl_trtd_dly",
  colKey107: "num_ppl_trtd_tl_nw",
  colKey108: "num_hlg_dist_dly",
  colKey109: "num_hlg_dist_tl_nw",
  colKey110: "amt_blc_pw_sprk_dly",
  colKey111: "amt_blc_pw_sprk_tl_nw",
  colKey112: "num_anml_cmp_dly",
  colKey113: "num_anml_cmp_tl_nw",
  colKey114: "num_anml_trtd_dly",
  colKey115: "num_anml_trtd_tl_nw",
  colKey116: "amt_gr_rcmd",
  colKey117: "totl_gr_rcmd",
  colKey118: "num_dry_rtn",
  colKey119: "totl_dry_rtn_pck",
  colKey120: "num_fd_pck",
  colKey121: "totl_fd_pck",
  colKey122: "plt_sht_dist",
  colKey123: "totl_poly_sht_dist",
  colKey124: "otr_ingr",
};
