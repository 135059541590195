import React, { useEffect, useState } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import DMSHeader from "../../DMSHeader/DMSHeader";
import DailyFloodReportStatusForm from "./DailyFloodReportStatusForm";
import moment from "moment";
//import DMSHeader from "../../DMSHeader/DMSHeader";

const DailyFloodReportStatus = () => {
  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );
  useEffect(() => {
    GridStack.init();
  }, []);
  const floodReportStatusfile = `DailyFloodReportStatus_${selectedDate}`;
  console.log("filename in reprotstatus=====", floodReportStatusfile);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title="Form 1 - Reporting Status"
          fileName={`DailyFloodReportStatus_${selectedDate}`}
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 10,
      id: 2,
      component: (
        <DailyFloodReportStatusForm
          setSelectedDate={setSelectedDate}
          selectedDate={selectedDate}
        />
      ),
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default DailyFloodReportStatus;
