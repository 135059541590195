import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const defaultConfig = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

const msgs = {
  create: "Successfully Created!",
  post: "Successfully Posted!",
  edit: "Successfully Updated!",
  release: "Vehicle Released Successfully",
  nullTrip: "Cannot allocate since trip is null",
  delete: "Successfully Deleted!",
  swr: "Something went wrong!",
  createFail: "Failed to Create!",
  editFail: "Faild to Edit",
  deleteFail: "Failed to Delete",
  blackList: "Successfully Blacklisted!",
  whiteList: "Successfully Whitelisted!",
};

const lmsToast = ({
  toasterType = "",
  operationType = "",
  customConfig = {},
  msg = "",
}) => {
  if (operationType !== "" || msg !== "") {
    let toaster;
    const toastCofiguration = { ...defaultConfig, ...customConfig };
    const message = msg || msgs[operationType];
    switch (toasterType) {
      case "success":
        toaster = toast.success(message, toastCofiguration);
        break;
      case "info":
        toaster = toast.info(message, toastCofiguration);
        break;
      case "warning":
        toaster = toast.warning(message, toastCofiguration);
        break;
      case "error":
        toaster = toast.error(message, toastCofiguration);
        break;
      default:
        toaster = toast(message, toastCofiguration);
    }
    return toaster;
  } else {
    console.log("Either operationType or msg should not be empty");
  }
};

export default lmsToast;
