import React from "react";
import { NavLink } from "react-router-dom";
import NavItemHeader from "./Sidebar/NavItemHeader";
import { LayoutState } from "../Context/LayoutContext/LayoutContext";

const NavItem = ({ item, index = "" }) => {
  const { collapsed } = LayoutState();
  const { to, icon, label, children } = item;
  if (children) {
    return <NavItemHeader item={item} />;
  }
  return (
    <li className="nav__item" key={index}>
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? `nav__link ${
                !collapsed ? "nav__active" : "nav__active--collapsed"
              }`
            : "nav__link "
        }
      >
        {icon} {collapsed ? null : label}
      </NavLink>
    </li>
  );
};

export default NavItem;
