
const TableRows=({ rowsData, deleteTableRows, handleChange }) =>{
  return rowsData.map((data, index) => {
    const { attributeName, attributeValue} = data;
    return (
      <tr key={index}>
        <td>
          <input
            type="text"
            value={attributeName}
            onChange={(evnt) => handleChange(index, evnt)}
            name="attributeName"
            className="form-control  form__input"
          />
        </td>
        <td>
          <input
            type="text"
            value={attributeValue}
            onChange={(evnt) => handleChange(index, evnt)}
            name="attributeValue"
            className="form-control  form__input"
          />{" "}
        </td>
        
        <td>
          <input type="button"
            className="btn btn-outline-danger"
            onClick={() => deleteTableRows(index)}
            value={"x"}
          />
           
        </td>
      </tr>
    );
  });
}

export default TableRows;
