import React, { useEffect } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../../DMSHeader/DMSHeader";
import CreateMasterResourceRegForm from "./CreateMasterResourceRegForm";
import { useTranslation } from "react-i18next";
//import CreateResourceRegForm from "./CreateResourceRegForm";

const CreateMasterResource = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 0,
      component: (
        <DMSHeader title={t("createmasterresouurce_list_formheader")} showIconsForback={true} />
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 11,
      id: 1,
      component: <CreateMasterResourceRegForm />,
    },
  ];
  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default CreateMasterResource;
