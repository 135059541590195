import { useKeycloak } from "@react-keycloak/web";
const PrivateRoute = ({ children, roles }) => {

  const isAuthorized = (roles) => {
    if(roles){
      return roles.some((r) => {
        const realm = keycloak.hasRealmRole(r);
        const resource = keycloak.hasResourceRole(r);
        return realm || resource;
      });
    }
    else{
      return true;
    }
    
  };
  const { keycloak } = useKeycloak();
  const isLoggedIn = keycloak.authenticated;
  if(isLoggedIn){
    localStorage.setItem("user_locale",keycloak.tokenParsed.locale);
    if(isAuthorized(roles)){
      return children;
    }
    else{
      return "You are not authorized";
    }
  }
  else{
    return null;
  }
  
};

export default PrivateRoute;
