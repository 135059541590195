import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../../../../src/Components/IconComponents/XCircleblack";
import {
  Editicon,
  Deleteicon,
  Viewicon,
} from "../../../../Components/IconComponents";
import { toast, ToastContainer } from "react-toastify";
import Table from "../../../../Components/UiComponents/Table/Table";
import Moment from "react-moment";
import Loader from "react-spinner-loader";
import {
  SOPTableData,
  sopcolumnsForExport,
} from "../../../../JsonData/SOPConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {
  getMasterSOPListURL,
  getActiveMasterSOPList,
  deleteSopById,
} from "../../../../Services/EndPoints";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";

const SOPTable = forwardRef((props, ref) => {
  const { roleName } = useContext(RoleContext);
  const dispatch = useDispatch();
  const qs = require("qs");
  const [SOPData, setSOPData] = useState([]);
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (props.locationInstance && props.locationInstance.msg != "") {
      toast.success("Agency has been created successfully.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
    getSOPMasterList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const addNewHandler = () => {
    navigate("/planning/create-sop");
  };
  const openSOPDataTable = async () => {
    setShow(true);
  };
  const getShortenedText = (strLength, fulltext) => {
    let abbreviatedText = "";
    if (fulltext.length > strLength) {
      abbreviatedText = fulltext.substring(0, 15) + "...";
    } else {
      abbreviatedText = fulltext;
    }
    return <span title={fulltext}>{abbreviatedText}</span>;
  };
  const hideModal = () => {
    setShow(false);
  };
  const getSOPMasterList = async () => {
    setLoader(true);
    var sopMasterList = await getData(getActiveMasterSOPList);
    let sortedList = sopMasterList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });
    setLoader(false);
    setSOPData(sortedList);
    setRowCount(sortedList.length);
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = sopcolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("sopmanagementtableheaders.name"),
        accessor: "name",
        disableSortBy: true,

        Cell: (props) => {
          return (
            <span /*onClick={() => openSOPDataTable(props.value)}*/>
              {props.value}
            </span>
          );
        },
      },

      {
        Header: t("sopmanagementtableheaders.description"),
        accessor: "description",

        Cell: (props) => {
          if (props.value) {
            return getShortenedText(15, props.value);
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("sopmanagementtableheaders.tasklist"),
        accessor: "task_list",

        Cell: (props) => {
          if (props && props.value) {
            if (props.value.length > 0) {
              return getShortenedText(15, props.value);
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("sopmanagementtableheaders.resourcelist"),
        accessor: "resource_list",
        Cell: (props) => {
          if (props && props.value) {
            if (props.value.length > 0) {
              return getShortenedText(15, props.value);
            }
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("sopmanagementtableheaders.createdon"),
        accessor: "created_on",
        disableSortBy: false, // Enable sorting
        sortType: (rowA, rowB, columnId) => {
          const dateA = new Date(rowA.values[columnId]);
          const dateB = new Date(rowB.values[columnId]);
          return dateB - dateA; // Newest first
        },
        Cell: (props) => {
          if (props && props.value) {
            return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
      },      
      {
        Header: t("sopmanagementtableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteSopById, data, {});
            if (res.status == "success") {
              getSOPMasterList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };

          return (
            <div className="usertable__actionsicon">
              <span
                onClick={() =>
                  navigate("/planning/view-sopinfo", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Viewicon value={props.value} className="cursor-pointer me-2" />
              </span>

              <span
                onClick={() =>
                  navigate("/planning/create-sop", {
                    state: {
                      data: props.row.original,
                    },
                  })
                }>
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN && (
                <span onClick={deletePopUp}>
                  <Deleteicon
                    value={props.value}
                    className="cursor-pointer mr-1"
                  />
                </span>
              )}
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  // data={data}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.task}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t,roleName]
  );
  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {SOPTableData && (
          <Table
            data={SOPData}
            columns={columns}
            setSearchData={setSearchData}
            numberOfRows={10}
            addButtonLabel={t("sopaddButtonLabel")}
            addNewHandler={addNewHandler}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount} // Pass rowCount to the Table component
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid # #f0f0f0",
              padding: "2px",
              borderRadius: "12px",
            }}
          />
        )}
      </div>

      <Modal show={show} size="lg" className="table__modal__popup" centered>
        <Modal.Header>
          <Modal.Title>SOP Data</Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={hideModal}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div class="form-group">
              <Table
                data={SOPData}
                columns={columns}
                numberOfRows={5}
                showExport={false}
                showFilter={false}
                showReset={false}
                onExpand={false}
                headerTableHeadStyle={{
                  background: "#18a3a0",
                  color: "#FFFFFF",
                }}
                tableStyle={{ border: "none", padding: "0px" }}
              />
            </div>
            <div class="form-group">
              <Table
                data={SOPData}
                columns={columns}
                numberOfRows={5}
                showExport={false}
                showFilter={false}
                showReset={false}
                onExpand={false}
                headerTableHeadStyle={{
                  background: "#18a3a0",
                  color: "#FFFFFF",
                }}
                tableStyle={{ border: "none", padding: "0px" }}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
});

export default SOPTable;
