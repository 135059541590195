import React, { useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Table.css";
import Sortdownarrow from "../../IconComponents/Sortdownarrow";
import Sortuparrow from "../../IconComponents/Sortuparrow";
import Sortingarrow from "../../IconComponents/Sortingarrow";
import TableHeader from "./TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// Default filter UI for a column
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // undefined to remove the filter entirely
      }}
      placeholder={`Filter...`}
      style={{
        width: "100%",
        padding: "5px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginTop: "5px",
      }}
    />
  );
};

const Table = (props) => {
  const {
    columns,
    data,
    numberOfRows,
    headerTableHeadStyle = {},
    tableStyle = {},
    showRowCount = true,
    rowCount,
    tabChanger = false,
    activeTab,
    setActiveTab,
    searchData,
    setSearchData = () => {},
    enableColumnFilters = false, // New prop to control filter visibility
    disbalePaginationFilter = false,
    disbalePaginationFilter1 =false,
    disbalePaginationFilter2 = false,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    rows,
    state: { pageIndex, globalFilter, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter }, // Add default filter UI
      initialState: { pageIndex: 0, pageSize: numberOfRows },
    },
    useFilters, // Use filters hook
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { t } = useTranslation();

  const translateNumber = (number) => {
    const numberString = number.toString();
    return i18n.t(`numbers.${numberString}`, { defaultValue: numberString });
  };
  let tempRowCount = globalFilter ? rows.length : rowCount || data.length;
  let tempPageIndex = pageIndex;
  let tempPageSize = pageSize;

  if (tempPageIndex === undefined || tempPageIndex === null) {
    tempPageIndex = 0;
  }
  if (tempRowCount === undefined || tempRowCount === null) {
    tempRowCount = 0;
  }
  if (tempPageSize === undefined || tempPageSize === null) {
    tempPageSize = 10;
  }

  const startIndex = tempPageIndex * tempPageSize + 1 || 1;
  const endIndex =
    Math.min((tempPageIndex + 1) * tempPageSize, tempRowCount) || tempRowCount;

  const translatedStartIndex = startIndex;
  const translatedEndIndex = endIndex;
  const translatedRowCount = tempRowCount;
  useEffect(() => {
    setPageSize(numberOfRows);
  }, [numberOfRows]); // Update page size if numberOfRows changes

  useEffect(() => {
    if (globalFilter) {
      const pageData = page?.map((row) => row.original);
      setSearchData(pageData ? pageData : []);
    } else {
      const pageData = rows?.map((row) => row.original);
      setSearchData(pageData ? pageData : []);
    }
  }, [globalFilter, rows]);

  return (
    <div className="tabl_container">
      <TableHeader
        {...props}
        searchValue={globalFilter || ""}
        onSearchHandler={setGlobalFilter}
      />
      {tabChanger && (
        <div className="tabchanger_container">
          <div
            className={`tab ${activeTab === "Affected" ? "active" : ""}`}
            onClick={() => setActiveTab("Affected")}>
            Affected
          </div>
          <div
            className={`tab ${
              activeTab === "Partially Affected" ? "active" : ""
            }`}
            onClick={() => setActiveTab("Partially Affected")}>
            Partially Affected
          </div>
        </div>
      )}

      <div className="iccc__table--container" style={tableStyle}>
        <table {...getTableProps()} className="table iccc--table" id="report_table">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={headerTableHeadStyle}
                    key={index}>
                    {column.render("Header")}
                    {!column.disableSortBy &&
                      (column.isSorted ? (
                        column.isSortedDesc ? (
                          <Sortdownarrow />
                        ) : (
                          <Sortuparrow />
                        )
                      ) : (
                        <Sortingarrow />
                      ))}
                    {enableColumnFilters && column.canFilter && (
                      <div>{column.render("Filter")}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);

                // First condition: check if all affected block counts are 0
                const shouldHighlightRow =
                  row.original.affected === 0 &&
                  row.original.partially_affected === 0 &&
                  row.original.not_affected === 0;

                // Second condition: check if totalBlocks and totalMarkedBlocks are equal
                const areBlocksEqual =
                  row.original.total_block_count ===
                  row.original.totalMarkedBlocks;

                // Determine the row's background color or class
                const rowClassName = shouldHighlightRow
                  ? "highlighted-row"
                  : areBlocksEqual
                  ? "lightgreen-row"
                  : "yellow-row";

                return (
                  <tr {...row.getRowProps()} key={i} className={rowClassName}>
                    {row.cells.map((cell, i) => {
                      return (
                        <td
                          key={i}
                          {...cell.getCellProps()}
                          // className={
                          //   cell.column.centerAlign
                          //     ? "center-align"
                          //     : cell.column.leftAlign
                          //     ? "left-align"
                          //     : ""
                          // }
                          className={"left-align"}
                          
                          >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length} className="no-data-text">
                  No data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between align-items-center">
      {!disbalePaginationFilter2 && 
      <div className="float-start m-2 mt-2 ms-3">
          {showRowCount && (
            <div className="float-start m-2 mt-1 ms-3 pagecount-style">
              {t("showingentriestable.showing")} {translatedStartIndex}{" "}
              {t("showingentriestable.to")} {translatedEndIndex}{" "}
              {t("showingentriestable.of")} {translatedRowCount}{" "}
              {t("showingentriestable.entries")}
            </div>
          )}
          <div>
{/* <span>
Page{''}
<strong>
{pageIndex + 1} of {pageOptions.length}
</strong>{' '} 

</span>*/}
          </div>
            
        </div>
}
        <div className="pagination">
          {!disbalePaginationFilter && (
            <div className="d-flex">
              <span className="pagination_gotopage">
                Go to Page:
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                  className="pagination_gotopage-input"
                />
              </span>
              <span>
                <select
                  className="pagination_select"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}>
                  {[10, 50, 100, 500, 1000, 2000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      show {pageSize}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          )}
           {!disbalePaginationFilter1 && (
            <>
<button
            className="pagebtn mx-2"
            onClick={() => previousPage()}
            disabled={!canPreviousPage}>
            {"<"}
          </button>
          <button className="pagination_page">
            {translateNumber(pageIndex + 1)}{" "}
          </button>
          <button
            className="pagebtn"
            onClick={() => nextPage()}
            disabled={!canNextPage}>
            {">"}
          </button>{" "}
          <span className="right_pageno">
            {t("pagination.page_of", {
              page: translateNumber(pageIndex + 1),
              total: translateNumber(pageOptions.length),
            })}
          </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Table;
