export const validDocumentTypes = [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "docx",
    "doc",
  ];
   
  export const validFileTypes = [
    "application/pdf",
    "application/txt",
    "image/png",
    "image/jpg",
    "image/jpeg",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
   
  export const validImageTypes = ["image/png", "image/jpg", "image/jpeg"];