import React, { useState, useContext } from "react";
import { Modal } from "react-bootstrap";
import "./ColumnFilter.css";
import { Redclosebutton } from "../../../../Components/IconComponents";
import { ThemeContext } from "../../../..";
import { useTranslation } from "react-i18next";
import LMSButton from "../LMSButton/LMSButton";

const ColumnFilter = ({
  allColumns,
  toggleHideColumn,
  showModal,
  handleClose,
  selectedView
}) => {
  const { theme } = useContext(ThemeContext);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");

  const handleModalClose = () => {
    setSearchQuery(""); // Clear the search field
    handleClose(); // Call the parent-provided close handler
  };
  const handleSelectAll = () => {
    allColumns.forEach((column) => {
      // Select all columns except the first two (based on original indices)
      if (allColumns.indexOf(column) > 1 && !column.isVisible) {
        toggleHideColumn(column.id);
      }
    });
  };
  
  const handleDeselectAll = () => {
    allColumns.forEach((column) => {
      // Deselect all columns except the first two (based on original indices)
      if (allColumns.indexOf(column) > 1 && column.isVisible) {
        toggleHideColumn(column.id);
      }
    });
  };
  
  // Filter columns based on the search query
  const filteredColumns = allColumns.filter((column) =>
    t(column.render("Header")).toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      dialogClassName="modal-right"
      size="xl"
      id={theme}
      className="modal__header--ColumnFilter"
      backdrop="static"
      keyboard={false}>
      <Modal.Header className="ColumnFilter__modal">
        <Modal.Title>
          <span className="column__filter--title">Column Filter</span>
        </Modal.Title>
        <Redclosebutton
          onClick={handleModalClose}
          width="1.5em"
          height="1.5em"
          className="cursor-pointer"
          style={{ backgroundColor: "#E0F7FF" }}
        />
      </Modal.Header>
      <Modal.Body className="ColumnFilter__modal--body">
        <div className="filter__modal--body">
          <input
            type="text"
            placeholder="Search columns..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="column-filter-search"
          />

          <div className="d-flex gap-2 mt-2 justify-content-center">
            <LMSButton
              label={" Select All"}
              onClick={() => handleSelectAll(filteredColumns)}
            />
            <LMSButton
              label={" Deselect All"}
              onClick={() => handleDeselectAll(filteredColumns)}
            />
          </div>

          <ul className="checkbox-list">
            {filteredColumns?.map((column) => (
              <li key={column.id}>
                <div className="filter__container mt-2">
                  <label style={{ width: "90%" }}>
                    {t(column.render("Header"))}
                    {allColumns.indexOf(column) <= 1 && (
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    )}
                    <input
                      type="checkbox"
                      checked={column.isVisible}
                      onChange={() =>
                        allColumns.indexOf(column) > 1 && toggleHideColumn(column.id)
                      }
                      disabled={allColumns.indexOf(column) <= 1}
                    />
                    <span className="checkbox__checked"></span>
                  </label>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ColumnFilter;

