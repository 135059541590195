// import React from 'react'

// const AgencyForm = () => {
//   return (
//     <div>AgencyForm</div>
//   )
// }

// export default AgencyForm

import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import DynamicTabs from "../../Common/Tabs/Tabs";
import FormHandler from "../../Common/Form/FormHandler/FormHandler";
import useFormPersist from "react-hook-form-persist";
import {schema,createRegionFormConfig } from "../../../../JsonData/CreateRegionConfig";
import AddDeleteTableRows from "../AddDeleteTableRows/AddDeleteTableRows";
import { postDataWithBody, getData, putDataWithBody } from "../../../../Services/Services";
import { toast, ToastContainer } from "react-toastify";
import "./CreateRegion.css";
import {
    createRegion,updateRegion,findRegionById
  } from "../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";


const RegionRegistrationForm = (props) => {
    const [regionConfig, setRegionConfig] = useState(createRegionFormConfig);
    const navigate = useNavigate();
    const [rowValues, setRowValues] = useState([]);
    const [dNoneClass, setDNoneClass] = useState("d-none");
    const [activeTab, setActiveTab] = useState(0);
    const { state } = useLocation();
    let id = state?.id;
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        getValues,
        trigger,
        reset,
        control,
        formState: { errors },
    } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });

    const GenerateForm = (fields, register, errors, control) => {
        return (
            <FormHandler
                register={register}
                errors={errors}
                fields={fields}
                control={control}
                columns={2}
                getValues={getValues}
            />
        );
    };
    const generateTabsData = (tabWiseData) => {
        tabWiseData?.forEach((element) => {
            return (element.component = GenerateForm(
                element?.fields,
                register,
                errors,
                control
            ));
        });
        return tabWiseData;
    };

    const submitHandler = async (data, e) => {
        let res = null;
        e.preventDefault();
        data["attribute"] = rowValues;
        data["status"] = true;
        if(props.id !=null && props.actionType === "EDIT"){
            data["id"] = props.id;
            res= await putDataWithBody(updateRegion, data, {});
            if(res.status == "success"){
                window.regionTreeComponent.hideModalPopup();
                toast.success(res.msg, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000, // Close after 2 seconds
                });
            }
            else{
                toast.error(res.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000, // Close after 2 seconds
                });
            }
        }
        else{
            let hierListKeys = ["hier_level_one_id","hier_level_two_id","hier_level_three_id","hier_level_four_id","hier_level_five_id"]
            let hierIdList = props.keyVal.split("-");

            hierIdList.forEach(function(hier,index){
                data[hierListKeys[index]] = hier;
            });
            data["parent_region_id"] = hierIdList[hierIdList.length-1];
            data["region_type_id"] = props.regionTypeId;
            
            res = await postDataWithBody(createRegion, data, {});
            if(res.status == "success"){
                window.regionTreeComponent.hideModalPopup();
                toast.success(res.msg, {
                  position: toast.POSITION.TOP_CENTER,
                  autoClose: 2000, // Close after 2 seconds
                });
            }
            else{
                toast.error(res.msg, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000, // Close after 2 seconds
                });
            }
        }
        //props.refreshRegionList(res.msg);
        
    };
    const updateRowValues = (newRowValues) => {
        setRowValues(newRowValues);
    };

    useEffect(() => {
        if(props.keyVal.split("-").length == 5){
            setDNoneClass("");
        }
        else{
            setDNoneClass("d-none");
        }
        
    },[props.keyVal]);
    useEffect(() => {
        if(props.actionType !=null && props.actionType =="EDIT"){
            populateRegionFormFrEdit(props.id,props.key);
        }
        else{
           reset();
           setRowValues([{"attributeName":"Total Male","attributeValue":""}]);
        }
    },[props.updateRegionData]);
    const populateRegionFormFrEdit = async (id,key) => {
        var regionData = await postDataWithBody(findRegionById, [id], {});
        regionData = regionData[0];
        setValue("name",regionData.name);
        setValue("description",regionData.description);
        setValue("zipcode",regionData.zipcode);
        setRowValues(regionData.attribute);
    };
    // useEffect(() => {
    //     getAgencyTypeList();
    //     getHierarchyLevelList();
    // }, []);

    // async function getAgencyTypeList() {
    //     var agencyList = await getData(agencyTypeListURL);
    //     for (let agency in agencyList) {
    //         agencyList[agency]["label"] = agencyList[agency]["name"];
    //         agencyList[agency]["value"] = agencyList[agency]["id"];
    //     }
    //     agencyConfig[0].fields[1]["options"] = agencyList;
    //     setAgencyConfig(agencyConfig);
    // }
    const navToRegionList = (msg) => {
        navigate("/regionlist", { state: { msg: msg } });
    };

    // async function getHierarchyLevelList() {
    //     var hierarchyList = await getData(hierarchylevelListURL);
    //     for (let hierarchy in hierarchyList) {
    //         hierarchyList[hierarchy]["label"] = hierarchyList[hierarchy]["name"];
    //         hierarchyList[hierarchy]["value"] = hierarchyList[hierarchy]["id"];
    //     }
    //     agencyConfig[0].fields[2]["options"] = hierarchyList;
    //     setAgencyConfig(agencyConfig);
    // }

    const saveTabDataHandler = async (e) => {
        const isStepValid = await trigger();
        if (isStepValid) setActiveTab((prevId) => prevId + 1);
    };

    const previousHandler = () => {
        if (activeTab > 0) {
            setActiveTab((prevId) => prevId - 1);
        }
    };

    const tabsData = generateTabsData(regionConfig);

    return (
        <div className="">
            {/* <div className="form-header">
        <DynamicTabs
          tabsData={tabsData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isNavEnabled={false}
        />
      </div> */}

            <div className="form-body">
                <div className="registration__form--body">
                    <form onSubmit={handleSubmit(submitHandler)} name="AgancyRegForm">
                        <div class="label_text container pb-2 ps-3 mb-4">Region Information</div>
                        <div>
                            {tabsData?.map((tab) => (
                                <>
                                    {tab.id === activeTab && (
                                        <>
                                            <div className="registration__form--heading">
                                                {tab.heading}
                                            </div>
                                            <div>{tab.component}</div>
                                        </>
                                    )}
                                </>
                            ))}
                        </div>
                            <div className={dNoneClass}>
                                <div class="label_text container pb-2 ps-3 mt-3 mb-4">Region Parameters</div>
                                <div>
                                            {" "}
                                    <AddDeleteTableRows 
                                                rowValues={rowValues}
                                                updateRowValues={updateRowValues}
                                            />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                                {activeTab > 0 && (
                                    <Button
                                        variant="secondary"
                                        className="previousbutton-style mx-2"
                                        onClick={previousHandler}
                                    >
                                        Previous
                                    </Button>
                                )}
                                {tabsData.length - 1 === activeTab && (
                                    <Button type="submit" className="mx-2" variant="primary">
                                        Save & Submit
                                    </Button>
                                )}
                                {tabsData.length - 1 !== activeTab && (
                                    <Button
                                        variant="primary"
                                        className="mx-2"
                                        onClick={saveTabDataHandler}
                                    >
                                        Save & Next
                                    </Button>
                                )}
                            </div>
                       
                    </form>
                </div>
            </div>
        </div>
    );
};
export default RegionRegistrationForm;
