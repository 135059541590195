import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState, useEffect} from "react";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";
import Table from "../../../../Components/UiComponents/Table/Table";
import FLCKTable from "../PopupContent/FLCKTable";
import HLTHCamp from "../PopupContent/HLTHCamp";
import {
  PaperClipIcon,
  XCircleblack,
} from "../../../../Components/IconComponents";
import {postDataWithBody } from "../../../../Services/Services";
import {
  getFloodReliefData,
  getMealsDetailsURL,
} from "../../../../Services/EndPoints";
import { Modal } from "react-bootstrap";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { columnsForExport } from "../../../../JsonData/FloodReliefCenterConfig";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
const FloodReliefCenterTable = () => {
  const [floodReliefCenterData, setFloodReliefCenterData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [categoryCd, setCategoryCd] = useState(null);
  const [headerText, setHeaderText] = useState("");
  const { t, i18n } = useTranslation();
  const [ckStatusOpen, setCKStatusOpen] = useState(false);
  const [facilityReportId, setFacilityReportId] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const navigate = useNavigate();
   const dispatch = useDispatch();
  useEffect(() => {
    getFloodReliefCenterData();
  }, []);

   const sendDataToCentralStore = (data) => {
    console.log("floodcenterdata=", data);
     if (data.length > 0) {
       const columns = columnsForExport;
       dispatch(
         setTableData({
           columns,
           tableData: getExportedColumnsData(columns, data),
         })
       );
     }
   };

  const downloadFile = async (fileURL,fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    let headers = new Headers();
    headers.append('Authorization', 'Bearer '+localStorage.getItem("auth_token"));
    fetch(fileURL, { headers })
      .then(response => response.blob())
      .then(blobby => {
          let objectUrl = window.URL.createObjectURL(blobby);
          anchor.href = objectUrl;
          anchor.download = fileName;
          anchor.click();
          window.URL.revokeObjectURL(objectUrl);
    });
  }
  const getFloodReliefCenterData = async () => {
    let input = {"category_cd":"FLD_RLF_CNTR"};
    var frList = await postDataWithBody(getFloodReliefData, input, {});
    setRowCount(frList.length);
    setFloodReliefCenterData(frList);
    sendDataToCentralStore(frList);
  };

  

  const hideModal = () => {
    setIsShowModal(false);
  };
  const openPopUP = (categoryCd,facilityreportId,ckStat) => {
    setCKStatusOpen(ckStat)
    setHeaderText(ckStat == true ? t('mealdetails') : t('healthcampdetails'));
    setCategoryCd(categoryCd);
    setFacilityReportId(facilityreportId);
    setIsShowModal(true);
    //getMealsDetailsData(mealTypeCd,facilityreportId);
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t('floodreliefcentertableheaders.slno'),
        accessor: "sno",
        disableSortBy: true,
        Cell: (props) => <span>{props.row.index + 1}</span>,
      },
      {
        Header: t('floodreliefcentertableheaders.facility'),
        accessor: "facility_name",
        disableSortBy: true
      },
      {
        Header: t('floodreliefcentertableheaders.district'),
        accessor: "district_name",
        disableSortBy: true,
      },
      {
        Header: t('floodreliefcentertableheaders.block'),
        accessor: "block_name",
        disableSortBy: true
       
      },
     
      {
        Header: t('floodreliefcentertableheaders.createdon'),
        accessor: "created_on",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t('floodreliefcentertableheaders.opendate'),
        accessor: "open_date",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t('floodreliefcentertableheaders.operationaldate'),
        accessor: "operational_date",
        Cell: (props) => {
          if (props.value) {
            return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      // {
      //   Header: "Close Date",
      //   accessor: "close_date",
      //   Cell: (props) => {
      //     if (props.value) {
      //       return <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>;
      //     } else {
      //       return "NA";
      //     }
      //   },
      //   disableSortBy: true,
      // },

      {
        Header: t('floodreliefcentertableheaders.operational'),
        accessor: "operational_attribute",
        disableSortBy: true,
      },
      {
        Header: t('floodreliefcentertableheaders.totalmeals'),
        accessor: "total_meals",
        Cell: (props) => {
          return (
            <div  className="text-center value-style"
              onClick={() =>
                openPopUP("CK",props.row.original.id,true)
              }
            >
              {props.value}
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: t('floodreliefcentertableheaders.healthcamp'),
        Cell: (props) => {
          return (
            <div  className="text-center value-style"
              onClick={() =>
                openPopUP("FL",props.row.original.id,false)
              }
            >
              View
            </div>
          );
        },
        disableSortBy: true,
      },
      
      {
        Header: t('floodreliefcentertableheaders.action'),
        accessor: "attachment_path",
        disableSortBy: true,
        Cell: (props) => {
            let downloadAttachmentURL = process.env.REACT_APP_APP_SERVER_ADDRESS +"/"+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+"/download/download_attachments/?directoryPath="+props.value+"&fileName=Flood_Relief_Center.zip";
          return (
            <div className="text-center">
              <span className="icon__position">
              <div
                onClick={() => downloadFile(downloadAttachmentURL,'Flood_Relief_Center_'+new Date().getTime()+'.zip')} 
                ><PaperClipIcon className="cursor-pointer" /></div>
                
              </span>
            </div>
          );
        },
      },
    ],
    [t]
  );
  
  return (
    <div>
      <div className="container  create-user">
        <div className="datatable__container">
          {floodReliefCenterData && (
            <Table
              data={floodReliefCenterData}
              columns={columns}
              numberOfRows={7}
              showExport={false}
              showFilter={false}
              showReset={false}
              onExpand={false}
              rowCount={rowCount}
              headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
              tableStyle={{
                border: "1px solid  #f0f0f0",
                padding: "2px",
                borderRadius: "12px",
              }}
            />
          )}
        </div>
      </div>
      <Modal show={isShowModal} size="lg">
        <Modal.Header>
          <Modal.Title>{headerText}</Modal.Title>
          <XCircleblack onClick={hideModal} width="1.5em" height="1.5em" />
        </Modal.Header>
        <Modal.Body>
              {ckStatusOpen == true?
              <FLCKTable  categoryCd = {categoryCd} facilityReportId= {facilityReportId}/>:
              <HLTHCamp  categoryCd = {categoryCd} facilityReportId= {facilityReportId}/>
              }
    
          
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FloodReliefCenterTable;
