import * as yup from "yup";

export const schema = [
  yup.object({
    // contact_list: yup.string().required("Please select the contact"),
    contact_list: yup
      .array()
      .required("Please select the contact")
      // .min(1, "Pick atleast 1")
      .of(
        yup.object().shape({
          value: yup.string().required(),
        })
      ),
  }),
];

export const resourceFormConfig = [
  {
    id: 0,
    name: "General Information",
    fields: [
      {
        name: "rsrc_activity_id",
        label: "resourceformlabels.activity",
        type: "select",
        options: [],
      },
      {
        name: "rsrc_cat_id",
        label: "resourceformlabels.resourcecategory",
        type: "select",
        options: [],
      },

      {
        name: "name",
        label: "resourceformlabels.name",
        type: "select",
      },

      {
        name: "total_quantity",
        label: "resourceformlabels.totalquantity",
        type: "input",
      },
      {
        name: "damaged_quantity",
        label: "resourceformlabels.outofservice",
        type: "input",
      },
      {
        name: "facility_id",
        label: "resourceformlabels.facility",
        type: "select",
        options: [],
      },
      {
        name: "agency_id",
        label: "resourceformlabels.agency",
        type: "select",
      },
      {
        name: "region",
        label: "resourceformlabels.region",
        type: "hier_select",
      },
      {
        name: "contact_list",
        label: "resourceformlabels.contact",
        type: "multi_select",
        mandatory: "true",
      },
      {
        name: "description",
        label: "resourceformlabels.description",
        type: "text_area",
      }
    ],
  },
];
