import React, { useContext, useState, useRef, useEffect } from "react";
import axios from "axios";
import Popover from "react-bootstrap/Popover";
import Overlay from "react-bootstrap/Overlay";
import { ThemeSelectionIcon } from "../../IconComponents";
import { ThemeContext } from "../../..";
import "./ThemesPopUp.css";
import {
  getThemeURL,
  updateThemeDataURL,
  updateUserData,
} from "../../../Services/EndPoints";
import { putDataWithBody } from "../../../Services/Services";
import { useSelector } from "react-redux";
import {
  getData,
  putDataFormDataWithNoidPf,
} from "../../../Services/ProfileServices";
const ThemesPopUp = () => {
  const [showPopover, setShowPopover] = useState(false);
  const [target, setTarget] = useState(null);
  const [iconClicked, setIconClicked] = useState(false);
  const ref = useRef(null);
  const { theme, toggleTheme } = useContext(ThemeContext);
  const handleClick = (event) => {
    setShowPopover((prevState) => !prevState);
    setIconClicked((prevState) => !prevState);
    setTarget(event.target);
  };
  const [userData, setUserData] = useState(null);
  const { profileData } = useSelector((store) => store.data);

  useEffect(() => {
    setUserData(profileData);
  }, [profileData]);

  useEffect(() => {
    const handleWindowClick = (event) => {
      if (showPopover && ref.current && !ref.current.contains(event.target)) {
        setShowPopover(false);
        setIconClicked(false);
      }
    };
    window.addEventListener("click", handleWindowClick);
    return () => {
      window.removeEventListener("click", handleWindowClick);
    };
  }, [showPopover]);

  const updateTheme = async (theme) => {
    const userProfile = await getData(getThemeURL);
    if (userProfile) {
      const user_profile = {
        id: userProfile[0].id,
        theme: theme,
      };
      const formData = new FormData();
      formData.append("user_profile", JSON.stringify(user_profile));

      // Send the updated user profile data
      const updateResponse = await putDataFormDataWithNoidPf(
        updateUserData,
        formData
      );

      if (updateResponse) {
      } else {
        console.error("Failed to update user profile");
      }
    } else {
      console.error("Failed to fetch user profile");
    }
  };

  const handleThemeChange = async (theme) => {
    await updateTheme(theme);
    toggleTheme(theme); // Update the theme context
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="mx-4 themeicon  cursor-pointer" ref={ref}>
        <ThemeSelectionIcon
          width="1.6rem"
          height="1.6rem"
          onClick={handleClick}
        />

        <Overlay
          show={showPopover}
          target={target}
          placement="bottom"
          container={ref}
        >
          <Popover id="popover-contained">
            <Popover.Header as="h3" className="dark_mode_label">
              Select Theme
            </Popover.Header>
            <Popover.Body>
              <div className="themepopup cursor-pointer d-flex">
                <div className="theme-radio text-center">
                  <input
                    type="radio"
                    id="dark"
                    name="theme"
                    value="dark"
                    className="darkradio"
                    checked={theme === "dark"}
                    // onChange={() => toggleTheme("dark")}
                    onChange={() => handleThemeChange("dark")}
                  />
                  <label htmlFor="dark"></label>
                  <span className="hoverlabel">
                    <center>Dark Mode</center>
                  </span>
                  <span className="d-block theme__font">Dark Mode</span>
                </div>

                <div className="theme-radio text-center">
                  <input
                    type="radio"
                    id="light"
                    name="theme"
                    value="light"
                    className="lightradio"
                    checked={theme === "light"}
                    // onChange={() => toggleTheme("light")}
                    onChange={() => handleThemeChange("light")}
                  />
                  <label htmlFor="light"></label>
                  <span className="hoverlabel">
                    <center>Light Mode</center>
                  </span>
                  <span className="d-block theme__font">Light Mode</span>
                </div>
              </div>
            </Popover.Body>
          </Popover>
        </Overlay>
      </div>
    </ThemeContext.Provider>
  );
};

export default ThemesPopUp;
