import React from "react";
import "./DatePicker.css";

const DatePicker = ({
  label = "",
  name = "",
  minDate = null,
  maxDate = null,
  register = () => {},
  errors = null,
  mandatory = false,
  defaultDateValue = null,
  applyTableCustomClass = false,
  setDatePicked = () => {},
  style = {},
  setDate = () => {},
  disabled,
}) => {
  const handleDateChange = (event) => {
    const date = event.target.value;
    setDatePicked(date);
    setDate(date);
  };
  return (
    <div className="date__container">
      {label && (
        <label className="datepicker__label" htmlFor={name}>
          {label}
          {mandatory && <span className="asterisk">*</span>}
        </label>
      )}
      <div>
      <input
        type="date"
        min={minDate}
        max={maxDate}
        disabled={disabled}
        style={style}
        className={`date_picker_control form-control 
        ${disabled && "disabled__field"}
        ${applyTableCustomClass ? "custom-form-control" : ""} ${
          errors && errors[name] ? "error__border--color" : "form__input"
        }`}
        id={name}
        name={name}
        defaultValue={defaultDateValue}
        {...register(name)}
        onChange={handleDateChange}
      />
      {errors && errors[name] && (
        <p className="error__message">{errors[name].message}</p>
      )}
    </div>
    </div>
  );
};
export default DatePicker;

