import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Importfile from "../Importfile/Importfile";
import { postDatawithFormData } from "../../../Services/Services";
import { toast } from "react-toastify";

const ImportFileForm = ({ importEndpoint, handleToggle,downloadTemplate}) => {
  const FileSize = 15000000;
  const schema = yup
    .object()
    .shape({
      fileInput: yup
        .mixed()
        .required("You must upload a file.")
        .test(
          "required",
          "Please upload the image",
          (value) => value.length > 0
        )
        .test("fileSize", "File size must be less than 15MB ", (value) => {
          return value[0]?.size <= FileSize;
        }),

      // .test(
      //   "is-csv",
      //   "Invalid file format. Only CSV files are allowed.",
      //   (value) => {
      //     if (!value) return true; // Skip the validation if no file is selected
      //     return value.type === "text/csv";
      //   }
      // ),
    })
    .required();

  // Initialize React Hook Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema), // Use Yup resolver for validation
  });

  // Handle form submission
  const onSubmit = (data) => {
    const file = data?.fileInput[0];
    const formData = new FormData();
    formData.append("file", file);
    postDatawithFormData(importEndpoint, formData)
      .then((response) => {
        handleToggle();
        toast("Succesfully imported data!");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className=" d-flex import-form">
          <Importfile register={register} name="fileInput" errors={errors} downloadTemplate={downloadTemplate} />
        </div>
      </form>
    </div>
  );
};
export default ImportFileForm;
