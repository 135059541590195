import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./ModalComponent.css";
import { ModalCloseIcon } from "../../IconComponents";
import { LayoutState } from "../../../Context/LayoutContext/LayoutContext";

const ModalComponent = (props) => {
  const { title, show, handleToggle, size, component, fullscreen } = props;
  const { collapsed } = LayoutState();
  return (
    <>
      <Modal
        show={show}
        onHide={handleToggle}
        size={size}
        fullscreen={fullscreen}
        contentClassName="modal--size"
      >
        <Modal.Header>
          <Modal.Title>
            <h6>{title}</h6>
          </Modal.Title>
          <ModalCloseIcon
            width="2em"
            height="2em"
            onClick={handleToggle}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>
        <Modal.Body>{component}</Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
