import React from "react";
import { Expand } from "../../../../IconComponents";

const ExpandTable = ({ onExpand }) => {
  return (
    <div style={{ margin: "0.3em" }}>
      <Expand fill="#fff" width="1.1em" height="1.1em" onClick={onExpand} />
    </div>
  );
};

export default ExpandTable;
