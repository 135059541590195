import * as yup from 'yup';



export const schema = [
  yup.object({
    affected_municipal_bodies: yup
    .string()
    .required("Please provide the currently affected municipal bodies")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid currently affected municipal bodies"),
    affected_villages: yup
    .string()
    .required("Please provide the currently affected village count")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a  currently affected village count"),
    affected_wards: yup
    .string()
    .required("Please provide the currently affected ward count")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid currently affected ward count"),
    affected_blocks_count: yup
    .string()
    .required("Please provide the currently affected blocks count")
    .matches(/^(0|1)$/, "Please enter only 0 or 1"),
    affected_gram_panchayats: yup
    .string()
    .required("Please provide the currently affected Gram panchayat count")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a currently affected Gram panchayat count"),
    affected_population:yup
    .string()
    .required("Please provide the currently affected population (rural+urban)(in Lakhs)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a currently affected population (rural+urban)(in Lakhs)"),
    total_community_kitchens_opened_so_far: yup
    .string()
    .required("Please provide the total number of community kitchens opened so far (cumulative)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of community kitchens opened so far (cumulative)"),
    total_community_kitchens_closed_so_far: yup
    .string()
    .required("Please provide the total number of community kitchens closed so far (cumulative)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of community kitchens closed so far (cumulative)"),
    total_community_kitchens_open_today: yup
    .string()
    .required("Please provide the total community kitchens open today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total community kitchens open today"),
    number_of_people_eating_lunch_today: yup
    .string()
    .required("Please provide the number of people ate food in lunch today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of people ate food in lunch today"),
    number_of_people_eating_dinner_tonight: yup
    .string()
    .required("Please provide the number of people ate food in dinner today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of people ate food in dinner today"),
    number_of_people_eating_in_community_kitchens:yup
    .string()
    .required("Please provide the number of people ate food today (day+night)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of people ate food today (day+night)"),
    total_number_of_meals_provided_till_date:yup
    .string()
    .required("Please provide the total number of people ate till date (cumulative)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of people ate till date (cumulative)"),
    number_of_health_centers_running_daily:yup
    .string()
    .required("Please provide the number of health centers running daily")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of health centers running daily"),
    number_of_health_centers_running_till_now:yup
    .string()
    .required("Please provide the number of health centers running till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of health centers running till now"),
    number_of_people_treated_daily:yup
    .string()
    .required("Please provide the total number of people treated daily")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of people treated daily"),
    number_of_people_treated_till_now:yup
    .string()
    .required("Please provide the total number of people treated till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of people treated till now"),
    number_of_halogen_tablets_distributed_daily:yup
    .string()
    .required("Please provide the total number of halogen tablets distributed daily")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of halogen tablets distributed daily"),
    number_of_halogen_tablets_distributed_till_now:yup
    .string()
    .required("Please provide the total number of halogen tablets distributed till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of halogen tablets distributed till now"),
    amount_of_bleaching_powder_sprinkled_daily:yup
    .string()
    .required("Please provide the amount of bleaching powder sprinkled daily (kg)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid amount of bleaching powder sprinkled daily(kg)"),
    amount_of_bleaching_powder_sprinkled_till_now:yup
    .string()
    .required("Please provide the amount of bleaching powder sprinkled till now (kg)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid amount of bleaching powder sprinkled till now(kg)"),
    number_of_animal_camps_daily:yup
    .string()
    .required("Please provide the number of animal camps daily")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animal camps daily"),
    number_of_animal_camps_till_now:yup
    .string()
    .required("Please provide the number of animal camps till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animal camps till now"),
    number_of_animals_treated_daily:yup
    .string()
    .required("Please provide the number of animals treated daily")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animals treated daily"),
    number_of_animals_treated_till_now:yup
    .string()
    .required("Please provide the number of animals treated till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animals treated till now"),
    amount_of_gr_recommendation:yup
    .string()
    .required("Please provide the amount of GR (Gratuitous Relief) recommendation @Rs.7000 per family")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid amount of GR (Gratuitous Relief) recommendation @Rs.7000 per family"),
    number_of_dry_ration_packets_per_family:yup
    .string()
    .required("Please provide the number of dry ration packets per family (Chana,Chura,Jaggery,etc)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of dry ration packets per family (Chana,Chura,Jaggery,etc)"),
    number_of_food_packets:yup
    .string()
    .required("Please provide the number of food packets")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of food packets"),
    number_of_polythene_sheets_distributed:yup
    .string()
    .required("Please provide the number of polythene sheets distributed")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of polythene sheets distributed"),
    other_ingredients:yup
    .string()
    .required("Please provide the other Ingredients (KG/Ltr/number)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid other Ingredients (KG/Ltr/number)"),
    kutcha_complete:yup
    .string()
    .required("Please provide the kutcha house (complete damage)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid kutcha house (complete damage)"),
    kutcha_partial:yup
    .string()
    .required("Please provide the kutcha house (partial damage)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid kutcha house (partial damage)"),
    concrete_complete:yup
    .string()
    .required("Please provide the concrete house (complete damage)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid concrete house (complete damage)"),
    concrete_partial:yup
    .string()
    .required("Please provide the concrete house (partial damage)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid concrete house (partial damage)"),
    cottage_hut:yup
    .string()
    .required("Please provide the cottage hut")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid cottage hut"),
    animal_shed:yup
    .string()
    .required("Please provide the animal shed")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid animal shed"),
    estimated_house_value:yup
    .string()
    .required("Please provide the estimated house value (in Rs. Lakh)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid estimated house value (in Rs. Lakh)"),
    estimated_public_property_value:yup
    .string()
    .required("Please provide the estimated public property value (in Rs. Lakh)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid estimated public property value (in Rs. Lakh)"),
    human_deaths:yup
    .string()
    .required("Please provide the humans death count")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid  humans death count"),
    animal_deaths:yup
    .string()
    .required("Please provide the  animals death count")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a animals death count"),
    total_evacuated_till_yesterday:yup
    .string()
    .required("Please provide the total population evacuated till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total population evacuated till yesterday"),
    total_evacuated_today:yup
    .string()
    .required("Please provide the total population evacuated today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total population evacuated today"),
    total_evacuated_till_now:yup
    .string()
    .required("Please provide the total population evacuated till now")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total population evacuated till now"),
    damaged_boats_complete:yup
    .string()
    .required("Please provide the completely damaged boats")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid completely damaged boats"),
    damaged_boats_partial:yup
    .string()
    .required("Please provide the partially damaged boats")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid partially damaged boats"),
    damaged_net_complete:yup
    .string()
    .required("Please provide the completely damaged fishing net")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid completely damaged fishing net"),
    damaged_net_partial:yup
    .string()
    .required("Please provide the  partially damaged fishing net")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid  partially damaged fishing net"),
    area_of_damaged_fish_egg_farm:yup
    .string()
    .required("Please provide the area of damaged fish egg farm (in hectares)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid area of damaged fish egg farm (in hectares)"),
    total_camps_opened_so_far:yup
    .string()
    .required("Please provide the total flood relief camps opened so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total flood relief camps opened so far"),
    total_camps_closed_so_far:yup
    .string()
    .required("Please provide the total flood relief camps closed so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total flood relief camps closed so far"),
    total_camps_open_today:yup
    .string()
    .required("Please provide the total flood relief camps open today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total flood relief camps open today"),
    total_people_registered_till_yesterday:yup
    .string()
    .required("Please provide the total people registered till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total people registered till yesterday"),
    new_people_registered_today:yup
    .string()
    .required("Please provide the new people registered today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid new people registered  today"),
    total_people_registered_so_far:yup
    .string()
    .required("Please provide the total people registered so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total people registered so far"),
    total_people_gone_back_from_camps:yup
    .string()
    .required("Please provide the total people gone back from camps")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total people gone back from camps"),
    ra_total_gram_panchayats_affected:yup
    .string()
    .required("Please provide the total number of gram panchayats affected so far(cumulative)(rural)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of gram panchayats affected so far(cumulative)(rural)"),
    ra_number_of_villages_affected:yup
    .string()
    .required("Please provide the total number of villages affected so far(cumulative)(rural)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of villages affected so far(cumulative)(rural)"),
    ua_total_nagar_panchayats_affected:yup
    .string()
    .required("Please provide the total number of affected nagar panchayats(cumulative)(urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of affected nagar panchayats(cumulative)(urban)"),
    ua_total_municipal_corporations_affected:yup
    .string()
    .required("Please provide the total affected municipal corporation (cumulative)(urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total affected municipal corporation (cumulative)(urban)"),
    ua_total_nagar_parishads_affected:yup
    .string()
    .required("Please provide the total number of affected nagar parishad(cumulative)(urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of affected nagar parishad(cumulative)(urban)"),
    ua_number_of_wards_affected:yup
    .string()
    .required("Please provide the number of wards affected so far (cumulative)(urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of wards affected so far (cumulative)(urban)"),
    affected_population_rural_yesterday:yup
    .string()
    .required("Please provide the rural population affected till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid rural population affected till yesterday"),
    affected_population_urban_yesterday:yup
    .string()
    .required("Please provide the urban population affected till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid urban population affected till yesterday"),
    affected_population_total_yesterday:yup
    .string()
    .required("Please provide the total population affected till yesterday(rural+urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total population affected till yesterday(rural+urban)"),
    affected_families_rural_yesterday:yup
    .string()
    .required("Please provide the rural families affected till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid rural families affected till yesterday"),
    affected_families_urban_yesterday:yup
    .string()
    .required("Please provide the urban families affected till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid urban families affected till yesterday"),
    affected_families_total_yesterday:yup
    .string()
    .required("Please provide the total families affected till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total families affected till yesterday"),
    affected_animals_rural_yesterday:yup
    .string()
    .required("Please provide the animals affected in rural areas till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid animals affected in rural areas till yesterday"),
    affected_animals_urban_yesterday:yup
    .string()
    .required("Please provide the animals affected in urban areas till yesterday")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid animals affected in urban areas till yesterday"),
    affected_animals_total_yesterday:yup
    .string()
    .required("Please provide the total animals affected till yesterday (rural+urban)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total animals affected till yesterday (rural+urban)"),
    affected_population_rural_today:yup
    .string()
    .required("Please provide the affected population rural today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected population rural today"),
    affected_population_urban_today:yup
    .string()
    .required("Please provide the affected population urban today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected population urban today"),
    affected_population_total_today:yup
    .string()
    .required("Please provide the affected population total today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected population total today"),
    affected_families_rural_today:yup
    .string()
    .required("Please provide the affected families rural today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected families rural today"),
    affected_families_urban_today:yup
    .string()
    .required("Please provide the affected families urban today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected families urban today"),
    affected_families_total_today:yup
    .string()
    .required("Please provide the affected families total today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected families total today"),
    affected_animals_rural_today:yup
    .string()
    .required("Please provide the affected animals rural today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected animals rural today"),
    affected_animals_urban_today:yup
    .string()
    .required("Please provide the affected animals urban today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected animals urban today"),
    affected_animals_total_today:yup
    .string()
    .required("Please provide the affected animals total today")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid affected animals total today"),
    affected_population_rural:yup
    .string()
    .required("Please provide the rural population affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid rural population affected so far"),
    affected_population_urban:yup
    .string()
    .required("Please provide the urban population affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid urban population affected so far"),
    affected_population_total:yup
    .string()
    .required("Please provide the total population affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total population affected so far"),
    affected_families_rural:yup
    .string()
    .required("Please provide the rural families affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid rural families affected so far"),
    affected_families_urban:yup
    .string()
    .required("Please provide the urban families affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid urban families affected so far"),
    affected_families_total:yup
    .string()
    .required("Please provide the total families affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total families affected so far"),
    affected_animals_rural:yup
    .string()
    .required("Please provide the number of animals affected in rural area so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animals affected in rural area so far"),
    affected_animals_urban:yup
    .string()
    .required("Please provide the number of animals affected in urban area so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of animals affected in urban area so far"),
    affected_animals_total:yup
    .string()
    .required("Please provide the total number of animals affected so far")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total number of animals affected so far"),
    agricultural_area_affected:yup
    .string()
    .required("Please provide the agricultural area affected")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid agricultural area affected"),
    non_agricultural_area_affected:yup
    .string()
    .required("Please provide the non-agricultural Area affected")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid non-agricultural Area affected"),
    // total_area_affected:yup
    // .string()
    // .required("Please provide the total area affected")
    // .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid total area affected"),
    damaged_crop_area_affected:yup
    .string()
    .required("Please provide the damaged crop area (in hectares)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid damaged crop area (in hectares)"),
    estimated_value_of_damaged_crops_affected:yup
    .string()
    .required("Please provide the estimated value of damaged crops (in Rs lakh)")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid estimated value of damaged crops (in Rs lakh)"),
    motor_boat:yup
    .string()
    .required("Please provide the number of motorboats used")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of motorboats used"),
    government_boat:yup
    .string()
    .required("Please provide the number of government boats used")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of government boats used"),
    private_boat:yup
    .string()
    .required("Please provide the number of private boats used")
    .matches(/^\d{1,50}(\.\d{1,5})?$/, "Please enter a valid number of private boats used"),
  })
];



export const FormInputData = {
  "flood_impact_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "affected_blocks_count",
          label: "currentflood.currentlyaffectedblocks",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_gram_panchayats",
          label: "currentflood.currentaffectedgrampanchayat",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_municipal_bodies",
          label: "currentflood.currentlyaffectedbodies",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_villages",
          label: "currentflood.currentlyaffectedvillage",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_wards",
          label: "currentflood.currentlyaffectedward",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_population",
          label: "currentflood.currentaffectedpopulation",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
      ],
    }],
  "community_kitchen_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "total_community_kitchens_opened_so_far",
          label:"communitykitchen.communitykitchenopen",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "total_community_kitchens_closed_so_far",
          label:
            "communitykitchen.communitykitchenclose",
          maxLength: 60,
          type: "input",
          mandatory:true,
        },
        {
          name: "total_community_kitchens_open_today",
          label: "communitykitchen.totalcommunitykitchen",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "number_of_people_eating_lunch_today",
          label: "communitykitchen.peopleatefoodlunch",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "number_of_people_eating_dinner_tonight",
          label: "communitykitchen.peopleatefooddinner",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "number_of_people_eating_in_community_kitchens",
          label: "communitykitchen.totalpeopleate",
          maxLength: 60,
          type: "input",
          "disabled":true
        },
        {
          name: "total_number_of_meals_provided_till_date",
          label: "communitykitchen.totalpeopleatetilldate",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
      ],
    },
  ],
  "health_center_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "number_of_health_centers_running_daily",
          label: "healthreport.numberofhealthcentersdaily",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "number_of_health_centers_running_till_now",
          label: "healthreport.numberofhealthcenterstillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_people_treated_daily",
          label: "healthreport.totalnumberofpeople",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_people_treated_till_now",
          label: "healthreport.totalpeopletreatedtillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_halogen_tablets_distributed_daily",
          label: "healthreport.halogentabletsdaily",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_halogen_tablets_distributed_till_now",
          label: "healthreport.halogentablets",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "amount_of_bleaching_powder_sprinkled_daily",
          label: "healthreport.amountofbleaching",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "amount_of_bleaching_powder_sprinkled_till_now",
          label: "healthreport.amountofbleachingtillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_animal_camps_daily",
          label: "healthreport.animalcampsdaily",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_animal_camps_till_now",
          label: "healthreport.animalcamptillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
        {
          name: "number_of_animals_treated_daily",
          label: "healthreport.animaltreateddaily",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_animals_treated_till_now",
          label: "healthreport.animalstreatedtillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
      ],
    },
  ],
  "relief_distribution_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "amount_of_gr_recommendation",
          label: "reliefdistribution.amountofgr",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_dry_ration_packets_per_family",
          label: "reliefdistribution.numberofdryration",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_food_packets",
          label: "reliefdistribution.numberoffoodpackets",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "number_of_polythene_sheets_distributed",
          label: "reliefdistribution.polythenedistributed",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "other_ingredients",
          label: "reliefdistribution.otheringredients",
          maxLength: 60,
          type: "input",
          mandatory: true
        }
      ],
    },
  ],

  "damaged_house_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "kutcha_complete",
          label: "damagehouse.completedamagekutchahouse",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "kutcha_partial",
          label: "damagehouse.partialdamagekutchahouse",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_complete",
          label: "damagehouse.completeconcretehousedamage",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "concrete_partial",
          label: "damagehouse.partialconcretehousedamage",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "cottage_hut",
          label: "damagehouse.cottagehut",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "animal_shed",
          label: "damagehouse.animalshed",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "estimated_house_value",
          label: "damagehouse.estimatedvalue",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "estimated_public_property_value",
          label: "damagehouse.estimatedpublic",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
      ],
    },
  ],

  death_report: [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "human_deaths",
          label: "deathreport.humancount",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "animal_deaths",
          label: "deathreport.animalcount",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
      ],
    },
  ],
  evacuation_report: [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "total_evacuated_till_yesterday",
          label: "evacuationreprt.totalpopulationtillyesterday",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "total_evacuated_today",
          label: "evacuationreprt.totalpopulationtilltoday",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "total_evacuated_till_now",
          label: "evacuationreprt.totalpopulationtillnow",
          maxLength: 60,
          type: "input",
          mandatory: true,
          "disabled": true
        },
      ],
    },
  ],
  "fishing_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "damaged_boats_complete",
          label: "fishingreport.completelydamage",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "damaged_boats_partial",
          label: "fishingreport.partiallydamage",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "damaged_net_complete",
          label: "fishingreport.completedamagefishing",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "damaged_net_partial",
          label: "fishingreport.partiallydamagefishing",
          maxLength: 60,
          type: "input",
          mandatory: true
        },
        {
          name: "area_of_damaged_fish_egg_farm",
          label: "fishingreport.areaofdamage",
          maxLength: 60,
          type: "input",
          mandatory: true
        },

      ],
    },
  ],
  "flood_relief_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "total_camps_opened_so_far",
          label: "floodrelief.totalfloodreliefopened",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "total_camps_closed_so_far",
          label: "floodrelief.totalfloodreliefclosed",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "total_camps_open_today",
          label: "floodrelief.totalfloodreliefopen",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "total_people_registered_till_yesterday",
          label: "floodrelief.totalpeopleregistered",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "new_people_registered_today",
          label: "floodrelief.newpeopleregistered",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "total_people_registered_so_far",
          label: "floodrelief.totalpeopleregisteredsofar",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true

        },
        {
          name: "total_people_gone_back_from_camps",
          label: "floodrelief.peoplegoneback",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "people_remaining_in_camps",
          label: "floodrelief.peopleremaining",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
      ],
    },
  ],
  "affected_area_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "ra_total_gram_panchayats_affected",
          label: "affectedarealabels.total_numbergrampanchayat",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "ra_number_of_villages_affected",
          label: "affectedarealabels.total_numbervillagesaffected",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "ua_total_nagar_panchayats_affected",
          label: "affectedarealabels.total_affectednagar",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "ua_total_municipal_corporations_affected",
          label: "affectedarealabels.total_affectedmunicipal",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "ua_total_nagar_parishads_affected",
          label: "affectedarealabels.total_affectednagarparishad",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "ua_number_of_wards_affected",
          label: "affectedarealabels.number_wardsaffected",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
      ],
    },
  ],
  "affected_details_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "affected_population_rural_yesterday",
          label: "affectedpopulationreport.ruralpopulation",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_population_urban_yesterday",
          label: "affectedpopulationreport.urbanpopulation",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_population_total_yesterday",
          label: "affectedpopulationreport.totalpopulation",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
        {
          name: "affected_families_rural_yesterday",
          label: "affectedpopulationreport.ruralfamilies",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_families_urban_yesterday",
          label: "affectedpopulationreport.urbanfamilies",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_families_total_yesterday",
          label: "affectedpopulationreport.totalfamilies",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
        {
          name: "affected_animals_rural_yesterday",
          label: "affectedpopulationreport.animalsaffectedinrural",
          maxLength: 60,
          type: "input",
          mandatory:true,
         "disabled": true
        },
        {
          name: "affected_animals_urban_yesterday",
          label: "affectedpopulationreport.animalsaffectedinurban",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_animals_total_yesterday",
          label: "affectedpopulationreport.totalanimals",
          maxLength: 60,
          type: "input",
          "disabled": true,
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_population_rural_today",
          label: "affectedpopulationreport.affectedpopulationrural",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_population_urban_today",
          label: "affectedpopulationreport.affectedpopulationurban",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_population_total_today",
          label: "affectedpopulationreport.affectedpopulationtotal",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
        {
          name: "affected_families_rural_today",
          label: "affectedpopulationreport.affectedfamiliesrural",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_families_urban_today",
          label: "affectedpopulationreport.affectedfamiliesurban",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_families_total_today",
          label: "affectedpopulationreport.affectedfamiliestotal",
          maxLength: 60,
          type: "input",
          "disabled": true
        },

        {
          name: "affected_animals_rural_today",
          label: "affectedpopulationreport.affectedanimalsrural",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_animals_urban_today",
          label: "affectedpopulationreport.affectedanimalsurban",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "affected_animals_total_today",
          label: "affectedpopulationreport.affectedanimalstotal",
          maxLength: 60,
          type: "input",
          "disabled": true
        },

        {
          name: "affected_population_rural",
          label: "affectedpopulationreport.ruralpopulationsofar",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_population_urban",
          label: "affectedpopulationreport.urbanpopulationsofar",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_population_total",
          label: "affectedpopulationreport.totalpopulationsofar",
          maxLength: 60,
          type: "input",
          "disabled": true
        },

        {
          name: "affected_families_rural",
          label: "affectedpopulationreport.ruralfamiliessofar",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_families_urban",
          label: "affectedpopulationreport.urbanfamiliessofar",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true

        },
        {
          name: "affected_families_total",
          label: "affectedpopulationreport.totalfamiliessofar",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
        {
          name: "affected_animals_rural",
          label: "affectedpopulationreport.numberofanimalsaffectedrural",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_animals_urban",
          label: "affectedpopulationreport.numberofanimalsaffectedurban",
          maxLength: 60,
          type: "input",
          mandatory:true,
          "disabled": true
        },
        {
          name: "affected_animals_total",
          label: "affectedpopulationreport.totalnumberaffected",
          maxLength: 60,
          type: "input",
          "disabled": true        },
      ],
    },
  ],
  "area_affected_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "agricultural_area_affected",
          label: "affectedagriculture.agriculturalarea",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "non_agricultural_area_affected",
          label: "affectedagriculture.non-agriculturearea",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "total_area_affected",
          label: "affectedagriculture.totalarea",
          maxLength: 60,
          type: "input",
          "disabled": true
        },
        {
          name: "damaged_crop_area_affected",
          label: "affectedagriculture.damagedcrop",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
        {
          name: "estimated_value_of_damaged_crops_affected",
          label: "affectedagriculture.estimatedvalue",
          maxLength: 60,
          type: "input",
          mandatory:true
        },
      ],
    },
  ],
  "boats_report": [
    {
      id: 0,
      name: "",
      heading: "",
      fields: [
        {
          name: "motor_boat",
          label: "boatusage.motorboatsused",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "government_boat",
          label: "boatusage.govtbpatdused",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
        {
          name: "private_boat",
          label: "boatusage.privateboat",
          maxLength: 60,
          type: "input",
          mandatory:true

        },
      ],
    },

  ]

};


