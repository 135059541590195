export const resourcemgmtcolumnsForExport = [
  // { label: "Name", key: "name" },
  { label: "Resource Name", key: "resource_name" },
  { label: "Resource Activity", key: "rsrc_activity_id" },
  { label: "Resource Category", key: "rsrc_cat_id" },
  { label: "Agency Name", key: "agency_name" },
  { label: "Region Name", key: "region_name" },
  {label: "Created on", key: "created_on"},
  {label: "Updated By", key: "updated_by"},
  { label: "Facility", key: "facility" },
];
