import React, { useState, createContext, useEffect, useMemo, useRef } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Table.css";
import Sortdownarrow from "../../IconComponents/Sortdownarrow";
import Sortuparrow from "../../IconComponents/Sortuparrow";
import Sortingarrow from "../../IconComponents/Sortingarrow";
import TableHeader from "./TableHeader/TableHeader";
import { useTranslation } from "react-i18next";

const CustomTable = (props) => {
  const { t } = useTranslation();
  const {
    columns,
    data,
    numberOfRows,
    showHeader = true,
    isHeaderFixed = false,
    showFilterColumn,
    setVisibleColumns,
    selectedView,
  } = props;

  const hiddenColumns = showFilterColumn
    ? columns
        .filter((column) => !column.showInitially)
        .map((column) => column.accessor)
    : [];

  const prevNumberOfRows = useRef(numberOfRows); 
  useEffect(() => {
    setVisibleColumns(columns);
  }, [columns]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    setGlobalFilter,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: hiddenColumns,
        pageSize: numberOfRows || 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    // Check if numberOfRows has actually changed before calling setPageSize
    if (numberOfRows !== prevNumberOfRows.current) {
      setPageSize(numberOfRows);
      prevNumberOfRows.current = numberOfRows;  // Update the ref to the new value
    }
  }, [numberOfRows, setPageSize]);

  const handleToggleHideColumn = async (columnId) => {
    await toggleHideColumn(columnId);
    const updatedVisibleColumns = allColumns.filter((col) => col.isVisible);
    const headers = updatedVisibleColumns.map((col) => {
      return {
        Header: col.Header,
        accessor: col.id,
      };
    });

    setVisibleColumns(headers);
  };

  const calculateTotals = () => {
    if (data.length === 0) return [];

    const totals = columns.map((column) => {
      if (column.isNumeric) {
        return data.reduce((acc, row) => acc + (row[column.accessor] || 0), 0);
      }
      return null;
    });

    return totals;
  };

  const totals = calculateTotals();

  const isTotalsRow = (row) => {
    // Example: Check if the row is the last one (or based on any other logic)
    return row.index === data.length - 1; // Assuming the totals row is the last one
  };

  return (
    <>
      <div className="iccc__table-header__table-container__wrap">
        {showHeader && (
          <TableHeader
            {...props}
            searchValue={globalFilter || ""}
            allColumns={allColumns}
            toggleHideColumn={handleToggleHideColumn} // Use updated handler
            onSearchHandler={setGlobalFilter}
            selectedView={selectedView}
          />
        )}
       <div className="cust__table--container" style={{ overflowX: "auto", position: "relative" }}>
        <div
          style={{ maxHeight: "700px", overflowY: "auto", position: "relative"}}
         >
          <table style={{ position: "sticky", top: 0, background: "#ffffff", zIndex: 1 }}
            {...getTableProps()}
            className="table iccc--table fixed-width-header">
            <thead>
              {headerGroups?.map((headerGroup, index) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                  className={isHeaderFixed ? "fixed__header" : ""}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={{
                        fontSize: "0.9rem",
                        minWidth: "10rem",
                        // position: "sticky",
                        // top: 0,
                        // zIndex: 1,
                      }}
                      key={index}
                      className={column.leftAlignHeader ? "left-align" : ""}>
                      {t(column.render("Header"))}
                        {!column.disableSortBy && column.isSorted && !column.isSortedDesc && (
                          <Sortuparrow /> // Only show the up arrow when sorted in ascending order
                        )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
          <tbody {...getTableBodyProps()}>
  {page.length > 0 &&
    page.map((row, i) => {
      prepareRow(row);
      const rowClassName = isTotalsRow(row) ? "totals-row" : "";
      return (
        <tr {...row.getRowProps()} key={i} className={rowClassName}>
          {row.cells.map((cell, i) => (
            <td
              key={i}
              {...cell.getCellProps()}
              className={cell.column.leftAlign ? "left-align" : ""}
              style={{
                border: "1px solid",
                textAlign: "center",
              }}>
              {cell.render("Cell")}
            </td>
          ))}
        </tr>
      );
    })}
  {/* {data.length > 0 && (
    <tr>
      <td colSpan={columns.length - 1} style={{ textAlign: "right" }}>Total</td>
      {columns.map((col) => (
        <td key={col.accessor} style={{ textAlign: "center" }}>
          {totals[col.accessor] || 0}
        </td>
      ))}
    </tr>
  )} */}
</tbody>

          </table>
          <div></div>
          {page.length === 0 && (
            <div className="no-data-text">No data Found</div>
          )}
            {/* {data.length > 0 && ( */}
              <table className="table iccc--table fixed-width-header">
                <tfoot>
  {/* <tr>
    <td colSpan={2} style={{ textAlign: "center", borderTop: "2px solid", fontWeight: "bold" }}>
      TOTAL
    </td>
    {totals.slice(2).map((total, index) => (
      <td key={index + 2} style={{ textAlign: "center", borderTop: "2px solid", fontWeight: "bold" }}>
        {total !== null ? total : ""}
      </td>
    ))}
  </tr> */}
</tfoot>

              </table>
            {/* )} */}
        </div>
      </div>
      </div>
    </>
  );
};

export default CustomTable;
