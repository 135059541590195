import React, { useEffect } from 'react'
import "./GisManagement.css";

const EventManagement = () => {
    
  const url = process.env.REACT_APP_GIS_SERVICE + "/createevent";
  return (
    <div className='iframecontainer'>
    <iframe src={url} sandbox='allow-scripts allow-modal'>
    </iframe>
    </div>
  );
}

export default EventManagement