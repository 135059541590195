import React, { useEffect, useRef } from "react";
import { GridStack } from "gridstack";
import GridItem from "../../../Components/UiComponents/GridItem/GridItem";
import DMSHeader from "../DMSHeader/DMSHeader";
import UserTable from "./UserTable/UserTable";
import { useTranslation } from "react-i18next";


const UserManagement = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    GridStack.init();
  }, []);

  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader title={t("user_management_header")} showdownload={true}
        fileName="UserTable"/>
      ),
      haveOverflowVisible: true,
    },
    {
      x: 0,
      y: 1,
      width: 12,
      height: 12,
      id: 2,
      component: <UserTable />,

    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default UserManagement;
