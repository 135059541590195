import React from "react";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./Importfile.css";
import {
  Upload,
  Rejectedicon,
  Csvicon,
} from "../../../../../Components/IconComponents";
import { downloadTemplateUnloadingpoint } from "../../../../../Services/EndPoints";

const ImportFile = ({ name, errors, register,downloadTemplate }) => {
  const [fileObj, setFileObj] = useState(null);

  const { onChange, ...registerParams } = register(name);

  const handleChange = (event) => setFileObj(event.target.files[0]);

  const handleDragOver = (e) => e.preventDefault();

  // A funtion to handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFileObj(file);
  };

  // A function to convert the bytes in to other size units
  const convertFileSize = (bytes) => {
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 B";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  // Trigger the file input click event
  const handleClick = () => {
    const fileInputTriger = document.getElementById(name);
    fileInputTriger.click();
  };

  // A funtion to return the icon based on the file type
  const generateIconByType = (type) => {
    let icon;
    if (type === "text/csv") {
      icon = <Csvicon />;
    } else {
      icon = "";
    }
    return icon;
  };

  return (
    <div onDrop={handleDrop} onDragOver={handleDragOver}>
      {fileObj ? (
        <div>
          <div className="import__file--heading">File Added</div>
          <div className="importfile__detail--box">
            <div className="d-flex">
              <div className="d-flex">
                <>
                  <div className="file_icon">
                    {generateIconByType(fileObj?.type)}
                  </div>
                </>

                <div className="file__detail">
                  <div
                    className={
                      fileObj?.name?.length > 10
                        ? "importfile__name--ellipsis"
                        : "file__detail"
                    }
                  >
                    {fileObj?.name}
                  </div>
                  <div className="importfile-size">
                    {convertFileSize(fileObj?.size)}
                  </div>
                </div>

                <div
                  className="import__reject--iconplace"
                  onClick={() => setFileObj(null)}
                >
                  <Rejectedicon />
                </div>
              </div>
              <div className="import-submit">
                <Button type="submit">submit</Button>
              </div>
            </div>
          </div>
          <span className="error__message">{errors[name]?.message}</span>
        </div>
      ) : (
        <div>
          <div className="file__upload--import">
            <div className="d-flex import__icon--text">
              <div className="icon__size--import">
                <Upload />
              </div>
              <div className="import-text">
                select a file or drag & drop here
              </div>
              <div className="import-select" onClick={handleClick}>
                <Button>Select</Button>
              </div>
            </div>
            <input
              id={name}
              type="file"
              accept=".csv"
              name={name}
              style={{ display: "none" }}
              onChange={(event) => {
                onChange(event);
                handleChange(event);
              }}
              {...registerParams}
            />
          </div>
          <div className="import-template">
            <div className="or_text">(or)</div>
            <div className="d-flex">
              <span className="download__template--text" >
                Download the .CSV file template
              </span>
              <span className="import-download">
                <Button type="submit" href={downloadTemplate}>Download </Button>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImportFile;
