import { combineReducers, configureStore } from "@reduxjs/toolkit";
import dataReducer from "./ExportLMSTable/dataSlice";
import { registrationReducer } from "./RegistrationsSlice/registrationSlice";


const rootReducer = combineReducers({
  data: dataReducer,
  registrations: registrationReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;