import React, { useState, useEffect, useRef, useContext } from "react";
import "../DailyFloodReportComp/Hindi_Font.js";
import "../DailyFloodReportComp/DailyFloodReportCompTable.css";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import govt_logo from "../../../../Assets/Images/bihar_logo.png";
import Loader from "react-spinner-loader";
//import html2pdf from 'html2pdf.js';
import ExcelJS from "exceljs";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import SelectComponent from "../../../../Components/UiComponents/SelectComponent/SelectComponent.js";
import { postDataWithBody, getData } from "../../../../Services/Services.js";
import {
  getChildRegionsById,
  findRegionsByUser,
  getDailyReportByDistrict,
  getDailyFloodReportListURL,
  cleardailyReportData,
  getDailyAffectedReportByDistrict,
  getDailyColdWaveReport,
} from "../../../../Services/EndPoints.js";
import { useTranslation } from "react-i18next";
import DatePicker from "../../Common/Form/DatePicker/DatePicker.js";
import { RoleContext } from "../../../../index.js";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup.js";

const DailyColdWaveReportForm = () => {
  const roleName = useContext(RoleContext);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [dailyReport, setDailyReport] = useState(null);
  const [popUp, setPopUp] = useState(false);
  const [districtData, setDistrictData] = useState([]);
  const [clearId, setClearId] = useState(null);
  const [blockData, setBlockData] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState(null);
  const [selectedDistrictId, setSelectedDistrictId] = useState(null);
  const [slctdBlkId, setSlctdBlkId] = useState(null);
  const [selectedBlockId, setSelectedBlockId] = useState(null);
  const pdfRef = useRef(null);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // State to store selected date
  const rolName = roleName.roleName;

  const getBlockIdListForReport = () => {
    if (slctdBlkId == 0) {
      let blockIdList = [];
      blockData.forEach((v) => blockIdList.push(v.id));
      return blockIdList;
    } else {
      return [slctdBlkId];
    }
  };

  //   const editFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         navigate("/dmsdashboard/editdfr", {
  //           state: {
  //             blockId: slctdBlkId,
  //             slctd_dt: document.getElementById("flood_date_picker").value,
  //           },
  //         });
  //       }
  //     }
  //   };
  //   const clearFloodData = async () => {
  //     if (slctdBlkId == 0) {
  //       toast.error("Please select a block to edit data.", {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //     } else {
  //       let slctdDate = document.getElementById("flood_date_picker").value;
  //       const data = await getData(
  //         getDailyFloodReportListURL
  //           .replace("ID", slctdBlkId)
  //           .replace("DATE", slctdDate)
  //       );
  //       if (data.length == 0) {
  //         toast.error("No data exists for the selected date and block", {
  //           position: toast.POSITION.TOP_CENTER,
  //           autoClose: 2000, // Close after 2 seconds
  //         });
  //       } else {
  //         setPopUp(true);
  //         setClearId(data[0].id);
  //       }
  //     }
  //   };
  const getDailyReportData = async (blockListId,onInit=false) => {
    if (blockListId == null) {
      blockListId = getBlockIdListForReport();
    }
    var selectedDateStr = document.getElementById("flood_date_picker").value;
    if (selectedDateStr == "") {
      selectedDateStr = new Date().toISOString().split("T")[0];
    }
    setSelectedDate(selectedDateStr);
    setLoader(true);

    const index = blockListId.indexOf(0);
    if (index > -1) {
      // only splice array when item is found
      blockListId.splice(index, 1); // 2nd parameter means remove one item only
    }
    let inputobj = {
      region_id_list: blockListId,
      reported_on: selectedDateStr,
    };
    let dailyReportColdWaveData = await postDataWithBody(
      getDailyColdWaveReport,
      inputobj,
      {}
    );
    dailyReportColdWaveData?.dr_cold_wave?.forEach(val => {
      val.district = districtData?.find(x => x.id == selectedDistrictId)?.value || ''
      if(onInit){
        Object.keys(val).forEach(key => {
          val[key] = val[key] ? val[key] : " "
        })
      }
    })
    setDailyReport(dailyReportColdWaveData);

    setLoader(false);
    var skillsSelect = document.getElementById("district_drop");
    var selectedText = skillsSelect.options[skillsSelect.selectedIndex].text;
    document.getElementById("selected_dist_name").innerHTML = selectedText;
  };

  async function addImageProcess() {
    return new Promise((resolve, reject) => {
      let img = new Image();
      img.src = govt_logo;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  }
  const downloadPDF = async () => {
    // debugger;
    console.log(i18n.language);
    let incrementVal = 20;
    let selectedDate = new Date(
      document.getElementById("flood_date_picker").value
    ).toLocaleDateString("en-GB");
    const content = pdfRef.current;
    const doc = new jsPDF("l", "pt", "a4");  // Set the orientation to landscape ("l")
    const image = await addImageProcess();

    let fontName = "Helvetica";

    if (i18n.language == "hi") {
      fontName = "Akshar Unicode";
    }
    doc.setFont(fontName);
    doc.setFontSize(13);
    doc.addImage(image, "png", 380, 28, 0, 0);
    var xOffset = doc.internal.pageSize.width / 2;
    doc.text(t("floodreport.govt_name"), xOffset, 120, { align: "center" });
    doc.text(t("floodreport.department_name"), xOffset, 140, {
      align: "center",
    });
    doc.text(
      t("Cold Wave Report") +
        " (" +
        t("floodreport.dated_on_lbl") +
        " " +
        selectedDate +
        ")",
      xOffset,
      160,
      { align: "center" }
    );
    doc.autoTable({
      html: "#report_table",
      startX: 0,
      startY: 190,
      theme: "grid",
      rowPageBreak: "avoid",
      bodyStyles: { lineColor: [0, 0, 0] },
      styles: { 
        font: fontName, 
        fontSize: 2, 
        cellPadding: 3, 
        lineColor: [0, 0, 0], // Black lines
        lineWidth: 0.1, // Adjust thickness of lines
      },
      headStyles: {
        fillColor: [255, 255, 255], // White background for header
        textColor: [0, 0, 0],       // Black text for header
        fontStyle: "bold",          // Bold header text
        lineColor: [0, 0, 0],       // Ensure grid lines stay black for the header
        lineWidth: 0.1,             // Ensure lines are drawn in header
      },
      columnStyles: {
        0: { cellWidth: 29 }, // S.No
        1: { cellWidth: 70 }, // District Name
        2: { cellWidth: 42 }, // Number of places...
        3: { cellWidth: 42 }, 
        4: { cellWidth: 42}, 
        5: { cellWidth: 42 },
        6: { cellWidth: 46 }, 
        7: { cellWidth: 42 }, 
        8: { cellWidth: 42 },
        9: { cellWidth: 42 },
        10: { cellWidth: 42 },
        11: { cellWidth: 42 }, 
        12: { cellWidth: 42 }, 
        13: { cellWidth: 42 },
        14: { cellWidth: 42 },
        15: { cellWidth: 42 }, 
        16: { cellWidth: 42 }, 
        17: { cellWidth: 44 },
      },
      rowPageBreak: "auto",
      pageBreak: "auto",
      didParseCell: function (data) {
        if (data.cell.raw) {
          if (data.cell.raw.querySelector("b")) {
            data.cell.styles.textColor = "red";
          }
          if (data.cell.raw.querySelector("span")) {
            data.cell.styles.halign = "center";
          }
        } else {
          console.warn("data.cell.raw is undefined", data);
        }
      },
      styles: {
        font: fontName, //<------ This is the font. I have already added this font using doc.addfont()
        fontSize: 9,
        cellWidth: "auto",
        halign: "left",
        cellPadding: 3,
      },
    });
    let finalY = doc.lastAutoTable.finalY; // The y position on the page
    doc.setFontSize(10);
    doc.text(550, finalY + 100, "Signature of District Officers / Additional District Magistrate", { maxWidth: 300 });
    doc.text(550, finalY + 120, "(ADM) / (Disaster Management Officer in Charge)", { maxWidth: 300 });
    doc.text(550, finalY + 140, `(District Disaster Management)   [${document.getElementById("selected_dist_name").innerHTML}]`, { maxWidth: 300 });

    doc.text(50, finalY + 100, "Letter No.: ", { maxWidth: 300 });
    doc.text(50, finalY + 120, "Date: ", { maxWidth: 300 });
    doc.text(50, finalY + 140, "Copy: ", { maxWidth: 300 });
    
    // doc.text(
    //   650,
    //   finalY + 160,
    //   `[${document.getElementById("selected_dist_name").innerHTML}]`
    // );
    doc.save("daily_cold_wave_report.pdf");
};


  // const downloadExcel = async (tableData, tableHeaders, fileName = "ColdWaveReport.xlsx") => {
  //   const workbook = new ExcelJS.Workbook();
  //   const worksheet = workbook.addWorksheet("Cold Wave Report");
  
  //   // Define columns based on tableHeaders
  //   worksheet.columns = tableHeaders.map((header) => ({
  //     header: header.label,
  //     key: header.key,
  //     width: 25, // Adjust as needed
  //   }));
  
  //   // Process data and replace null or undefined with "NA"
  //   const processedData = tableData.map((row, index) => {
  //     const processedRow = { slno: index + 1 }; // Add Sl. No dynamically
  
  //     // Replace null or undefined with "NA" for all other keys
  //     tableHeaders.forEach((header) => {
  //       if (header.key !== "slno") { // Skip slno column
  //         processedRow[header.key] = row[header.key] != null ? row[header.key] : "NA";
  //       }
  //     });
  //     return processedRow;
  //   });
  
  //   // Add rows to the worksheet
  //   processedData.forEach((row) => worksheet.addRow(row));
  
  //   // Style header row
  //   worksheet.getRow(1).eachCell((cell) => {
  //     cell.font = { bold: true };
  //     cell.alignment = { horizontal: "center", vertical: "middle" };
  //   });
  
  //   // Generate and save the Excel file
  //   try {
  //     const buffer = await workbook.xlsx.writeBuffer();
  //     const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = fileName;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error("Failed to export Excel file:", error);
  //   }
  // };
  
  
  
  
  const getDistrictData = async () => {
    try {
      const distList = await getData(findRegionsByUser);

      if (Array.isArray(distList) && distList.length > 0) {
        let blockdata = await getBlockListByDistId(distList[0].id);
        setSlctdBlkId(0);
        let blockIdList = [];
        blockdata.forEach((v) => blockIdList.push(v.id));
        setDistrictData(distList);
        getDailyReportData(blockIdList,true);
        setSelectedDistrictId(distList[0].id);
      } else {
        console.error("Error: District data is empty or not an array");
      }
    } catch (error) {
      console.error("Error fetching district list:", error);
    }
  };

  useEffect(() => {
    document.getElementById("flood_date_picker").valueAsDate = new Date();
    getDistrictData();
  }, []);

  const getFormattedDate = (dateStr) => {
    return <Moment format="DD/MM/YYYY">{dateStr}</Moment>;
  };

  const getBlockListByDistId = async (distId) => {
    try {
      const blockList = await postDataWithBody(
        getChildRegionsById,
        [distId],
        {}
      );
      blockList.forEach(function (elem, index) {
        elem["value"] = elem["name"];
      });
      blockList.unshift({ id: 0, value: "All" });
      setBlockData(null);
      setTimeout(function () {
        setBlockData(blockList);
      }, 100);
      return blockList;
    } catch (error) {
      console.error("Error fetching block list:", error);
    }
  };
  async function getDistrictDataByCode(event) {
    setSelectedDistrictName(event.target.value);
    setSelectedDistrictId(event.target.value);
    getBlockListByDistId(event.target.value);
  }
  function setSelectedBlockIdVal(event) {
    setSlctdBlkId(event.target.value);
  }
  const changeSelectedate = () => {
    /*if (selectedDistrictId && selectedDate) {
      getDailyReportData(selectedDistrictId, selectedDate);
    } else {
      console.error("Please select both district and date.");
    }*/
  };

  const handleTogglePopup = () => {
    setPopUp(!popUp);
  };

  const onClickSucesshandler = async () => {
    await postDataWithBody(cleardailyReportData + clearId, {}, {});
    setPopUp(false);
  };
  console.log("DISTRICT:",districtData);

  // const tableHeaders = [
  //   { label: "Sl. No", key: "slno" },
  //   { label: "District Name", key: "district" },
  //   { label: "Number of Bonfire Places (Today)", key: "number_of_bonfire_places_today" },
  //   { label: "Number of Bonfire Places (Total)", key: "number_of_bonfire_places" },
  //   { label: "Quantity of Wood Burnt (Today)", key: "quantity_of_wood_burnt_today" },
  //   { label: "Quantity of Wood Burnt (Total)", key: "quantity_of_wood_burnt" },
  //   { label: "Population Affected by Cold Wave (Today)", key: "pop_affected_by_cold_wave_today" },
  //   { label: "Population Affected by Cold Wave (Total)", key: "pop_affected_by_cold_wave" },
  //   { label: "Number of Deaths (Today)", key: "number_of_deaths_today" },
  //   { label: "Number of Deaths (Total)", key: "number_of_deaths" },
  //   { label: "Amount Allocated by Department", key: "amount_allocated_by_department" },
  //   { label: "Updated Amount Spent", key: "updated_amount_spent" },
  //   { label: "Number of Night Shelters (Today)", key: "number_of_night_shelters_today" },
  //   { label: "Number of Night Shelters (Total)", key: "number_of_night_shelters" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Today)", key: "number_of_ppl_taking_shelter_in_night_shelters_today" },
  //   { label: "Number of People Taking Shelter in Night Shelters (Total)", key: "number_of_ppl_taking_shelter_in_night_shelters" },
  //   { label: "Number of Blankets Distributed (Today)", key: "number_of_blankets_distributed_today" },
  //   { label: "Number of Blankets Distributed (Total)", key: "number_of_blankets_distributed" },
  // ];
  
  

  return (
    <div class="flood__report--container">
      {popUp && (
        <Confirmpopup
          show={popUp}
          handleToggle={handleTogglePopup}
          title="Confirmation"
          message="Are You sure to clear"
          cancel="No"
          success="Yes"
          onClickSucesshandler={onClickSucesshandler}
        />
      )}
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="ps-2 d-flex">
        <div className="me-2 region_type_container">
          <label className="mb-2">{t("floodreport.district")}</label>
          <div>
            <SelectComponent
              id="district_drop"
              value={selectedDistrictId}
              placeholder="All District"
              options={districtData}
              onChange={getDistrictDataByCode}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
            />
          </div>
        </div>

        {/* <div className="me-2 region_type_container">
          <label className="mb-2">Block</label>
          <SelectComponent
            id="block_drop"
            value={selectedBlockId}
            onChange={setSelectedBlockIdVal}
            placeholder="All Blocks"
            options={blockData}
          />
        </div> */}

        <div>
          <label className="mb-2">{t("floodreport.reported_on")}</label>
          <div className="date_picker_flood_container me-1">
            <DatePicker
              selected={selectedDate}
              maxDate={new Date().toISOString().split("T")[0]}
              name="flood_date_picker"
              onChange={(date) => {
                console.log("Selected date:", date);
                setSelectedDate(date);
              }}
              className="form-control date-picker date_picker_flood"
            />
          </div>
        </div>
        <button
          className="btn btn-success ms-2 btn-sm flood__report--button"
          onClick={() => getDailyReportData(null)}>
          {t("floodreport.buttonapply")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={downloadPDF}>
          {t("floodreport.download")}
        </button>
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => downloadExcel(dailyReport?.dr_cold_wave, tableHeaders)}>
          {"Export"}
        </button> */}
        {/* <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => editFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}>
          {t("floodreport.edit_btl_lbl")}
        </button>
        <button
          className="btn btn-success btn-sm flood__report--button"
          onClick={() => clearFloodData()}
          disabled={rolName !== process.env.REACT_APP_SUPER_ADMIN}>
          {t("floodreport.clear_btl_lbl")}
        </button> */}
      </div>
      <div className="pe-3 overflow-scroll" ref={pdfRef}>
        <div className="text-center fw-bold d-none">
          Daily Flood Report Dated on ({getFormattedDate(selectedDate)})
        </div>
        <table id="report_table" className="flood__report--table" border="2" style={{width: "200%"}} >
  <thead>
    <tr>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "1%" }}>{t("coldwavereport.slno")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.district_name")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.number_of_bonfire_places_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.number_of_bonfire_places_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.quantity_wood_burnt_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.quantity_wood_burnt_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.population_affected_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.population_affected_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.deaths_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.deaths_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.amount_allocated")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.amount_spent_updated")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.night_shelters_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.night_shelters_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.people_in_shelter_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.people_in_shelter_total")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.blankets_distributed_reporting")}</td>
      <td style={{ verticalAlign: "top", textAlign: "center", width: "5%" }}>{t("coldwavereport.blankets_distributed_total")}</td>
    </tr>
  </thead>
  <tbody>
    {dailyReport?.dr_cold_wave?.map((report, index) => (
      <tr style={{ height: "30pt" }} key={index}>
        <td>{index + 1}</td> {/* Sl. No */}
        {/* <td>{districtData.find(x => x.id == selectedDistrictId)?.value || 'NA'}</td> District name */}
        <td id = "selected_dist_name">{report.district || ''}</td> {/* District name */}
        <td>{report.number_of_bonfire_places || "NA"}</td>
        <td>{report.number_of_bonfire_places_so_far || "NA"}</td>
        <td>{report.quantity_of_wood_burnt || "NA"}</td>
        <td>{report.quantity_of_wood_burnt_so_far || "NA"}</td>
        <td>{report.pop_affected_by_cold_wave || "NA"}</td>
        <td>{report.pop_affected_by_cold_wave_so_far || "NA"}</td>
        <td>{report.number_of_deaths || "NA"}</td>
        <td>{report.number_of_deaths_so_far || "NA"}</td>
        <td>{report.amount_allocated_by_department || "NA"}</td>
        <td>{report.updated_amount_spent || "NA"}</td>
        <td>{report.number_of_night_shelters || "NA"}</td>
        <td>{report.number_of_night_shelters_so_far || "NA"}</td>
        <td>{report.number_of_ppl_taking_shelter_in_night_shelters || "NA"}</td>
        <td>{report.number_of_ppl_taking_shelter_in_night_shelters_so_far || "NA"}</td>
        <td>{report.number_of_blankets_distributed || "NA"}</td>
        <td>{report.number_of_blankets_distributed_so_far || "NA"}</td>
      </tr>
    ))}
  </tbody>
</table>


      </div>
    </div>
  );
};

export default DailyColdWaveReportForm;
