import React, { useState, useEffect } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ExpandableTable.css";
import Sortdownarrow from "../../IconComponents/Sortdownarrow";
import Sortuparrow from "../../IconComponents/Sortuparrow";
import Sortingarrow from "../../IconComponents/Sortingarrow";
import TableHeader from "./TableHeader/TableHeader";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

// Default filter UI for a column
const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  return (
    <input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // undefined to remove the filter entirely
      }}
      placeholder={`Filter...`}
      style={{
        width: "100%",
        padding: "5px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        marginTop: "5px",
      }}
    />
  );
};

const Expandaletable = (props) => {
  const {
    columns,
    data,
    numberOfRows,
    headerTableHeadStyle = {},
    tableStyle = {},
    showRowCount = true,
    rowCount,
    tabChanger = false,
    activeTab,
    setActiveTab,
    searchData,
    setSearchData = () => {},
    enableColumnFilters = false,
    disbalePaginationFilter = false,
    disbalePaginationFilter1 = false,
    disbalePaginationFilter2 = false,
  } = props;

  const [expandedRowIds, setExpandedRowIds] = useState({});

  const toggleRowExpansion = (rowId) => {
    setExpandedRowIds((prev) => {
      const isCurrentlyExpanded = !!prev[rowId];
      return isCurrentlyExpanded ? {} : { [rowId]: true };
    });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    rows,
    state: { pageIndex, globalFilter, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: { pageIndex: 0, pageSize: numberOfRows },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { t } = useTranslation();

  const translateNumber = (number) => {
    const numberString = number.toString();
    return i18n.t(`numbers.${numberString}`, { defaultValue: numberString });
  };

  let tempRowCount = globalFilter ? rows.length : rowCount || data.length;
  let tempPageIndex = pageIndex;
  let tempPageSize = pageSize;

  if (tempPageIndex === undefined || tempPageIndex === null) {
    tempPageIndex = 0;
  }
  if (tempRowCount === undefined || tempRowCount === null) {
    tempRowCount = 0;
  }
  if (tempPageSize === undefined || tempPageSize === null) {
    tempPageSize = 10;
  }

  const startIndex = tempPageIndex * tempPageSize + 1 || 1;
  const endIndex =
    Math.min((tempPageIndex + 1) * tempPageSize, tempRowCount) || tempRowCount;

  const translatedStartIndex = startIndex;
  const translatedEndIndex = endIndex;
  const translatedRowCount = tempRowCount;

  useEffect(() => {
    setPageSize(numberOfRows);
  }, [numberOfRows]);

  useEffect(() => {
    if (globalFilter) {
      const pageData = page?.map((row) => row.original);
      setSearchData(pageData ? pageData : []);
    } else {
      const pageData = rows?.map((row) => row.original);
      setSearchData(pageData ? pageData : []);
    }
  }, [globalFilter, rows]);

  const renderExpandedRow = (row) => {
    const userTaskMap = row.original.userTaskMapList || [];

    return (
      <tr>
        <td colSpan="6">
          <div
            style={{
              width: "85%",
              border: "1px solid white",
              borderRadius: "10px",
              marginTop: "10px",
              marginLeft: "80px",
              marginBottom: "10px",
              border: "1px solid #ddd",
            }}
          >
            <table
              style={{
                height: "5%",
                borderCollapse: "collapse",
                position: "relative",
              }}
            >
              <thead style={{ position: "sticky" }}>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      color: "white",
                    }}
                  >
                    {t("Assign To")}
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      color: "white",
                    }}
                  >
                    {t("Region")}
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      color: "white",
                    }}
                  >
                    {t("Action Message")}
                  </th>              
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      color: "white",
                    }}
                  >
                    {t("Updated On")}
                  </th>
                  <th
                    style={{
                      padding: "8px",
                      textAlign: "left",
                      borderBottom: "1px solid #ddd",
                      color: "white",
                    }}
                  >
                    {t("Status")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {userTaskMap.map((task) => (
                  <tr key={task.id}>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {task.assigned_to || t("NA")}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {t("NA")}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {task.action_message || t("NA")}
                    </td>
                    <td
                      style={{
                        padding: "8px",
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {task.updated_on
                        ? new Date(task.updated_on).toLocaleString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })
                        : t("NA")}
                    </td>
                    <td
                      style={{
                        padding: "10px",
                        textAlign: "left",
                        borderBottom: "1px solid gray",
                      }}
                    >
                      {task.status ? (
                        task.status === "New-Task" ? (
                          <div className="inactivestatus">
                            <div id="inactive_dot"></div>
                            <div>{t("New Task")}</div>
                          </div>
                        ) :
                         task.status ===
                          "In-Progress" ? (
                          <div className="inprogressstatus">
                            <div id="progress_dot"></div>
                            <div>{t("In-Progress")}</div>
                          </div>
                        ) : task.status ===
                          "Completed" ? (
                          <div className="activestatus">
                            <div id="active_dot"></div>
                            <div>{t("completed")}</div>
                          </div>
                        ) : (
                          t("NA")
                        )
                      ) : (
                        t("NA")
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="tabl_container">
      <TableHeader
        {...props}
        searchValue={globalFilter || ""}
        onSearchHandler={setGlobalFilter}
      />
      <div className="iccc__table--container" style={tableStyle}>
        <table {...getTableProps()} className="table iccc--table">
          <thead>
            <tr>
              <th
                style={{
                  width: "30px",
                  textAlign: "left",
                  color: "white",
                  backgroundColor: "#18a3a0",
                }}
              >
                {t("")}
              </th>
              {headerGroups.map((headerGroup, index) => (
                <React.Fragment key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      style={headerTableHeadStyle}
                      key={index}
                    >
                      {column.render("Header")}
                      {!column.disableSortBy &&
                        (column.isSorted ? (
                          column.isSortedDesc ? (
                            <Sortdownarrow />
                          ) : (
                            <Sortuparrow />
                          )
                        ) : (
                          <Sortingarrow />
                        ))}
                      {enableColumnFilters && column.canFilter && (
                        <div>{column.render("Filter")}</div>
                      )}
                    </th>
                  ))}
                </React.Fragment>
              ))}
            </tr>
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                const isExpanded = expandedRowIds[row.id];

                return (
                  <React.Fragment key={row.id}>
                    <tr {...row.getRowProps()}>
                      <td style={{ textAlign: "left" }}>
                        <span
                          onClick={() => toggleRowExpansion(row.id)}
                          style={{ cursor: "pointer", fontSize: "18px" }}
                        >
                          {isExpanded ? "-" : "+"}
                        </span>
                      </td>
                      {row.cells.map((cell) => (
                        <td
                          {...cell.getCellProps()}
                          key={cell.column.id}
                          style={{ textAlign: "left" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      ))}
                    </tr>
                    {isExpanded && renderExpandedRow(row)}
                  </React.Fragment>
                );
              })
            ) : (
              <tr>
                <td colSpan={columns.length + 1} className="no-data-text">
                  No data Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        {!disbalePaginationFilter2 && (
          <div className="float-start m-2 mt-2 ms-3">
            {showRowCount && (
              <div className="float-start m-2 mt-1 ms-3 pagecount-style">
                {t("showingentriestable.showing")} {translatedStartIndex}{" "}
                {t("showingentriestable.to")} {translatedEndIndex}{" "}
                {t("showingentriestable.of")} {translatedRowCount}{" "}
                {t("showingentriestable.entries")}
              </div>
            )}
          </div>
        )}
        <div className="pagination">
          {!disbalePaginationFilter && (
            <div className="d-flex">
              <span className="pagination_gotopage">
                Go to Page:
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                  className="pagination_gotopage-input"
                />
              </span>
              <span>
                <select
                  className="pagination_select"
                  value={pageSize}
                  onChange={(e) => setPageSize(Number(e.target.value))}
                >
                  {[10, 50, 100, 500, 1000, 2000].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      show {pageSize}
                    </option>
                  ))}
                </select>
              </span>
            </div>
          )}
          {!disbalePaginationFilter1 && (
            <>
              <button
                className="pagebtn mx-2"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>
              <button className="pagination_page">
                {translateNumber(pageIndex + 1)}{" "}
              </button>
              <button
                className="pagebtn"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <span className="right_pageno">
                {t("pagination.page_of", {
                  page: translateNumber(pageIndex + 1),
                  total: translateNumber(pageOptions.length),
                })}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Expandaletable;
