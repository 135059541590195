import React, { useState } from 'react';
import './TabSwitch.css';

export default function TabSwitch({activeTab,setActiveTab}) {

  const handleTabClick = (tabName) => {
      setActiveTab(tabName);
      
  };

  return (
    <div className="tab-switch-container">
      <div
        className={`tab-item ${activeTab === 'dss' ? 'active' : ''}`}
        onClick={() => handleTabClick('dss')}
      >
        DSS
      </div>
      <div
        className={`tab-item ${activeTab === 'currentStatus' ? 'active' : ''}`}
        onClick={() => handleTabClick('currentStatus')}
      >
        Current Status
      </div>
    </div>
  );
}
