import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import "./ConfirmPopup.css";
import SvgSuccessSvg from "../../../../Components/IconComponents/SuccessSvg";
import Loader from "../../../../Components/UiComponents/Loader/Loader";
import useLoading from "../../../../Hooks/useLoading";
import lmsToast from "../CustomToastifier/CustomToastifier";
import { useSelector } from "react-redux";
import SvgRedclosebutton from "../../../../Components/IconComponents/Redclosebutton";
import { ThemeContext } from "../../../..";
import DmsButton from "../DmsButton/DmsButton";
const Confirmpopup = (props) => {
  const {
    title,
    show,
    handleToggle,
    row,
    deleteRow,
    name,
    messageComp,
    message,
    cancel,
    action,
    success,
    isSuccessPopup,
    onClickSucesshandler,
    keyboard = true,
  } = props;
  const { theme } = useContext(ThemeContext);

  return (
    <>
      {/* <Loader show={loading} /> */}
      <div className="confirm__popup">
        <Modal
          show={show}
          id={theme}
          onHide={handleToggle}
          className="table__confirm--popup"
          dialogClassName="make-it-center"
          backdrop="static"
          keyboard={keyboard}
          centered
        >
          <Modal.Header>
            <Modal.Title>
              {isSuccessPopup && (
                <span className="table_confirm__success-icon">
                  <SvgSuccessSvg width="0.9em" height="0.9em" />
                </span>
              )}

              {title}
            </Modal.Title>
            <SvgRedclosebutton
              onClick={handleToggle}
              className="cursor-pointer"
              width="1.5em"
              height="1.5em"
            />
          </Modal.Header>
          <Modal.Body>
            <div
              className={`table_confirm_body ${
                isSuccessPopup == true && "table_confirm_body__success-msg"
              }`}
            >
              {messageComp}
              <div className="table_confirm_body_msg">
                {message}
                <span className="popup__name">{name}</span>
                {action === "Delete" && <span>?</span>}
              </div>
            </div>
            <div className="table_confirm_button">
              {cancel && (
                <DmsButton
                  label={cancel}
                  size="btn_sm"
                  className="table__cancel"
                  onClick={handleToggle}
                  variant="secondary"
                />
              )}
              {action && (
                <DmsButton
                  size="btn_sm"
                  label={action}
                  className="table__confirm"
                  onClick={() => {
                    deleteRow(row?.original?.id);
                    // deleteRow &&
                    //   lmsToast({
                    //     toasterType: "success",
                    //     operationType: "delete",
                    //   });
                  }}
                />
              )}
              {success && (
                <DmsButton
                  size="btn_sm"
                  label={success}
                  className="table__confirm"
                  onClick={onClickSucesshandler}
                />
              )}
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Confirmpopup;
