import React from "react";
import UserManagement from "../Pages/DMS/UserManagement/UserManagement";
import CreateUser from "../Pages/DMS/UserManagement/CreateUser/CreateUser";
import ResourceManagement from "../Pages/DMS/ResourceManagement/ResourceManagement";
import ResourceList from "../Pages/DMS/ResourceManagement/ResourceList/ResourceList";
import RegionManagement from "../Pages/DMS/RegionManagement/RegionManagement";
import RegionList from "../Pages/DMS/RegionManagement/RegionList/RegionList";
import CreateAgency from "../Pages/DMS/AgencyManagement/CreateAgency/CreateAgency";
import AgencyManagement from "../Pages/DMS/AgencyManagement/AgencyManagement";
import AgencyTable from "../Pages/DMS/AgencyManagement/AgencyTable/AgencyTable";
import MasterDataManagement from "../Pages/DMS/MasterDataManagement/MasterDataManagement";
import CreateRegionForm from "../Pages/DMS/RegionManagement/CreateRegion/CreateRegion";
import ContactManagement from "../Pages/DMS/ContactManagement/ContactManagement";
import CreateContact from "../Pages/DMS/ContactManagement/CreateContact/CreateContact";
import ResourceManagementEntity from "../Pages/DMS/ResourceEntityManagement/ResourceManagementEntity";
import TaskManagement from "../Pages/DMS/TaskManagement/TaskManagement";
import CreateTask from "../Pages/DMS/TaskManagement/CreateTask/CreateTask";
import CreateFacility from "../Pages/DMS/FacilityManagement/CreateFacility/CreateFacility";
import FacilityManagement from "../Pages/DMS/FacilityManagement/FacilityManagement";
import MyTaskListManagement from "../Pages/DMS/MyTaskListManagement/MyTaskListManagement";
import FacilityList from "../Pages/DMS/FacilityManagement/FacilityList/FacilityList";
import MasterResourceManagement from "../Pages/DMS/MasterResourceManagement/MasterResourceManagement";
import CreateMasterResource from "../Pages/DMS/MasterResourceManagement/CreateMasterResource/CreateMasterResource";
import CreateSOP from "../Pages/DMS/SOPManagement/CreateSOP/CreateSOP";
import SOPManagement from "../Pages/DMS/SOPManagement/SOPManagement";
import RoleManagement from "../Pages/DMS/RoleManagement/RoleManagement";
import CreateRole from "../Pages/DMS/RoleManagement/CreateRole/CreateRole";
import ViewSOP from "../Pages/DMS/SOPManagement/ViewSOP/ViewSOP";
import GisManagement from "../Pages/DMS/GisManagement/GisManagement";
import ReportManagement from "../Pages/DMS/ReportManagement/FormBuilder";
import FormViewer from "../Pages/DMS/ReportManagement/FormViewer";
import FormRenderer from "../Pages/DMS/ReportManagement/FormRenderer";
import SOPExecutionManagement from "../Pages/DMS/GisManagement/SOPExecutionManagement";
import FormBuilder from "../Pages/DMS/ReportManagement/FormBuilder";
import CreateResource from "../Pages/DMS/ResourceManagement/CreateResource/CreateResource";
import IncidentManagement from "../Pages/DMS/GisManagement/IncidentManagement";
import EventManagement from "../Pages/DMS/GisManagement/EventManagement";
import ExecuteSOP from "../Pages/DMS/GisManagement/ExecuteSOP";
import DeploymentList from "../Pages/DMS/GisManagement/DeploymentList";
import DeployResource from "../Pages/DMS/GisManagement/DeployResource";
import UserTaskManagement from "../Pages/DMS/UserTaskManagement/UserTaskManagement";
import UserTaskForm from "../Pages/DMS/UserTaskManagement/UserTaskForm/UserTaskForm";
import AnimalShelter from "../Pages/DMS/Dashboard/AnimalShelter/AnimalShelter";
import CommunityKitchen from "../Pages/DMS/Dashboard/CommunityKitchen/CommunityKitchen";
import FloodReliefCamp from "../Pages/DMS/Dashboard/FloodReliefCamp/FloodReliefCamp";
import FloodReliefCenter from "../Pages/DMS/Dashboard/FloodReliefCenter/FloodReliefCenter";
import DistDashboard from "../Pages/DMS/Dashboard/StateDashboard/StateDashboard";
import DailyFloodReport from "../Pages/DMS/Dashboard/DailyFloodReport/DailyFloodReport";
import ViewDailyFloodReport from "../Pages/DMS/Dashboard/DailyFloodReport/ViewDailyFloodReport/ViewDailyFloodReport";
import DailyFloodReportComp from "../Pages/DMS/Dashboard/DailyFloodReportComp/DailyFloodReportComp";
import StateDashboard from "../Pages/DMS/Dashboard/StateDashboard/StateDashboard";
import DailyFloodReportEditableForm from "../Pages/DMS/Dashboard/DailyFloodReportEditableForm/DailyFloodReportEditableForm";
import StateFloodReport from "../Pages/DMS/Dashboard/StateFloodReport/StateFloodReport";
import Form9 from "../Pages/DMS/Dashboard/StateFloodReport/Form9/Form9";
import DailyFloodReportStatus from "../Pages/DMS/Dashboard/DailyFloodReportStatus/DailyFloodReportStatus";
import AffectedReport from "../Pages/DMS/Dashboard/AffectedReport/AffectedReport";
import PartiallyAffectedReport from "../Pages/DMS/Dashboard/PartiallyAffectedReport/PartiallyAffectedReport";
import MISDistFloodReport from "../Pages/DMS/Dashboard/MISDistFloodReport/MISDistFloodReport";
import MISStateFloodReport from "../Pages/DMS/Dashboard/MISStateFloodReport/MISStateFloodReport";
import DailyAffectedFloodReport from "../Pages/DMS/Dashboard/DailyAffectedFloodReport/DailyAffectedFloodReport";
import DailyPartiallyAffectedFloodReport from "../Pages/DMS/Dashboard/DailyPartiallyAffectedFloodReport/DailyPartiallyAffectedFloodReport";
import DMSDashboard from "../Pages/DMS/Dashboard/DMSDashboard/DMSDashboard";
// import CommunityKitchenFireReport from "../Pages/DMS/Dashboard/CommunityKitchenFireReport/CommunityKitchenFireReport";
import DailyColdWaveReport from "../Pages/DMS/Dashboard/DailyColdWaveReport/DailyColdWaveReport";
import UserActivityManagement from "../Pages/DMS/UserManagement/UserActivity/UserActivityManagement";
import HumanLossReport from "../Pages/DMS/Dashboard/HumanLossReport/HumanLossReport";
import HumanLossForm from "../Pages/DMS/Dashboard/HumanLossDetails/HumanLossForm/HumanLossForm";
// import HumanLossDetails from "../Pages/DMS/Dashboard/HumanLossDetails/HumanLossDetails";
// import HeatWaveFormatA from "../Pages/DMS/Dashboard/HeatWaveFormatA/HeatWaveFormatA";
// import HeatWaveFormatB from "../Pages/DMS/Dashboard/HeatWaveFormatB/HeatWaveFormatB";
// import HeatWaveFormatC from "../Pages/DMS/Dashboard/HeatWaveFormatC/HeatWaveFormatC";
// import FireIncidentReport from "../Pages/DMS/Dashboard/FireIncidentReport/FireIncidentReport";

export const lms_routes_regs = [
  { name: "create-user", component: <CreateUser /> },
  { name: "users", component: <UserManagement /> },
  { name: "roles", component: <RoleManagement /> },
  { name: "create-role", component: <CreateRole /> },
];

export const resource_routes_regs = [
  { name: "resource", component: <ResourceManagement /> },
  { name: "resource-list", component: <ResourceList /> },
  { name: "create-resource", component: <CreateResource /> },
];

export const masterresource_routes_regs = [
  { name: "master-resource", component: <MasterResourceManagement /> },
  { name: "create-masterresource", component: <CreateMasterResource /> },
];

export const resource_entity_routes_regs = [
  { name: "create-resourceentity", component: <ResourceManagementEntity /> },
];

export const region_routes_regs = [
  { name: "region", component: <RegionManagement /> },
  { name: "region-list", component: <RegionList /> },
  { name: "region-form", component: <CreateRegionForm /> },
];

export const agency_routes_regs = [
  { name: "agency", component: <AgencyManagement /> },
  { name: "agency-list", component: <AgencyTable /> },
  { name: "create-agency", component: <CreateAgency /> },
];

export const planning_routes_regs = [
  // { name: "planning", component: <TaskManagement /> },

  { name: "view-task", component: <TaskManagement /> },
  { name: "create-task", component: <CreateTask /> },
  { name: "view-contact", component: <ContactManagement /> },
  { name: "create-contact", component: <CreateContact /> },
  { name: "view-sop", component: <SOPManagement /> },
  { name: "create-sop", component: <CreateSOP /> },
  { name: "view-sopinfo", component: <ViewSOP /> },
];

export const masterdatamgmnt_routes_regs = [
  { name: "masterdata-table", component: <MasterDataManagement /> },
  {
    name: "event-category",
    component: <MasterDataManagement configType={"EVNT_CAT"} />,
  },
  {
    name: "region-type",
    component: <MasterDataManagement configType={"REGION_TYP"} />,
  },
  {
    name: "resource-category",
    component: <MasterDataManagement configType={"RSRC_CAT"} />,
  },
  {
    name: "resource-kind",
    component: <MasterDataManagement configType={"RSRC_KND"} />,
  },
  {
    name: "agency-type",
    component: <MasterDataManagement configType={"AGNC_TYP"} />,
  },
  {
    name: "incident-type",
    component: <MasterDataManagement configType={"INCDNT_TYP"} />,
  },
  {
    name: "incident-status",
    component: <MasterDataManagement configType={"INCDNT_STAT"} />,
  },
  {
    name: "tasks-tatus",
    component: <MasterDataManagement configType={"TSK_STAT"} />,
  },
  {
    name: "facilities-type",
    component: <MasterDataManagement configType={"FCLTY_TYP"} />,
  },
];

export const facility_routes_regs = [
  { name: "facility-mgmt", component: <FacilityManagement /> },
  { name: "facility-list", component: <FacilityList /> },
  { name: "create-facility", component: <CreateFacility /> },
];
export const my_tasks_regs = [
  { name: "my-tasks", component: <MyTaskListManagement /> },
  { name: "view-usertask", component: <UserTaskManagement /> },
  { name: "create-usertask", component: <UserTaskForm /> },
];

export const my_gis_mgmnt_regs = [
  { name: "gismanagement", component: <GisManagement /> },
  { name: "map-view", component: <GisManagement /> },
  { name: "response-relief", component: <DeploymentList /> },
  { name: "event-mgmnt", component: <EventManagement /> },
  { name: "incident-mgmnt", component: <IncidentManagement /> },
];

export const my_sop_mgmnt_regs = [
  { name: "sopexecution", component: <SOPExecutionManagement /> },
  { name: "sop-instances", component: <SOPExecutionManagement /> },
  { name: "sop", component: <SOPExecutionManagement /> },
  { name: "execute-sop", component: <ExecuteSOP /> },
  { name: "resource-list", component: <DeploymentList /> },
  { name: "deploy-resource", component: <DeployResource /> },
];
export const my_report_mgmnt_regs = [
  { name: "reportmanagement", component: <ReportManagement /> },
  { name: "create-form", component: <FormBuilder /> },
  {name: "user-activity", component: <UserActivityManagement />},
];
export const form_viewers = [
  { name: "report-view", component: <FormViewer /> },
  { name: "create-form-view", component: <FormRenderer /> },
];

export const dmsdashboard = [
  { name: "dmsdashboard", component: <DMSDashboard /> },
  { name: "animal-shelter", component: <AnimalShelter /> },
  { name: "community-kitchen", component: <CommunityKitchen /> },
  { name: "fl-center", component: <FloodReliefCenter /> },
  { name: "daily-flood-report", component: <DailyFloodReport /> },
  { name: "view-daily-flood-report", component: <ViewDailyFloodReport /> },
  { name: "fl-camp", component: <FloodReliefCamp /> },
  { name: "dailyfloodreport", component: <DailyFloodReportComp /> },
  { name: "affected-floodreport", component: <DailyAffectedFloodReport /> },
  { name: "partiallyaffected-floodreport", component: <DailyPartiallyAffectedFloodReport /> },
    { name: "reporting-status", component: <DailyFloodReportStatus /> },
  { name: "misdist-report", component: <MISDistFloodReport /> },
  { name: "misstate-report", component: <MISStateFloodReport /> },

  { name: "dist-dashboard", component: <StateDashboard /> },
  {
    name: "editdfr",
    component: <DailyFloodReportEditableForm />,
  },
  { name: "statefloodreport", component: <StateFloodReport /> },
  { name: "form9", component: <Form9 /> },
  { name: "affected-report", component: <AffectedReport /> },
  { name: "partiallyaffected-report", component: <PartiallyAffectedReport /> },
  // {name: "communitykitchen-fire-report", component: <CommunityKitchenFireReport />},
  { name: "dr-cold-wave", component: <DailyColdWaveReport /> },
  {name: "human-loss-report", component:<HumanLossReport />},
  // { name: "human-loss-details", component: <HumanLossDetails /> },
  { name: "human-loss-form", component: <HumanLossForm /> },
  // {name: "heat-wave-A-report", component: <HeatWaveFormatA />},
  // {name: "heat-wave-B-report", component: <HeatWaveFormatB />},
  // {name: "heat-wave-C-report", component: <HeatWaveFormatC />},
  // { name: "fireincident-report", component: <FireIncidentReport/> },
];
