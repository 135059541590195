import React from "react";
import Modal from "react-bootstrap/Modal";
import XCircleblack from "../../IconComponents/XCircleblack";
import { postDataWithBody,getData } from "../../../Services/Services";
import { completeTask} from "../../../Services/EndPoints";
import { toast, ToastContainer } from "react-toastify";
import { useKeycloak } from "@react-keycloak/web";

const ModalResourceType = (props) => {
  const { title, titleValue, show, handleToggle, size, popupLabel,buttonLabel,data } = props;
  console.log("update value=",data);

  const { keycloak } = useKeycloak();
  async function saveConfigData(){
    let comment = document.getElementById("modal_comment_txt_area").value;
    let input = { "task_id":props.taskData.task_id,"description":comment,"sop_instance_id": props.taskData.id,"user_name":keycloak.idTokenParsed.email };
    var res = await postDataWithBody(completeTask, input, {});
    if(res.status == "success"){
      props.showSuccessMsg();
      toast.success(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
    else{
      toast.error(res.msg, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000, // Close after 2 seconds
      });
    }
  }
  return (
    <>
      <Modal
        show={show}
        onHide={handleToggle}
        size={size}
        className="table__modal__popup"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            {title}
            {titleValue}
          </Modal.Title>
          <XCircleblack
            className="cursor-pointer"
            onClick={handleToggle}
            width="1.5em"
            height="1.5em"
          />
        </Modal.Header>
        <Modal.Body>
          <div className="create__resource--popup">
            <div class="form-group">
              <label
                className="create__resource--label"
                for="tfld_config_value"
              >
                {popupLabel}
              </label>
              <textarea type="text" class="form-control" id="modal_comment_txt_area" value={data}></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={saveConfigData}
            type="button"
            className="btn btn-success"
            style={{ backgroundColor: "#40B2B7" }}
          >
           {buttonLabel}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalResourceType;
