export const commmunityKitchen = [
  {
    label: "Total Meals Served",
    value: "1024",
    keyName: "Total Meals served",
  },
  {
    label: "Breakfast",
    value: "1024",
    keyName: "Breakfast",
  },
  {
    label: "Lunch",
    value: "1024",
    keyName: "Lunch",
  },
  {
    label: "Dinner",
    value: "1024",
    keyName: "Dinner",
  },
];



export const healthCamp = [
  {
    label: "Bleaching Powder",
    value: "525",
    keyName: "Amount of bleaching powder sprinkled in KG",
  },
  {
    label: "Halogen Tablets",
    value: "1024",
    keyName: "Number of halogen tablets distributed",
  },
  {
    label: "No of People Treated",
    value: "150",
    keyName: "Number of people treated",
  },
];
