import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../../../Components/UiComponents/Table/Table";
import { Editicon, Deleteicon } from "../../../../Components/IconComponents";
import {
  facilityTableData,
  facilitycolumnsForExport,
} from "../../../../JsonData/CreateFacilityConfig";
import { getData, putDataWithBody } from "../../../../Services/Services";
import {
  getFacilities,
  deleteFacilityURL,
} from "../../../../Services/EndPoints";
import Moment from "react-moment";
import { useDispatch } from "react-redux";
import { getExportedColumnsData } from "../../../../Components/UiComponents/utils";
import { setTableData } from "../../../../CentralStore/ExportLMSTable/dataSlice";
import { toast, ToastContainer } from "react-toastify";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import StatusHandler from "../../Common/StatusHandler/StatusHandler";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";
import { RoleContext } from "../../../..";
import TextEllipsis from "../../../../Components/UiComponents/TextEllipsis/TextEllipsis";

const FacilityList = () => {
  const { roleName } = useContext(RoleContext);
  const { t } = useTranslation();
  const [data, setData] = useState(facilityTableData);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getFacilityList();
  }, []);

  useEffect(() => {
    sendDataToCentralStore(searchData);
  }, [searchData]);

  const getFacilityList = async () => {
    setLoader(true);
    var facilityList = await getData(getFacilities);
    let sortedList = facilityList.sort((a, b) => {
      return new Date(b.created_on) - new Date(a.created_on);
    });

    setLoader(false);
    setData(sortedList);
    setRowCount(sortedList.length);
    // sendDataToCentralStore(facilityList);
  };
  const addNewHandler = () => {
    navigate("/facility-mgmt/create-facility");
    // localStorage.removeItem("DriverRegForm");
  };

  const sendDataToCentralStore = (data) => {
    if (data.length > 0) {
      const columns = facilitycolumnsForExport;
      dispatch(
        setTableData({
          columns,
          tableData: getExportedColumnsData(columns, data),
        })
      );
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("facilitytableheaders.name"),
        accessor: "name",
        disableSortBy: true,
        Cell: (props) => <TextEllipsis text={props?.value} maxLength={20} />,
      },
      {
        Header: t("facilitytableheaders.address"),
        accessor: "address",
        Cell: (props) => {
          if (props.value) {
            let addressText = props.value;
            if (props.value.length > 15) {
              addressText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{addressText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      {
        Header: t("facilitytableheaders.district"),
        accessor: "dist_name",
        disableSortBy: true,
      },
      {
        Header: t("facilitytableheaders.block"),
        accessor: "block_name",
        disableSortBy: true,
      },
      {
        Header: t("facilitytableheaders.description"),
        accessor: "description",

        Cell: (props) => {
          if (props.value) {
            let descriptionText = props.value;
            if (props.value.length > 15) {
              descriptionText = props.value.substring(0, 15) + "...";
            }
            return <span title={props.value}>{descriptionText}</span>;
          } else {
            return "NA";
          }
        },
        disableSortBy: true,
      },
      // {
      //   Header: t("facilitytableheaders.createdon"),
      //   accessor: "created_on",
      //   Cell: (props) => {
      //     if (props.value) {
      //       return <Moment format="DD/MM/YYYY, HH:mm ">{props.value}</Moment>;
      //     } else {
      //       return "NA";
      //     }
      //   },
      //   disableSortBy: true,

      //   // Cell: (props) => <TextEllipsis text={props?.value} maxLength={15} />,
      // },

      {
        Header: t("facilitytableheaders.facilitytype"),

        accessor: "facility_type",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <div>{props.value}</div>;
          } else {
            return "NA";
          }
        },
      },
      {
        Header: t("facilitytableheaders.contactlist"),
        accessor: "contact_list",
        disableSortBy: true,
        Cell: (props) => {
          if (props.value) {
            return <TextEllipsis text={props.value} maxLength={20} />;
          } else {
            return "NA";
          }
        },
      },

      {
        Header: t("facilitytableheaders.action"),
        accessor: "actions",
        disableSortBy: true,
        disableFilters: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const [isEditMode, setIsEditMode] = useState(true);
          const isFacility = props.row?.original?.facility_type || ""; // Default to empty string if undefined
 
          const disableDeleteValues = [
            "Community Kitchen",
            "Flood Relief Camp",
            "Animal Shelter",
          ];
 
          // Check if isFacility exists and then apply .includes()
          const isDeleteDisabled =
            isFacility &&
            disableDeleteValues.some((value) => isFacility.includes(value));
 
          const deletePopUp = () => {
            setIsdelete(!isdelete);
          };
 
          const deleteTableDataById = async (id) => {
            let data = { id: id };
            var res = await putDataWithBody(deleteFacilityURL, data, {});
            if (res.status === "success") {
              getFacilityList();
            }
            toast.success(res.msg, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 2000, // Close after 2 seconds
            });
            setIsdelete(false);
          };
 
          const isMultiSelectDisabled = isEditMode;
 
          const handleEditClick = () => {
            setIsEditMode(false); // Enable edit mode (disable MultiSelectWrapper)
          };
 
          return (
            <div className="usertable__actionsicon">
              <span
                className="ms-3"
                onClick={() =>{
                  navigate("/facility-mgmt/create-facility", {
                    state: {
                      data: props.row.original.id,
                      isMultiSelectDisabled: isMultiSelectDisabled,
                    },
                  });
                  handleEditClick();
                }}>
                <Editicon value={props.value} className="cursor-pointer me-2" />
              </span>
              {roleName === process.env.REACT_APP_SUPER_ADMIN &&
                !isDeleteDisabled && (
                  <span onClick={deletePopUp}>
                    <Deleteicon
                      value={props.value}
                      className="cursor-pointer mr-1"
                    />
                  </span>
                )}
 
              {isdelete && (
                <Confirmpopup
                  className="DeltePopup"
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={deletePopUp}
                  row={props.row}
                  name={props.row.original.name}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>
          );
        },
      },
    ],
    [t, roleName]
  );

  return (
    <div className="create-user">
      <Loader
        show={loader}
        type="body"
        stack="vertical"
        message="Loading Data"
      />
      <div className="datatable__container">
        {facilityTableData && (
          <Table
            data={data}
            title=""
            showFilter={false}
            columns={columns}
            numberOfRows={10}
            addButtonLabel={t("facilityaddButtonLabel")}
            addNewHandler={addNewHandler}
            setSearchData={setSearchData}
            showExport={false}
            showReset={false}
            onExpand={false}
            showSearch={true}
            isHeaderFixed={true}
            rowCount={rowCount} // Pass rowCount to the Table component
            enableColumnFilters={true}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              borderRadius: "12px",
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FacilityList;
