import React, { useEffect } from 'react'


const GISResourceManagement= () => {
    useEffect(()=>{
        window.location.href = process.env.REACT_APP_GIS_SERVICE+"/resourceallocationlist"
    })
  return (
    <div>Loading...</div>
  )
}

export default GISResourceManagement