let context = {};

export const setDataTocontext = (key, value) => {
  context[key["name"]] = value;
};

export const getContextForkey = (key) => {
  return context[key];
};

export const getContext = () => {
  return context;
};

export const deletContext = (keys) => {
  keys.array.forEach((key) => {
    delete context[key];
  });
};

export const cleanContext = () => {
  context = {};
};
