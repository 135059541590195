import React, { useEffect, useCallback, useRef, useState } from "react";
import GridItem from "../../../../Components/UiComponents/GridItem/GridItem";
import { GridStack } from "gridstack";
import { useTranslation } from "react-i18next";
import DMSHeader from "../../DMSHeader/DMSHeader";
import DailyFloodReportTable from "./DailyFloodReportTable";
import ViewDailyFloodReportHeader from "./ViewDailyFloodReport/ViewDailyFloodReportHeader";
const DailyFloodReport = () => {
  const { t, i18n } = useTranslation();
  const [selectedValues, setSelectedValues] = useState(null);
  const viewHeaderRef = useRef();
  useEffect(() => {
    GridStack.init();
  }, []);

  // const setRef = useCallback((node) => {
  //   if (node !== null) {
  //     viewHeaderRef.current = node;
  //   }
  // }, []);

  const handleSelectedValues = useCallback((values) => {
    setSelectedValues(values);
  }, []);

  useEffect(() => {
    if (viewHeaderRef.current && viewHeaderRef.current.selectedValuesRef) {
      setSelectedValues(viewHeaderRef.current.selectedValuesRef.current);
    }
  }, []);
  const layoutData = [
    {
      x: 0,
      y: 0,
      width: 12,
      height: 1,
      id: 1,
      component: (
        <DMSHeader
          title={t('daily_flood_header')}
          fileName="DailyFloodReport.csv"
          showdownload={true}
        />
      ),
      haveOverflowVisible: true,
    },
    /*{
      x: 0,
      y: 1,
      width: 12,
      height: 3,
      id: 1,
      component: (
        <ViewDailyFloodReportHeader
          // setRef={setRef}
          handleSelectedValues={handleSelectedValues}
        />
      ),
      haveOverflowVisible: true,
    },*/
    {
      x: 0,
      y: 2,
      width: 12,
      height: 13,
      id: 2,
      component: <DailyFloodReportTable selectedValues={selectedValues} />,
    },
  ];

  return (
    <div className="grid-stack grid-stack-instance-1 smartpole__header--transition">
      <GridItem layoutData={layoutData} />
    </div>
  );
};

export default DailyFloodReport;
