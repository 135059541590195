import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Table from "../../../../Components/UiComponents/Table/Table";
import {
  deleteDataById,
  postDataWithBody,
} from "../../../../Services/Services";
import {
  deleteTaskById,
  getMatserUserTaskList,
} from "../../../../Services/EndPoints";
import Loader from "react-spinner-loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Deleteicon } from "../../../../Components/IconComponents";
import Confirmpopup from "../../Common/ConfirmPopup/Confirmpopup";
import { useKeycloak } from "@react-keycloak/web";
import Expandaletable from "../../../../Components/UiComponents/Table/ExpandableTable";

const UserTaskTable = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [userTaskData, setUserTaskData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [isTextFieldDisable, setIsTextFieldDisable] = useState(true);
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();
  const { keycloak } = useKeycloak();

  const addNewHandler = () => {
    navigate("/my-tasks/create-usertask");
  };

  useEffect(() => {
    getTaskList();
  }, []);

  const getLoggedInuserName = () => {
    return keycloak.idTokenParsed.email;
  };

  const getTaskList = async () => {
    setLoader(true);
    try {
      const masterUserTaskListResponse = await postDataWithBody(
        getMatserUserTaskList,
        { email: getLoggedInuserName() },
        {}
      );
      const masterUserTaskList = masterUserTaskListResponse || [];

      masterUserTaskList.sort(
        (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
      );

      setUserTaskData(masterUserTaskList);
      setRowCount(masterUserTaskList.length);
    } catch (error) {
      console.error("Error fetching task list:", error);
      toast.error(t("fetch_error_message"), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
    } finally {
      setLoader(false);
    }
  };

  const handleClickView = () => {
    setIsTextFieldDisable(false);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: t("dailytaskmgmnttableheaders.name"),
        accessor: "name",
        disableSortBy: true,
      },
      {
        Header: t("dailytaskmgmnttableheaders.tasktype"),
        accessor: "task_type",
        disableSortBy: true,
        Cell: (props) => (props.value === 0 ? "Activity" : "Notification"),
      },
      {
        Header: t("dailytaskmgmnttableheaders.assigntype"),
        accessor: "assign_type",
        disableSortBy: true,
        Cell: (props) => (props.value === 0 ? "Group" : "Users"),
      },
      {
        Header: t("dailytaskmgmnttableheaders.createdon"),
        accessor: "createdOn",
        disableSortBy: true,
        Cell: (props) => (
          <Moment format="DD/MM/YYYY, hh:mm a">{props.value}</Moment>
        ),
      },
      {
        Header: t("dailytaskmgmnttableheaders.action"),
        disableSortBy: true,
        Cell: (props) => {
          const [isdelete, setIsdelete] = useState(false);
          const [selectedId, setSelectedId] = useState(null);
      
          const deletePopUp = (id) => {
            setSelectedId(id);
            setIsdelete(!isdelete);
          };

          const deleteTableDataById = async () => {
            try {
              const response = await deleteDataById(deleteTaskById, selectedId);
              if (response?.status === "success") {
                await getTaskList();
                toast.success(
                  response.msg || t("delete_success_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              } else {
                toast.error(
                  response.msg || t("delete_failure_message"),
                  {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                  }
                );
              }
            } catch (error) {
              toast.error(t("delete_error_message"), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
              });
              console.error("Delete error:", error);
            } finally {
              setIsdelete(false);
              setSelectedId(null);
            }
          };


          return (
            <div style={{ alignItems: "center" }}>
              <span
                onClick={() => {
                  navigate("/my-tasks/create-usertask", {
                    state: {
                      data: props.row.original,
                      isTextFieldDisable: isTextFieldDisable,
                    },
                  });
                  handleClickView();
                  console.log("Row Data:", props.row.original);
                }}
                style={{ cursor: "pointer", marginRight: "10px" }}
              >
                <i className="fa fa-eye"></i>
              </span>
              <span onClick={() => deletePopUp(props.row.original.id)}>
                <Deleteicon />
              </span>
              {isdelete && (
                <Confirmpopup
                  show={isdelete}
                  title={t("deletepopuptext")}
                  message={t("deletepopupmessage")}
                  handleToggle={() => deletePopUp(null)}
                  deleteRow={deleteTableDataById}
                  cancel={t("cancelbutton")}
                  action={t("actionbutton")}
                />
              )}
            </div>

          );
        },
      }
      
    ],
    [t, isTextFieldDisable, navigate]
  );

  const handleRowExpand = (row) => {
    row.toggleRowExpanded();
  };

  return (
    <div>
      <Loader show={loader} type="body" message="Loading Data" />
      <div className="container create-user">
        <div className="datatable__container">
          <Expandaletable
            data={userTaskData}
            addButtonLabel={t("dailytaskaddbuttonlabel")}
            addNewHandler={addNewHandler}
            columns={columns}
            numberOfRows={7}
            showExport={false}
            showFilter={false}
            showReset={false}
            onExpand={false}
            rowCount={rowCount}
            headerTableHeadStyle={{ background: "#18a3a0", color: "#FFFFFF" }}
            tableStyle={{
              border: "1px solid #f0f0f0",
              borderRadius: "12px",
            }}
            // expandedRowRender={(row) => {
            //   const taskDetails = row.original.userTaskMapList;
            //   return (
            //     <div style={{ padding: "10px" }}>
            //       {taskDetails && taskDetails.length > 0 ? (
            //         <table style={{ width: "100%", borderCollapse: "collapse" }}>
            //           <thead>
            //             <tr style={{ backgroundColor: "#f5f5f5" }}>
            //               <th style={{ padding: "8px", textAlign: "left" }}>{t("Assign To")}</th>
            //               <th style={{ padding: "8px", textAlign: "left" }}>{t("Action Message")}</th>
            //             </tr>
            //           </thead>
            //           <tbody>
            //             {taskDetails.map((task) => (
            //               <tr key={task.id}>
            //                 <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
            //                   {task.assigned_to || t("NA")}
            //                 </td>
            //                 <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
            //                   {task.action_message || t("NA")}
            //                 </td>
            //               </tr>
            //             ))}
            //           </tbody>
            //         </table>
            //       ) : (
            //         <div>{t("no_data_available")}</div>
            //       )}
            //     </div>
            //   );
            // }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTaskTable;
