import React, { useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import FormHandler from "../../../Common/Form/FormHandler/FormHandler";
import "./HumanLossForm.css"
import { useKeycloak } from "@react-keycloak/web";
import { toast, ToastContainer } from "react-toastify";
import {
  schema,
  HumanLossUserConfig
} from "../../../../../JsonData/HumanLossUserConfig";



import {
  postDataWithBody,
  getData,
  
  putDataWithBody,
  postDataWithBodyFrGIS,
} from "../../../../../Services/Services";


import {
  getUsersFromApp,
  getAllRole,
  createTaskandAssign,
  createHumanLossDetails,
  getPoliceStationDetails
} from "../../../../../Services/EndPoints";
import { yupResolver } from "@hookform/resolvers/yup";

const getCurrentDate = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = now.getFullYear();
  return `${year}-${month}-${day}`; // Return in yyyy-mm-dd format
};



const HumanLossRegistrationForm = (props) => {
  const { keycloak } = useKeycloak();
  const [activeTab, setActiveTab] = useState(0);
  const [loader, setLoader] = useState(false);
  const [humanLossUserConfig, setHumanLossUserConfig] = useState(
    HumanLossUserConfig
  );
  const navigate = useNavigate();
  const { state } = useLocation();
  
  let dataFromTable = state?.data;

  const [lgShow, setLgShow] = useState(false);

 
  const {
    register,
    handleSubmit,
    setValue,
    getValues,

    reset,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema[activeTab]), mode: "all" });
  const paymentStatus = useWatch({
    name: "paymentStatus",
    control,
  });
  
  useEffect(() => {
    const currentDate = getCurrentDate();
    setValue("entryDate", currentDate);
    setValue("paymentDate", currentDate);
  }, [setValue]);

  useEffect(() => {
    // Clear the paymentDate if paymentStatus is 'No' or 'NA'
    if (paymentStatus === "1" || paymentStatus === "2") {
      setValue("paymentDate", ""); // Clear paymentDate
    } else if (paymentStatus === "0") {
      setValue("paymentDate", getCurrentDate()); // Set the current date when "Yes"
    }
  }, [paymentStatus, setValue]);

  useEffect(() => {

    const getPoliceStationList = async () => {
      try {
        const policeStationDetails = await getData(getPoliceStationDetails);
        
        // Map the response to the format expected by the dropdown
        const policeStationOptions = policeStationDetails.map((station) => ({
          value: station.id.toString(),
          label: station.police_station,
        }));

        // Update the HumanLossUserConfig dynamically
        const updatedConfig = { ...HumanLossUserConfig };  
        updatedConfig[0].fields.forEach(field => {
          if (field.name === "policeStationName") {
            field.options = policeStationOptions;
          }
        });

        setHumanLossUserConfig(updatedConfig);
      } catch (error) {
        console.error("Error fetching police station details:", error);
      }
    };

    getPoliceStationList();
  }, []);  // Empty dependency array to fetch data only once
 
  // useEffect(() => {
  //   document.getElementById("container_user_list").classList.add('disabled_dropdown');
  //   getUserList();
  //   fetchAllRoles();
  //   if(data){
  //     document.getElementById("submitBtn").style.display="none";
  //     reset(data);
  //   }
  // }, []);
  // const getUserList = async () => {
  //   setLoader(true);
  //   var userIdNameMap = {};
  //   var userList = await getData(getUsersFromApp);
  //   (userList).sort((a, b) => (a.name > b.name) ? 1: -1);
  //   let userArrSanitized = [];
  //   userList.forEach((user, index) => {
  //     userIdNameMap[user.id] = user.first_name+" "+user.last_name;
  //     userArrSanitized.push({
  //       label: user.first_name+" "+user.last_name,
  //       value: user.id,
  //     });
  //   });
  //   HumanLossUserConfig[0].fields[4]["options"] = userArrSanitized;
  //   setHumanLossUserConfig(HumanLossUserConfig);
  //   populateUserList(userIdNameMap);
  // };

  // const populateUserList = (userIdNameMap)=>{
  //   let userListToPrePopulate = [];
  //   if(data){
  //     var userListFrmEdit = data.user_list.split(",");
  //     for(let user in userIdNameMap){
  //       if(userListFrmEdit.indexOf(user)>-1){
  //         userListToPrePopulate.push({"label":userIdNameMap[user],"value":user});
  //       }
  //     }
  //     setValue("user_list",userListToPrePopulate);
  //   }
  // }
  // const populateRoleList = (roleIdNameMap)=>{
  //   let roleListToPrePopulate = [];
  //   if(data){
  //     var roleListFrmEdit = data.role_list.split(",");
  //     for(let role in roleIdNameMap){
  //       if(roleListFrmEdit.indexOf(role)>-1){
  //         roleListToPrePopulate.push({"label":roleIdNameMap[role],"value":role});
  //       }
  //     }
  //     setValue("role_list",roleListToPrePopulate);
  //   }
  // }
  // const fetchAllRoles = async () => {
  //   setLoader(true);
  //   let rolearr = await getData(getAllRole);
  //   (rolearr).sort((a, b) => (a.name > b.name) ? 1: -1);
  //   let roleArrSanitized = [];
  //   var roleIdNameMap = {};
  //   rolearr.forEach((role, index) => {
  //     roleIdNameMap[role.id] = role.name;
  //     roleArrSanitized.push({
  //       label: role.name,
  //       value: role.id,
  //     });
  //   });
  //   HumanLossUserConfig[0].fields[3]["options"] = roleArrSanitized;
  //   setHumanLossUserConfig(HumanLossUserConfig);
  //   //setValue("role_list", rolearr);
  //   setLoader(false);
  //   populateRoleList(roleIdNameMap);
   
  // };
  // if(assign_type && assign_type == 1){
  //   document.getElementById("container_user_list").classList.remove('disabled_dropdown');
  //   document.getElementById("container_role_list").classList.add('disabled_dropdown');
  //   setValue("role_list",[]);
  // }
  // else if(assign_type && assign_type == 0){
  //   document.getElementById("container_user_list").classList.add('disabled_dropdown');
  //   document.getElementById("container_role_list").classList.remove('disabled_dropdown');
  //   setValue("user_list",[]);
  // }
  // console.log("assign_type=", assign_type);

 
  const GenerateForm = (fields, register, errors, control) => {
    return (
      <div>
        
          <FormHandler
            register={register}
            errors={errors}
            fields={fields}
            control={control}
            columns={2}
            getValues={getValues}
          />
     
      </div>
    );
  };

  

  const generateTabsData = (tabWiseData) => {
    tabWiseData?.forEach((element) => {
      return (element.component = GenerateForm(
        element?.fields,
        register,
        errors,
        control
      ));
    });
    return tabWiseData;
  };


  // const submitHandler = async (data, e) => {
  //   e.preventDefault(); // Prevent default form submission
  //   console.log("DATA", data);
    
  //   const formData = new FormData();
    
  //   // Function to convert "True"/"False" string values to boolean
  //   const convertToBoolean = (value) => {
  //     if (value === "True") return true;
  //     if (value === "False") return false;
  //     return value; // For non-boolean values, return them as is
  //   };

  //    // Function to get the current time in HH:mm:ss format
  // const getCurrentTime = () => {
  //   const now = new Date();
  //   const hours = String(now.getHours()).padStart(2, "0"); // Ensure two digits for hours
  //   const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
  //   const seconds = String(now.getSeconds()).padStart(2, "0"); // Ensure two digits for seconds
  //   return `${hours}:${minutes}:${seconds}`; // Return the formatted time string
  // };

  // // Function to get the label from options based on the value
  // const getSelectedLabel = (fieldName, value) => {
  //   const field = HumanLossUserConfig[0].fields.find(f => f.name === fieldName);
  //   const selectedOption = field?.options.find(option => option.value === value);
  //   return selectedOption ? selectedOption.label : value; // If no label found, return the value itself
  // };
  
  //   // Your data object (array of objects)
  //   const formDataObject = [{
  //     "human_loss_primary_id": dataFromTable.id,
  //     "typeOfCasualty": getSelectedLabel("typeCasualty", data.typeCasualty),
  //     "personName": data.nameDeceased,
  //     "personFamilyMemberName": data.fathersName,
  //     "age": data.age,
  //     "gender": getSelectedLabel("sex", data.sex),
  //     "personAddress": data.residentialAddress,
  //     "activityDuringAccident": data.activityAccident,
  //     "isPostMortem": convertToBoolean(data.postMortem),  // Convert to boolean if necessary
  //     "isHospitalized": convertToBoolean(data.hospitalized), // Convert to boolean if necessary
  //     "hospitalName": data.hospitalName,
  //     "isReportedToPS": convertToBoolean(data.reportedPoliceStation), // Convert to boolean if necessary
  //     "policeStationName": getSelectedLabel("policeStationName", data.policeStationName),
  //     "remarks": data.remarks,
  //     "entryDate": data.entryDate,
  //     "entryTime": getCurrentTime(),
  //     "paymentStatus": getSelectedLabel("paymentStatus", data.paymentStatus),
  //     "paymentDate": data.paymentDate
  //   }];
  
  //   // Since formDataObject is an array, we need to handle the first object in the array
  //   const dataObject = formDataObject[0]; // Take the first object
  
  //   // Append each field of the data object to FormData
  //   Object.keys(dataObject).forEach(key => {
  //     const value = dataObject[key];
  //     formData.append(key, value); // Append key-value to FormData
  //   });
  
  //   console.log("FormData being sent: ", formData);  // Optional: See the FormData object in the console
  
  //   setLoader(true); // Show loader
    
  //   const headers = { "Content-Type": "application/json" }; // Correct Content-Type for FormData
  
  //   try {
  //     // Replace the endpoint with your actual endpoint and pass the FormData
  //     const res = await postDataWithBody(createHumanLossDetails.replace("DATE", dataFromTable.reported_on), formData, headers);
  
  //     // Handle response
  //     if (res.status === "success") {
  //       toast.success(res.msg, {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //       setLoader(false);
  //       // navigate("/my-tasks/view-usertask");
  //     } else {
  //       toast.error(res.msg, {
  //         position: toast.POSITION.TOP_CENTER,
  //         autoClose: 2000, // Close after 2 seconds
  //       });
  //       setLoader(false);
  //     }
  //   } catch (error) {
  //     console.error("Error submitting form data:", error);
  //     setLoader(false);
  //     toast.error("An error occurred while submitting the form.");
  //   }
  // };
  

  const submitHandler = async (data, e) => {
    e.preventDefault(); // Prevent default form submission
    console.log("DATA", data);
  
    // Function to convert "True"/"False" string values to boolean
    const convertToBoolean = (value) => {
      if (value === "True") return true;
      if (value === "False") return false;
      return value; // For non-boolean values, return them as is
    };
  
    // Function to get the current time in HH:mm:ss format
    const getCurrentTime = () => {
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0"); // Ensure two digits for hours
      const minutes = String(now.getMinutes()).padStart(2, "0"); // Ensure two digits for minutes
      const seconds = String(now.getSeconds()).padStart(2, "0"); // Ensure two digits for seconds
      return `${hours}:${minutes}:${seconds}`; // Return the formatted time string
    };
  
    // Function to get the label from options based on the value
    const getSelectedLabel = (fieldName, value) => {
      const field = HumanLossUserConfig[0].fields.find(f => f.name === fieldName);
      const selectedOption = field?.options.find(option => option.value === value);
      return selectedOption ? selectedOption.label : value; // If no label found, return the value itself
    };
  
    // Your data object (array of objects)
    const formDataObject = [{
      "human_loss_primary_id": dataFromTable.id,
      "typeOfCasualty": getSelectedLabel("typeCasualty", data.typeCasualty),
      "personName": data.nameDeceased,
      "personFamilyMemberName": data.fathersName,
      "age": data.age,
      "gender": getSelectedLabel("sex", data.sex),
      "personAddress": data.residentialAddress,
      "activityDuringAccident": data.activityAccident,
      "isPostMortem": convertToBoolean(data.postMortem),  // Convert to boolean if necessary
      "isHospitalized": convertToBoolean(data.hospitalized), // Convert to boolean if necessary
      "hospitalName": data.hospitalName,
      "isReportedToPS": convertToBoolean(data.reportedPoliceStation), // Convert to boolean if necessary
      "policeStationName": getSelectedLabel("policeStationName", data.policeStationName),
      "remarks": data.remarks,
      "entryDate": data.entryDate,
      "entryTime": getCurrentTime(),
      "paymentStatus": getSelectedLabel("paymentStatus", data.paymentStatus),
      "paymentDate": data.paymentDate
    }];
  
    // Convert the array of objects into a JSON string to send in the body of the request
    const jsonData = JSON.stringify(formDataObject);
  
    console.log("Payload being sent: ", jsonData);  // Optional: See the payload in the console
  
    setLoader(true); // Show loader
  
    const headers = {
      "Content-Type": "application/json", // Set content type to application/json for sending JSON data
    };
  
    try {
      // Replace the endpoint with your actual endpoint and pass the JSON string in the request body
      const res = await postDataWithBody(
        createHumanLossDetails.replace("DATE", dataFromTable.reported_on),
        jsonData, // Send the JSON payload
        headers
      );
  
      // Handle response
      if (res.status === "success") {
        toast.success(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);
        navigate("/dist-dashboard/human-loss-report",{
          state:{submitted: true,
            date: dataFromTable.reported_on,
            distName: dataFromTable.distName,
            viewFromForm:'Detailed'
        }
        });
      } else {
        toast.error(res.msg, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000, // Close after 2 seconds
        });
        setLoader(false);

      }
    } catch (error) {
      console.error("Error submitting form data:", error);
      setLoader(false);
      toast.error("An error occurred while submitting the form.");
    }
  };
  

  

  const tabsData = generateTabsData(HumanLossUserConfig);
  return (
    <div className="mt-4 facility-container">
    

      <div className="form-body">
        <div className="registration__form--body">
          <form
            onSubmit={handleSubmit(submitHandler)}
            name="CreateHumanLossDetailedRegForm"
          >
            <div>
              <h4 style={{marginLeft: '15px'}}>Detailed</h4>
            </div>
            <div>
              {tabsData?.map((tab) => (
                <>
                  {tab.id === activeTab && (
                    <>
                      <div>{tab.component}</div>
                    
                    </>
                  )}
                </>
              ))}
            </div>
            
            <div className="d-flex justify-content-center">
              {tabsData.length - 1 === activeTab && (
                <Button type="submit" id="submitBtn" className="mx-2" variant="primary">
                  Save & Submit
                </Button>
               )}
            </div>
          </form>
        </div>
      </div>
      
    </div>
  );
};

export default HumanLossRegistrationForm;
