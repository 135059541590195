import axios from "axios";
import { userMgmtService } from "./EndPoints";
// //Axios Instance
// export const api = axios.create({
//   baseURL: baseUrl,
//   timeout: 10000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });
export const userApi = axios.create({
  baseURL: '/'+process.env.REACT_APP_CORE_CONTEXT_PATH_NAME,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
});
const getTokenFromLocalStorage = () => {
  return localStorage.getItem("accessToken");
};
userApi.interceptors.request.use(
  (config) => {
    const token = getTokenFromLocalStorage();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function getData(endPoint) {
  try {
    let url = "/" +process.env.REACT_APP_CORE_CONTEXT_PATH_NAME+ endPoint;
    const response = await axios.get(url,{
      withCredentials: true ,
      headers: {
          Authorization: 'Bearer '+localStorage.getItem("auth_token"),
      }
     });
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}


export async function getDataPf(endPoint) {
  try {
    const response = await userApi.get(endPoint);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
export async function putDataWithBodyPf(endPoint, body) {
  try {
    const response = await userApi.put(endPoint, body);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return error;
  }
}

export async function putDataFormDataWithNoidPf(endPoint, formData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  try {
    const url = `${endPoint}`;
    const response = await userApi.put(url, formData, config);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log(response);
    }
  } catch (error) {
    console.log(error, endPoint);
    return [];
  }
}
