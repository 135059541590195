export const roleDetails = [
    {
      roleName: "Camera",
      roleDescription: "Createing the role for Camera.",
      createdDateTime: "3/02/2023,4:00pm",
      roleStatus: "Active",
      },
        {
      roleName: "ECB",
      roleDescription: "Createing the role for ECB.",
      createdDateTime: "3/02/2023,4:00pm",
      roleStatus: "Deactive",
        },
        {
      roleName: "Light",
      roleDescription: "Createing the role for light.",
      createdDateTime: "3/02/2023,4:00pm",
      roleStatus: "Active",
        },
        {
      roleName: "Light",
      roleDescription: "Createing the role for light.",
      createdDateTime: "3/02/2023,4:00pm",
      roleStatus: "Active",
        },
        {
      roleName: "Light",
      roleDescription: "Createing the role for light.",
      createdDateTime: "3/02/2023,4:00pm",
      roleStatus: "Active",
        },
     
  ];
  
  export const roleTableDatacolumnsForExport =[
    { label: "Role name", key: "name" },
    { label: "Description", key: "description" },
    { label: "Created On", key: "createdOn" },
    { label: "Permission", key: "role_permission" },

  ];