import React, { useState, useEffect } from "react";
import "./FileUploadForMR.css";
import { Upload, Rejectedicon, Jpgicon, Pdficon, Docicon } from "../../../../../Components/IconComponents";
import PreviewFile from "../../Form/PreviewFile/PreviewFile";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setRegFilesToStore } from "../../../../../CentralStore/RegistrationsSlice/registrationSlice";
import { appserveraddr, dmscoreservicecontextname } from "../../../../../Services/EndPoints";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FileUploadForMR = ({
  label,
  name,
  errors,
  register,
  mandatory,
  getValues,
  setValue,
  editId,
  imgName,
}) => {
  const { t } = useTranslation();
  const [fileObj, setFileObj] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [image, setImage] = useState(null);
  const dispatch = useDispatch();
  const { onChange, ...registerParams } = register(name);
  const { registrationFiles } = useSelector((state) => state.registrations);

  useEffect(() => {
    const values = getValues(name);
    if (imgName) {
      setFileObj({ name: imgName, type: "image/jpeg" });
    } else if (registrationFiles[name]) {
      setFileObj(registrationFiles[name]);
    } else if (values) {
      setFileObj(values);
    }
  }, [imgName, registrationFiles, getValues, name]);

  const getImageWithAuth = async (imageName) => {
    const imageUrl = `${appserveraddr}/${dmscoreservicecontextname}/master_resource/get_resource_image/${imageName}`;
    try {
      const response = await fetch(imageUrl, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem("auth_token") },
      });
      
      if (response.ok) {
        const blob = await response.blob();
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => resolve(reader.result.split(',')[1]);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } else {
        console.error("Failed to fetch image:", response.statusText);
        return null;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      return null;
    }
  };

  useEffect(() => {
    if (imgName) {
      setImage(null);
      getImageWithAuth(imgName).then((base64) => {
        if (base64) setImage(base64);
        else setImage(null);
      }).catch(() => setImage(null));
    } else {
      setImage(null);
    }
  }, [imgName]);

  console.log(image,"iiiiiiiii")

  const generateIconByType = () => {
    const { type } = fileObj || {};
    let icon;
    if (type === "application/pdf") {
      icon = <Pdficon />;
    } else if (type && type.includes("image")) {
      icon = <Jpgicon/>;
    } else if (type && type.includes("doc")) {
      icon = <Docicon />;
    }
    return icon;
  };

  const convertFileSize = () => {
    const { size: bytes } = fileObj || {};
    const sizes = ["B", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 B";
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sizes[i]}`;
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(setRegFilesToStore({ type: name, file }));
      setFileObj(file);
      setShowPreview(false);
    }
  };

  const previewHandler = () => setShowPreview(!showPreview);

  const handleDragOver = (e) => e.preventDefault();

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    dispatch(setRegFilesToStore({ type: name, file }));
    setFileObj(file);
  };

  const handleClick = () => {
    const fileInputTriger = document.getElementById(name);
    if (fileInputTriger) fileInputTriger.click();
  };
  const exportTooltip = (
    <Tooltip id="export-tooltip">Click here to remove and upload new image</Tooltip>
  );

  return (
    <div className="upload">
      <label>
        {t(label)} {mandatory && <span className="asterisk">*</span>}
      </label>
      <div onDrop={handleDrop} onDragOver={handleDragOver} onClick={handleClick}>
        {image ? (
          // Display the uploaded image
          <div className="file__detail--box cursor-pointer" onClick={previewHandler}>
            <div className="uploaded-image-preview">
              <img src={image} alt="Uploaded" className="uploaded-image" style={{
    width: "200px", // Set a fixed width
    height: "100px", // Set a fixed height
    objectFit: "contain", // Ensure the entire image is visible
    borderRadius: "8px", // Rounded corners
    border: "1px solid #ccc", // Subtle border for separation
    margin: "10px 0" // Spacing around the image
  
            }}/>
            </div>
            <OverlayTrigger placement="top" overlay={exportTooltip}>
                <div
              className="reject__iconplace cursor-pointer"
              onClick={() => {
                setImage(null); // Clear the image state
                setFileObj(null);
                setShowPreview(false);
                setValue(name, null);
              }}
              >
              <Rejectedicon />
               </div>
            </OverlayTrigger>
          </div>
        ) : fileObj ? (
          // Show file details if no image is passed
          <div className="file__detail--box cursor-pointer" onClick={previewHandler}>
            <div className="d-flex">
              <div className="file_icon">{generateIconByType()}</div>
              <div className="file__detail">
                <div className={fileObj?.name?.length > 10 ? "file__name--ellipsis" : ""}>
                  {fileObj?.name}
                </div>
                <div>{convertFileSize()}</div>
              </div>
              <div
                className="reject__iconplace cursor-pointer"
                onClick={() => {
                  setFileObj(null);
                  setShowPreview(false);
                  setValue(name, null);
                }}
              >
                <Rejectedicon />
              </div>
            </div>
            {showPreview && <PreviewFile file={fileObj} show={showPreview} previewHandler={previewHandler} title="Preview" />}
          </div>
        ) : (
          // Default upload prompt
          <div className="file__upload cursor-pointer">
            <div className="d-flex file__icon--text">
              <div className="icon__size--file"><Upload /></div>
              <div className="file-text">{t("draganddrop")}</div>
            </div>
            <input
              id={name}
              type="file"
              accept="application/pdf, image/*, .doc, .docx"
              name={name}
              style={{ display: "none" }}
              onChange={(event) => {
                onChange(event);
                handleChange(event);
                if (event.target.files[0]) {
                  setImage(URL.createObjectURL(event.target.files[0])); // Set the image state
                }
              }}
              {...registerParams}
            />
          </div>
        )}
      </div>
      <span className="error__message">{errors[name]?.message}</span>
    </div>
  );
  
};

export default FileUploadForMR;
