import React from "react";
import "./CellDetails.css";

import FileView from "../Form/FileView/FileView";
import { getFormattedDate } from "../../../../Components/UiComponents/utils";

const CellDetails = ({ item, className = "" }) => {
  const { label, value, type } = item;

  const extractValues = () => {
    if (Array.isArray(value)) {
      if (value.length > 1) {
        return value.join(", ");
      } else if (value.length === 1) {
        return value[0];
      } else {
        return "";
      }
    } else {
      return value;
    }
  };

  const getFormattedValue = () => {
    if (value && type !== "boolean") {
      if (label === "Registered On") {
        return getFormattedDate(value);
      } else if (
        type === "input" ||
        type === "select" ||
        type === "text_area"
      ) {
        return value;
      } else if (type === "multi_select") {
        return extractValues();
      } else if (type === "file_upload") {
        let fileData = Array.isArray(value) ? item?.value[0] : item?.value;
        return <FileView fileData={fileData} />;
      } else if (type === "date") {
        return getFormattedDate(value);
      } else if (typeof value) {
        return value;
      }
    } else {
      if (value && type === "boolean") {
        return value === true ? "Yes" : "No";
      }
      return value;
    }
  };

  return (
    <div className={className}>
      <div className="view__details--label">{label}</div>

      <div className="view__details--value">
        {getFormattedValue() || <span className="text-secondary">-NA-</span>}
      </div>
    </div>
  );
};

export default CellDetails;
