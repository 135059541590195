import { useRef, useEffect } from "react";

const LocationAutoComplete = (props) => {
 const autoCompleteRef = useRef();
 const inputRef = useRef();
 const options = {
  fields: ["address_components", "geometry", "icon", "name"]
 };
 useEffect(() => {
  autoCompleteRef.current = new window.google.maps.places.Autocomplete(
   inputRef.current,
   options
  );
  autoCompleteRef.current.addListener("place_changed", async function () {
    const place = await autoCompleteRef.current.getPlace();
    props.updateMapLocation(place.geometry.location.lat(),place.geometry.location.lng())
    console.log(place.geometry.location.lat()+"   "+place.geometry.location.lng());
   });
 }, []);
 return (
  <div>
   <input className="ms-2 w-50" ref={inputRef} />
  </div>
 );
};
export default LocationAutoComplete;