import Keycloak from "keycloak-js";
import { createUserLog } from "./Services/EndPoints";
import { postDataWithBody } from "./Services/Services";
const keycloak = new Keycloak({
 url: process.env.REACT_APP_URM_BASE_URL+process.env.REACT_APP_URM_CONTEXT_NAME,
 realm: process.env.REACT_APP_URM_REALM,
 clientId: process.env.REACT_APP_URM_REALM_CLIENT,
});

// Variable to track the timeout
let apiCallTimeout = null;

const initializeKeycloak = (onAuthenticated) => {
  keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
    .then((authenticated) => {
      if (authenticated) {
        console.log("Authenticated");
        // Start session expiration timer
        startSessionExpirationTimer();
        onAuthenticated();
      } else {
        console.error("User not authenticated");
      }
    })
    .catch((err) => {
      console.error("Error initializing Keycloak", err);
    });
};

const startSessionExpirationTimer = () => {
  // Clear any existing timeouts to prevent multiple calls
  if (apiCallTimeout) {
    clearTimeout(apiCallTimeout);
    apiCallTimeout = null;
  }

  // Ensure the token is parsed and contains expiration info
  if (keycloak.tokenParsed && keycloak.tokenParsed.exp) {
    const expirationTime = keycloak.tokenParsed.exp * 1000;  // Convert to milliseconds
    const currentTime = Date.now();
    const timeLeft = expirationTime - currentTime;

    // Set timeout if token is valid (timeLeft > 0)
    if (timeLeft > 5000) { // Only set a timeout if more than 5 seconds remain
      apiCallTimeout = setTimeout(() => {
        sendApiCallBeforeTimeout();
      }, timeLeft - 5000); // Trigger 5 seconds before token expiration
    } else {
      console.log("Token is about to expire or already expired.");
    }
  } else {
    console.error("Token does not have expiration info");
  }
};

const sendApiCallBeforeTimeout = () => {
  const payload = JSON.parse(sessionStorage.getItem("inputDetails") || "{}");
  payload.activity = "Log Out";
  payload.date = new Date().toISOString().split("T")[0];
  payload.time = new Date().toLocaleTimeString("en-US", { hour12: false });

  console.log("Sending logout API call", payload);

  // Send the API request to log activity before session expires
  postDataWithBody(createUserLog, payload, {})
    .then((response) => {
      console.log("Successfully sent logout data", response);
    })
    .catch((error) => {
      console.error("Error sending logout data", error);
    });
};
  
export default keycloak;
